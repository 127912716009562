import React from 'react';
import LoaderStyles from './loader.styled';
import LoaderIcon from '../../../static/loader.gif';

const Loader = () => {
    return (
        <LoaderStyles.LoaderContent>
            <img src={LoaderIcon} alt="loader" />
        </LoaderStyles.LoaderContent>
    )
}

export default Loader;