import React from 'react'
import { Box, Typography } from '@mui/material'
import { getColorText } from '../../../utils'

const IndividualValueBarComponent = ({ data }) => {
	const { value } = data
	const currentValue = getColorText(value)

	return (
		<Box sx={{ paddingTop: '4px !important' }}>
			<Typography
				sx={{
					background: currentValue,
					WebkitBackgroundClip: 'text',
					WebkitTextFillColor: 'transparent',
					fontSize: '10px',
					lineHeight: '12px',
					fontWeight: '400',
					textAlign: 'center',
					letterSpacing: '0.5px',
				}}
			>
				{value}
			</Typography>
		</Box>
	)
}

export default IndividualValueBarComponent
