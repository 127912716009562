import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Typography from "../../common/typography";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";
import ErrorIcon from "@mui/icons-material/Error";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import changPhoneFormStyles from "./changePhone.styled";
import { palette, variants } from "../../../theme/partials";

const validationSchema = Yup.object({
  phone: Yup.string("Ingresa tu contraseña")
    .min(10, "Contraseña debe tener mínimo 10 caracteres")
    .required("Contraseña es requerida"),
});

const ChangePhoneForm = ({ title, description }) => {
  const formik = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  return (
    <changPhoneFormStyles.FormContent onSubmit={formik.handleSubmit}>
      <changPhoneFormStyles.HeadingWrapper>
        <>
          {title && (
            <Typography
              text={title}
              variant={variants.large}
              color={palette.secondary}
              asTag={"h3"}
            />
          )}
        </>
        <>
          {description && (
            <Typography
              text={description}
              variant={variants.normal}
              color={palette.neutral30}
              asTag={"p"}
            />
          )}
        </>
      </changPhoneFormStyles.HeadingWrapper>

      <changPhoneFormStyles.FieldWrapper>
        <FormControl
          variant="outlined"
          className={
            formik.touched.phone && formik.errors.phone && "text-error"
          }
        >
          <InputLabel htmlFor="outlined-adornment-password">Teléfono</InputLabel>
          <OutlinedInput
            id={"phone"}
            name={"phone"}
            label={"phone"}
            type={"text"}
            endAdornment={
              <>
                {formik.errors.phone &&
                  formik.touched.phone &&
                  Boolean(formik.errors.phone) && (
                    <InputAdornment position="end">
                      <IconButton aria-label="alert" edge="end">
                        <ErrorIcon />
                      </IconButton>
                    </InputAdornment>
                  )}
              </>
            }
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
            placeholder={"Ingresa tu teléfono celulcar"}
            onBlur={formik.handleBlur}
            required
          />
          {formik.errors.phone && (
            <div id="help-error">{formik.errors.phone}</div>
          )}
        </FormControl>
      </changPhoneFormStyles.FieldWrapper>

      <changPhoneFormStyles.ButtonWrapper>
        <Button variant={"contained"} disabled={!formik.isValid} type="submit">
          Enviar petición
        </Button>
        <Typography
            text={"Cancelar"}
            variant={variants.small}
            color={palette.neutral30}
            asTag={"a"}
            hasDecoration
          />
      </changPhoneFormStyles.ButtonWrapper>
    </changPhoneFormStyles.FormContent>
  );
};

export default ChangePhoneForm;
