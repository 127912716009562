import React, { useState, useEffect } from "react";
import { palette, variants } from "../../../theme/partials";
import DashboardStyle from "../../../pages/dashboard/dashboard.styled";
import Typography from "../../common/typography";
import IconButton from "@mui/material/IconButton";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import PeopleIcon from "@mui/icons-material/People";
/* import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms"; */
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { getUsersGraph } from "../../../services/dashboard.service";
import { userDataGraphicsMapper } from "../../../helper/dashboardMapper";
import LoaderIndcator from "../../skeleton/loaderIndicator";

function DashboardIndicators() {
  const [isLoading, setIsLoading] = useState(false);
  const [dataList, setDataList] = useState(null);

  const getUserData = async () => {
    setIsLoading(true);
    try {
      const result = await getUsersGraph();
      if (result?.data) {
        const dataFiltered = userDataGraphicsMapper(result?.data);
        setDataList(dataFiltered);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <DashboardStyle.Indicators>
      {!isLoading ? (
        <>
          {/* <DashboardStyle.Indicator>
            <Typography
              text={"Tiempo promedio por persona usuaria"}
              variant={variants.extraSmall}
              color={palette.secondary}
              asTag={"h4"}
            />
            <DashboardStyle.IndicatorLink>
              <IconButton aria-label="laptop">
                <AccessAlarmsIcon />
              </IconButton>
              <Typography
                text={"5 minutos"}
                variant={variants.normal}
                color={palette.primary}
                asTag={"h4"}
              />
            </DashboardStyle.IndicatorLink>
          </DashboardStyle.Indicator> */}
          <DashboardStyle.Indicator>
            <Typography
              text={"Personas usuarias nuevas"}
              variant={variants.extraSmall}
              color={palette.secondary}
              asTag={"h4"}
            />

            <DashboardStyle.IndicatorLink>
              <IconButton aria-label="laptop">
                <PersonAddIcon />
              </IconButton>
              <Typography
                text={dataList?.newUsers}
                variant={variants.normal}
                color={palette.primary}
                asTag={"h4"}
              />
            </DashboardStyle.IndicatorLink>
          </DashboardStyle.Indicator>
          <DashboardStyle.Indicator>
            <Typography
              text={"Personas usuarias recurrentes"}
              variant={variants.extraSmall}
              color={palette.secondary}
              asTag={"h4"}
            />

            <DashboardStyle.IndicatorLink>
              <IconButton aria-label="laptop">
                <PeopleIcon />
              </IconButton>
              <Typography
                text={dataList?.recurrentUser}
                variant={variants.normal}
                color={palette.primary}
                asTag={"h4"}
              />
            </DashboardStyle.IndicatorLink>
          </DashboardStyle.Indicator>
          <DashboardStyle.Indicator>
            <Typography
              text={"Personas usuarias suscritas"}
              variant={variants.extraSmall}
              color={palette.secondary}
              asTag={"h4"}
            />

            <DashboardStyle.IndicatorLink>
              <IconButton aria-label="laptop">
                <NotificationsActiveIcon />
              </IconButton>
              <Typography
                text={dataList?.registeredUsers}
                variant={variants.normal}
                color={palette.primary}
                asTag={"h4"}
              />
            </DashboardStyle.IndicatorLink>
          </DashboardStyle.Indicator>
        </>
      ) : (
        <>
          <DashboardStyle.Indicator>
            <LoaderIndcator show={isLoading} />
          </DashboardStyle.Indicator>
          <DashboardStyle.Indicator>
            <LoaderIndcator show={isLoading} />
          </DashboardStyle.Indicator>
          <DashboardStyle.Indicator>
            <LoaderIndcator show={isLoading} />
          </DashboardStyle.Indicator>
        </>
      )}
    </DashboardStyle.Indicators>
  );
}

export default React.memo(DashboardIndicators);
