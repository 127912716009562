import { axiosPrivate } from "../services/axiosInstance";
import { environment } from "../constant";

/**
 * It should get graphic bar data
 * @param null
 */
export const getGrachicBarData = () => {
  return axiosPrivate.get(
    `${environment.path_prefix.dashboard}/root-graph`
  );
};

/**
 * It should get graphic lineal data
 * @param payload: Object
 */
export const getGrachicLinealData = (payload) => {
  return axiosPrivate.post(
    `${environment.path_prefix.dashboard}/comparison-graph`,
    payload
  );
};

/**
 * It should get graphic map data
 * @param payload: Object
 */
export const getGrachicMapData = (payload) => {
  return axiosPrivate.post(
    `${environment.path_prefix.dashboard}/heatmap-score`,
    payload
  );
};

/**
 * It should get users graph data
 */
export const getUsersGraph = () => {
  return axiosPrivate.post(
    `${environment.path_prefix.dashboard}/users-graph`
  );
};
