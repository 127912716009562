import React, { useState, useEffect } from "react";
import TemplateStyles from "../createTemplate.styled";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "../../common/typography";
import DragAndDrop from "../../dragAndDrop/drapAndDrop";
import { palette, variants } from "../../../theme/partials";
import FormControlLabel from "@mui/material/FormControlLabel";
import Accordion from "@mui/material/Accordion";
import FormGroup from "@mui/material/FormGroup";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import ChevronRight from "../../../static/chevron-right.svg";
import Button from "@mui/material/Button";
import FieldTextLimit from "../../../helper/field";
import { translation } from "../../../constant";
import {
  setItemSelectedMap,
  setPayloadCustomComponents,
  setUpdateScenarieUpdate,
} from "../../../helper/templateMappers";
import { stageSettings } from "../../../services/platform.service";
import { textToCapitalize } from '../../../utils/sanitize';
import {TITLE_REGEX} from "../../../helper/regex";

const UpdateScenarieSelected = ({
  scenarieSelected,
  templateInfo,
  isEditScenarieCanceled,
}) => {
  const { staticText } = translation;
  const [expandedAccordion, setExpandedAccordion] = useState(false);
  const [itemData, setItemData] = useState({
    scenarieId: null,
    components: [],
    content: null,
    end: null,
    name: null,
    notifications: [],
    start: null,
  });

  /**
   * It should expand or collapse panel
   * @param event: Event
   * @param isExpanded: Boolean
   */
  const handleChangeStep3 = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : false);
  };

  /**
   * It should drag and drop components
   * @param components: Array
   */
  const updateDragAndDrop = (components) => {
    setItemData({
      ...itemData,
      components,
    });
  };

  /**
   * It should set input content message
   * @param e: Event
   */
  const onChangeContent = (e) => {
    const value = e.target.value
    const cleanValue= value.replace(TITLE_REGEX,'')
    setItemData({
      ...itemData,
      content: cleanValue,
    });
  };

  /**
   * It should update checkbox list with checked
   * @param e: Event
   * @param item
   */
  const onChangeNotification = (e, item) => {
    let checkboxList = itemData?.notifications;

    for (let i = 0; i < checkboxList.length; i++) {
      if (checkboxList[i].enum === item.enum) {
        checkboxList[i].checked = e.target.checked;
      }
    }

    setItemData({
      ...itemData,
      notifications: checkboxList,
    });
  };

  /**
   * It should save scenarie component updated
   * @param payload: Object
   */
  const saveCustomTemplateComponents = async (payload) => {
    try {
      await stageSettings(payload);
      setTimeout(() => {
        isEditScenarieCanceled();
      }, 800);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * It should update scenarie component data
   */
  const updateScenarieData = async () => {
    const customScenarie = await setPayloadCustomComponents(itemData);
    const payload = await setUpdateScenarieUpdate(customScenarie);
    saveCustomTemplateComponents(payload);
  };

  /**
   * It should transform item data to keep data with correct format
   */
  const setDataToEditScenarieSelected = async () => {
    const { scenarieId, name, start, end, notifications, content, components } =
      await setItemSelectedMap(scenarieSelected, templateInfo?.components);

    setItemData({
      scenarieId,
      name,
      start,
      end,
      notifications,
      content,
      components,
    });
  };

  useEffect(() => {
    setDataToEditScenarieSelected();
  }, [scenarieSelected]);

  return (
    <>
      <TemplateStyles.breadcrumb>
        <Typography
          text={staticText.customizationEditScenaries}
          variant={variants.small}
          color={palette.neutral}
          asTag={"a"}
          hasDecoration
        />
        <img src={`${ChevronRight}`} alt={"icon"} loading="lazy" />
        <Typography
          text={textToCapitalize(templateInfo?.name)}
          variant={variants.small}
          color={palette.primary}
          asTag={"a"}
          hasDecoration
        />
      </TemplateStyles.breadcrumb>

      <TemplateStyles.AccordionPanel>
        <Accordion
          expanded={expandedAccordion === "panel1"}
          onChange={handleChangeStep3("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography
              text={staticText.sortComponent}
              variant={variants.normal}
              color={palette.secondaryContainer}
              asTag={"h5"}
            />
          </AccordionSummary>
          <AccordionDetails>
            <DragAndDrop
              source={itemData?.components}
              updateComponentList={updateDragAndDrop}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expandedAccordion === "panel2"}
          onChange={handleChangeStep3("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography
              text={staticText.settingsNotifications}
              variant={variants.normal}
              color={palette.secondaryContainer}
              asTag={"h5"}
            />
          </AccordionSummary>
          <AccordionDetails>
            <TemplateStyles.defaultWrapper>
              <Typography
                text={staticText.updateUserNotifications}
                variant={variants.small}
                color={palette.neutral20}
                asTag={"h5"}
              />
            </TemplateStyles.defaultWrapper>

            <TemplateStyles.GridRanges>
              {itemData?.notifications.map((item) => {
                return (
                  <TemplateStyles.CheckBoxWrapper
                    key={item.id}
                    className={"wrapper-checkbox"}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={item.label}
                            name={item.label}
                            checked={item.checked}
                            onChange={(e) => onChangeNotification(e, item)}
                            required
                          />
                        }
                        label={item.label}
                      />
                    </FormGroup>
                  </TemplateStyles.CheckBoxWrapper>
                );
              })}
            </TemplateStyles.GridRanges>
            {itemData?.notifications.filter((i) => i.checked).length === 0 && (
              <div id="help-error">{staticText.errorNotificationMesage}</div>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expandedAccordion === "panel3"}
          onChange={handleChangeStep3("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography
              text={staticText.notificationContent}
              variant={variants.normal}
              color={palette.secondaryContainer}
              asTag={"h5"}
            />
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              text={staticText.customNotificationMessage}
              variant={variants.small}
              color={palette.neutral30}
              asTag={"p"}
            />
            <TemplateStyles.FieldWrapper>
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  {staticText.putContentMessage}
                </InputLabel>
                <OutlinedInput
                  id={"content"}
                  name={staticText.putContentMessage}
                  label={staticText.putContentMessage}
                  value={itemData?.content}
                  type={"text"}
                  placeholder={staticText.putContentMessage}
                  required
                  onChange={(e) => onChangeContent(e)}
                  inputProps={{ maxLength: 200 }}
                />
                <FieldTextLimit currentValue={itemData?.content} limit={200} />
              </FormControl>
            </TemplateStyles.FieldWrapper>
          </AccordionDetails>
        </Accordion>
      </TemplateStyles.AccordionPanel>

      <TemplateStyles.ButtonWrapper>
        <Button
          variant={"contained"}
          type="button"
          onClick={() => updateScenarieData()}
        >
          {staticText.btnSaveAndContinue}
        </Button>

        <span
          style={{ marginTop: "30px" }}
          onClick={() => isEditScenarieCanceled()}
        >
          <Typography
            text={staticText.btnExit}
            variant={variants.small}
            color={palette.neutral20}
            asTag={"a"}
            hasDecoration
          />
        </span>
      </TemplateStyles.ButtonWrapper>
    </>
  );
};

export default UpdateScenarieSelected;
