import React, { useState } from "react";
import UpIcon from "../../../static/up.png";
import DownIcon from "../../../static/down.png";

const Header = ({ headers, onSorting }) => {
    const [sortingField, setSortingField] = useState("");
    const [sortingOrder, setSortingOrder] = useState("asc");

    const onSortingChange = (field) => {
        const order =
            field === sortingField && sortingOrder === "asc" ? "desc" : "asc";

        setSortingField(field);
        setSortingOrder(order);
        onSorting(field, order);
    };

    return (
        <thead>
            <tr>
                {headers.map(({ name, field, sortable }, index) => (
                    <td
                        key={`${index}-${name}`}
                        onClick={() =>
                            sortable ? onSortingChange(field) : null
                        }
                    >
                        <a>
                            {name}
                            {
                                (field === 'nombre' || field === 'fechaModificacion' || field === 'estado') && <img src={(sortingField && sortingField === field) && sortingOrder === "asc" ? UpIcon : DownIcon} alt={"asc"} loading="lazy" className="sort-icon" />
                            }
                        </a>
                    </td>
                ))}
            </tr>
        </thead>
    );
};

export default Header;