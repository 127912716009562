const removeAccentsAndDiacritics = (input) => {
  const NORMALIZE_FORMAT = "NFD";
  const accentsAndDiacriticsRegex = /[\u0300-\u036f]/g;

  return input
    .normalize(NORMALIZE_FORMAT)
    .replace(accentsAndDiacriticsRegex, "");
};

const removeSpecialCharacters = (input) => {
  const whiteListRegex = /[^a-zA-Z0-9 .-_]/g;

  let inputWithoutSpecialCharacters = input.replace(whiteListRegex, "");
  if (!inputWithoutSpecialCharacters) {
    inputWithoutSpecialCharacters = "default";
  }

  return inputWithoutSpecialCharacters;
};

export const sanitizeFileName = (fileName) => {
  const fileNameWithoutAccensAndDiacritics =
    removeAccentsAndDiacritics(fileName);
  const sanitiziedFileName = removeSpecialCharacters(
    fileNameWithoutAccensAndDiacritics
  );

  return sanitiziedFileName;
};

export const textToCapitalize = (text) => {
  let transformText = text.toLowerCase();
  return transformText.trim().replace(/^\w/, (c) => c.toUpperCase());
};
