import styled from "styled-components";
import { palette } from "../../theme/partials";

const Sidebar = styled.div`
  background-color: ${palette.secondary};
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 24px 28px 24px 18px;
  height: 100vh;
  width: 40px;
  transition: all 0.1s ease;

  img {
    margin-top: 30px;
  }

  p {
    display: none;
  }

  @media screen and (min-width: 992px) {
    padding: 24px;
    width: 206px;

    p {
      display: block;
    }
  }
`;

const Menu = styled.nav`
  display: inline;
  margin-top: 20px;
  width: 100%;

  a {
    display: flex;
    cursor: pointer;
    flex-direction: row;
    align-items: center;
    text-list-decortation: none;
    margin: 10px 0;
    padding: 6px 4px;
    transition: all 0.3s ease;
    width: 100%;

    svg {
      margin-right: 8px;
      color: ${palette.neutral};
    }

    &.active {
      background-color: ${palette.secondaryContainer};
      border-radius: 8px;
  
      svg {
        color: ${palette.primary} !important;
      }
  
      p {
        color: ${palette.white} !important;
      }
    }
  
    &:hover {
      background-color: ${palette.secondaryContainer};
      border-radius: 8px;
  
      svg {
        color: ${palette.primary} !important;
      }
  
      p {
        color: ${palette.white} !important;
      }
    }
  }
`;

const SidebarStyle = {
  Sidebar,
  Menu
};

export default SidebarStyle;
