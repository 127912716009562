import styled from "styled-components";
import { palette, variants } from "../../../theme/partials";

const Main = styled.div`
  align-items: flex-start;
  background-color: ${palette.white};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 26px 80px;

  h3 {
    margin-bottom: 15px;
  }

  p {
    margin: 12px 0;
    text-align: center;
  }
`;

const Heading = styled.div`
  margin: 20px 0;

  p {
    text-align: left;
  }
`;

const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  padding: 26px 80px;
  margin-top: 16px;

  a {
    margin-top: 30px;
  }
`;

const FieldWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 20px;
  width: 100%;
`;

const ContentWrapper = styled.div`
  margin: 30px 0 20px 0;
`;

const Content = styled.div`
  width: 100%;

  p {
    text-align: left !important;
  }

  h5 {
    font-weight: 500 !important;
  }

  h6 {
    margin-top: 26px;
  }
`;

const UploadImage = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;

  a {
    padding: 0 6px 0 12px;
  }
`;

const HtmlPickerWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;
`;

const Picker = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-right: 80px;

  h6 {
    margin-top: 0 !important;
  }
`;

const InfoRange = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  h5 {
    margin-right: 5px !important;
  }

  img {
    margin: 0 2px;

    width: 18px;

    &:first-child {
      margin: 0 6px 0 8px !important;
    }
  }
`;

const GridRanges = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
  margin: 16px 0;
`;

const breadcrumb = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 30px;
  align-items: center;

  a {
    margin-right: 6px;
    margin-left: 6px;

    &:first-child {
      margin-right: 6px;
      margin-left: 0;
    }
  }
`;

const Range = styled.div`
  background: #f4f4f4;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  border: 1px solid #f4f4f4;
  transition: all 0.5s ease;

  .head {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  h5,
  p {
    text-align: center !important;
    padding: 0 !important;
    margin: 4px 0 !important;
  }

  &:hover {
    background-color: ${palette.primaryContainer};
    border: 1px solid ${palette.primary};
  }

  .options {
    display: none;
  }

  &:hover .options {
    display: block;
  }
`;

const AccordionPanel = styled.div`
  margin-top: 20px;

  .MuiPaper-root {
    margin-top: 10px;
    box-shadow: none;
    border-radius: 8px;
    background-color: ${palette.neutral95};
    position: inherit;

    h5 {
      color: ${palette.neutral30} !important;
    }

    &.Mui-expanded {
      background-color: ${palette.primaryContainer};
      border: 1px solid ${palette.onBorder};

      h5 {
        color: ${palette.secondaryContainer} !important;
      }
    }
  }
`;

const CheckBoxWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  padding: 0 4px;
  width: 100%;

  span {
    color: ${palette.primary};
    font-size: ${variants.small};
  }
`;

const defaultWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: row;
  border-radius: 12px;
  width: 100%;

  .content {
    padding: 0;
    width: 44px !important;

    span {
      padding-top: 0;
      margin-top: -10px;
    }
  }

  span {
    color: ${palette.primary};
    font-size: ${variants.small};
  }
`;

const Steps = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: row;
  background: ${palette.primaryContainer};
  padding: 0 4px;
  border-radius: 12px;
  border: 1px solid ${palette.primary};
  width: 100%;
  margin-bottom 40px;
  margin-top 30px;
  width: auto;

  &.graphic {
    flex-direction: column;
    overflow: hidden;
    padding: 0 10px;
    height: 90px;

    .score {
      display: flex;
      flex-direction: row;
      padding: 10px;
      justify-content: space-between;
      width: 100%;
      position: relative;
      padding-top: 0;
      
      &:before{
        content:" ";
        display: block;
        height: 1px;
        width: 92%;
        position: absolute;
        top: 44px;
        left: 4%;
        background: ${palette.neutral30};
        opacity: 0.3;
      }

      .point {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        &.active {
          p {
            background-color: ${palette.primary} !important;
            color: ${palette.white} !important;;
            width: 26px;
            height: 16px;
            border-radius: 12px;
            display: flex;
            flex-flow: row;
            justify-content: center;
            align-items: center;
          }

          .circle {
            background-color: ${palette.primary};

            &:before{
              position: absolute;
              top: -5px;
              left: -5px;
              content: '';
              background-color: ${palette.primary};
              opacity: 0.3;
              width: 18px;
              border-radius: 50%;
              height: 18px;
            }
          }
        }

        .circle {
          width: 8px;
          position: absolute;
          top: 40px;
          height: 8px;
          border-radius: 50%;
          background-color: ${palette.secondary};
        }
      }
    }

    .names {
      display: flex;
      flex-direction: row;
      padding: 10px;
      justify-content: space-between;
      width: 90%;
      position: relative;

      .ref p {
        margin: 0;
      }
    }
  }

  &.add-content {
    background: ${palette.neutral95};
    border: 1px solid ${palette.neutral95};
    height: 53px;
    justify-content: flex-start;
    border-radius: 0;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;

    h5 {
      margin-left: 10px;
    }
    
    img {
      margin-left: 20px;
    }
  }
  
  &.add-values {
    display: flex;
    flex-direction: column;
    padding: 0 10px 20px 10px;
    margin-top: 10px;

    label {
      font-size: 12px !important;
    }
    
    h5 {
      margin-left: 10px;
    }
    
    img {
      margin-left: 20px;
    }

    .MuiInputBase-formControl {
      height: 40px !important;
      padding: 0 8px !important;
      font-size: 12px !important;
    }

    .values {
      margin-top: 10px !important;
    }
  }
  
  &.preview-content {
    background: ${palette.neutral95};
    border: 1px solid ${palette.neutral95};
    flex-direction: column;
    justify-content: center;
    border-radius: 0;
    align-items: flex-start;
    margin-bottom: 10px;
    margin-top: 10px;

    h5 {
      margin-left: 10px;
      margin-top: 10px;
      margin-bottom: 5px;
    }
    
    img {
      margin-left: 20px;
    }

    p {
      margin: 0;
      padding: 0;
      padding-left: 10px;
      margin-bottom: 10px;
    }
  }
`;

const PreviewtWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  margin-bottom: 30px;

  label {
    font-size: 14px !important;
  }
`;

const option = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end !important;
  align-items: center !important;
  width: 100%;

  label {
    font-size: 14px !important;
  }
`;

const Header = styled.div`
  background-color: #F6F8FF;
  padding: 0 18px;
  width: auto
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  
  .breadcrumb {
    margin-top: 0 !important;
  }
}
`;

const Name = styled.div`
  background-color: #F6F8FF;
  
  h3 {
    margin-top: 40px;
    font-weight: 500 !important;
  }
}
`;

const InputName = styled.div`
  width: 60%;
  margin-top: 30px;
`;

const Wrapper = styled.div`
  padding: 0 20px;
`;

const Colors = styled.div`
  padding: 30px 0;
`;

const wrapperScenaries = styled.div`
  padding: 30px 0 0 0;
`;

const Fields = styled.div`
  width: 60%;
`;

const RowPerColumns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 30px 0;

  &.full-width {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
`;

const EditStyles = {
  Main,
  Heading,
  ButtonWrapper,
  FieldWrapper,
  HtmlPickerWrapper,
  ContentWrapper,
  Content,
  Picker,
  defaultWrapper,
  AccordionPanel,
  UploadImage,
  GridRanges,
  option,
  CheckBoxWrapper,
  Range,
  InfoRange,
  PreviewtWrapper,
  breadcrumb,
  Steps,
  Header,
  Name,
  InputName,
  Colors,
  Wrapper,
  RowPerColumns,
  Fields,
  wrapperScenaries
};

export default EditStyles;
