import React from "react";
import TemplateStyles from "../createTemplate.styled";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "../../common/typography";
import { palette, variants } from "../../../theme/partials";
import FieldTextLimit from "../../../helper/field";
import { translation } from "../../../constant";

const InputComponents = ({
  sourceData,
  setInputFieldComponents,
  templateName,
}) => {
  const { staticText } = translation;

  return (
    <>
      <Typography
        text={
          templateName
            ? `${staticText.components} "${templateName}"`
            : `${staticText.components}`
        }
        variant={variants.small}
        color={palette.secondary}
        asTag={"h6"}
      />

      {templateName && (
        <Typography
          text={staticText.customizeTitles}
          variant={variants.small}
          color={palette.neutral20}
          asTag={"p"}
        />
      )}

      <TemplateStyles.FieldWrapper>
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">
            {staticText.creditPoint}
          </InputLabel>
          <OutlinedInput
            id={"credit-point"}
            name={staticText.creditPoint}
            label={staticText.creditPoint}
            type={"text"}
            placeholder={staticText.yourScore}
            required
            value={sourceData?.score}
            onChange={(e) => setInputFieldComponents(e, "score")}
            inputProps={{ maxLength: 34 }}
          />
          <FieldTextLimit currentValue={sourceData?.score} limit={34} />
        </FormControl>
      </TemplateStyles.FieldWrapper>

      <TemplateStyles.FieldWrapper>
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">
            {staticText.compositionAndStrategies}
          </InputLabel>
          <OutlinedInput
            id={"composition-strategies"}
            name={staticText.compositionAndStrategies}
            label={staticText.compositionAndStrategies}
            type={"text"}
            placeholder={staticText.knowMoreYouScore}
            required
            value={sourceData?.points}
            onChange={(e) => setInputFieldComponents(e, "points")}
            inputProps={{ maxLength: 34 }}
          />
          <FieldTextLimit currentValue={sourceData?.points} limit={34} />
        </FormControl>
      </TemplateStyles.FieldWrapper>

      <TemplateStyles.FieldWrapper>
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">
            {staticText.historyScore}
          </InputLabel>
          <OutlinedInput
            id={"history-score"}
            name={staticText.historyScore}
            label={staticText.historyScore}
            type={"text"}
            placeholder={staticText.scoreEvolution}
            required
            value={sourceData?.history}
            onChange={(e) => setInputFieldComponents(e, "history")}
            inputProps={{ maxLength: 34 }}
          />
          <FieldTextLimit currentValue={sourceData?.history} limit={34} />
        </FormControl>
      </TemplateStyles.FieldWrapper>

      <TemplateStyles.FieldWrapper>
        <FormControl variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">
            {staticText.subscriptionAndNotifications}
          </InputLabel>
          <OutlinedInput
            id={"subscription-and-notifications"}
            name={staticText.subscriptionAndNotifications}
            label={staticText.subscriptionAndNotifications}
            type={"text"}
            placeholder={staticText.notificationsInput}
            required
            value={sourceData?.notify}
            onChange={(e) => setInputFieldComponents(e, "notify")}
            inputProps={{ maxLength: 34 }}
          />
          <FieldTextLimit currentValue={sourceData?.notify} limit={34} />
        </FormControl>
      </TemplateStyles.FieldWrapper>
    </>
  );
};

export default InputComponents;
