export const translation = {
  staticText: {
    templateName: "Nombre de la plantilla",
    inputPlaceholderName: "Ingresa nombre de plantilla",
    colors: "Colores",
    primaryColor: "Color primario",
    secondaryColor: "Color secundario",
    btnSaveAndContinue: "Guardar y continuar",
    btnExit: "Salir",
    logoCompany: "Logo de empresa",
    customAppearance: "Personalización de Apariencia",
    createCustomScenaries:
      "Define los escenarios que verán tus clientes y configura los puntajes entre ellos. Debes configurar un",
    minimun: "mínimo 3",
    maximun: "máximo 5",
    scenaries: "escenarios",
    andA: "y un",
    scenarieDefinition: "Definición de escenarios",
    customizeTitles:
      "Personaliza los títulos de los componentes. Asegúrate de que sean simples de comprender.",
    components: "Componentes",
    creditPoint: "Puntaje crediticio",
    yourScore: "Tu puntaje",
    compositionAndStrategies:
      "Composición del puntaje y estrategias para incrementarlo",
    knowMoreYouScore: "Conoce más sobre tu puntaje",
    historyScore: "Puntaje histórico y comparativa con otros usuarios",
    scoreEvolution: "¿Cómo ha evolucionado tu puntaje?",
    subscriptionAndNotifications: "Suscripción a notificaciones",
    notificationsInput: "Recibe actualizaciones de tu score",
    name: "Nombre",
    start: "Desde",
    end: "Hasta",
    removeScenarieTitle: "Eliminar scenario",
    removeScenarieDescription:
      "Al eliminar un escenario, todos los valores se reestablecerán por defecto, y deberás configurarlos nuevamente.",
    removeScenarieSubDescription:
      "¿Está seguro que desea eliminar éste escenario?",
    yesRemove: "Si, Eliminar",
    noClose: "No, Salir",
    noContinue: "No, Continuar",
    scoreUpdated:
      "Tu puntaje crediticio ha sido actualizado, ingresa a la app para obtener más detalles.",
    customizationEditScenaries: "Customización de escenarios",
    sortComponent: "Orden de los componentes",
    updateUserNotifications:
      "El usuario puede elegir la frecuencia en que recibirá notificaciones ",
    putContentMessage: "Ingresa el contenido del mensaje",
    customNotificationMessage:
      "Personaliza el mensaje de las notificaciones que recibirá el usuario",
    notificationContent: "Contenido de las notificaciones",
    errorNotificationMesage:
      "Debes seleccionar al menos una opción para continuar",
    settingsNotifications: "Configuración de las notifcaciones",
    orderComponents:
      "Configura el orden en el que los usuarios podrán visualizar los componentes arrastrando las opciones.",
    createNewTemplate: "Crear plantilla",
    findTemplate: "Buscar plantilla",
    templateCreated: "Plantilla creada",
    templateCreatedSuccessfully: "¡Tu plantilla ha sido creada con éxito!",
    removeTemplate: "Eliminar plantilla",
    removeTemplateDescription:
      "Al eliminar esta plantilla, perderás toda la información que contiene.",
    removeTemplateSub: "¿Está seguro que desea eliminar esta plantilla?",
    templateRemoved: "Plantilla eliminada",
    templateDescriptionRemoved: "¡Tu plantilla ha sido eliminada con éxito!",
    draftTitle: "Guardar esta plantilla en borrador",
    draftDescription:
      "Al salir su plantilla se guardará automáticamente y podrá finalizarla luego.",
    draftSubDescription: "¿Está seguro que desea salir?",
    draftConfirm: "Sí, salir",
    draftCancel: "No, continuar",
    addScenaries: "Agregar escenario"
  }
};

export const TYPES = {
  PUNTAJE_CREDITICIO: "PUNTAJE_CREDITICIO",
  COMPOSICIÓN_PUNTAJE_ESTRATEGIAS: "COMPOSICIÓN_PUNTAJE_ESTRATEGIAS",
  PUNTAJE_HISTÓRICO_COMPARATIVA: "PUNTAJE_HISTÓRICO_COMPARATIVA",
  SUSCRIPCIÓN_NOTIFICACIONES: "SUSCRIPCIÓN_NOTIFICACIONES"
};

export const RANGES = {
  initial: 300,
  end: 850
};

export const SCORE_RANGES = [
  {
    type: "NO_HIT",
    value: "No hit"
  },
  {
    type: "300-589",
    value: "300 a 589"
  },
  {
    type: "590-663",
    value: "590 a 663"
  },
  {
    type: "664-721",
    value: "664 a 721"
  },
  {
    type: "722-850",
    value: "722 a 850"
  }
];

export const SCORE_RANGES_MATCH = [
  "No hit",
  "300 a 589",
  "590 a 663",
  "664 a 721",
  "722 a 850"
];

export const GENERATION_TYPES = [
  {
    type: "GENERACION Z",
    value: "Generación Z"
  },
  {
    type: "MILLENNIAL",
    value: "Millenial"
  },
  {
    type: "GENERACION Y",
    value: "Generación Y"
  },
  {
    type: "GENERACION X",
    value: "Generación X"
  },
  {
    type: "BABY BOOMER",
    value: "Baby boomer"
  },
  {
    type: "SILENT",
    value: "Silent"
  }
];

export const SESSION = {
  name: "_s_cf_gma",
  type: "_s_cf_type"
};

export const COLORS = {
  primary: "#000",
  secondary: "#0d3"
};

export const DATA_STUB = {
  templateSteps: [
    "Personalización de Apariencia",
    "Definición de escenarios y componentes",
    "Customización de escenarios"
  ],
  templateTable: {
    headers: [
      {
        name: "Nombre",
        field: "nombre",
        sortable: true
      },
      {
        name: "Fecha de modificación",
        field: "fechaModificacion",
        sortable: true
      },
      {
        name: "Estado",
        field: "estado",
        sortable: true
      },
      {
        name: "",
        field: "",
        sortable: false
      }
    ]
  },
  inputFieldsComponents: {
    score: "Tu Puntaje",
    points: "Conoce más sobre tu puntaje",
    history: "¿Cómo ha evolucionado tu puntaje?",
    notify: "Recibe actualizaciones de tu score"
  },
  notifications: [
    {
      id: 0,
      label: "Cada 15 días",
      checked: false,
      enum: "15_DIAS"
    },
    {
      id: 1,
      label: "Cada mes",
      checked: false,
      enum: "1_MES"
    },
    {
      id: 2,
      label: "Cada 2 meses",
      checked: false,
      enum: "2_MESES"
    },
    {
      id: 3,
      label: "Cada 3 meses",
      checked: false,
      enum: "3_MESES"
    },
    {
      id: 4,
      label: "Cada 6 meses",
      checked: false,
      enum: "6_MESES"
    }
  ],
  notificationData: [
    {
      id: 0,
      label: "Cada 15 días",
      checked: true,
      enum: "15_DIAS"
    },
    {
      id: 1,
      label: "Cada mes",
      checked: false,
      enum: "1_MES"
    },
    {
      id: 2,
      label: "Cada 2 meses",
      checked: false,
      enum: "2_MESES"
    },
    {
      id: 3,
      label: "Cada 3 meses",
      checked: false,
      enum: "3_MESES"
    },
    {
      id: 4,
      label: "Cada 6 meses",
      checked: false,
      enum: "6_MESES"
    }
  ],
  scenaries: {
    case1: [
      {
        name: "Bajo",
        start: RANGES.initial,
        end: 589
      },
      {
        name: "Regular",
        start: 590,
        end: 663
      },
      {
        name: "Bueno",
        start: 664,
        end: 721
      },
      {
        name: "Excelente",
        start: 722,
        end: RANGES.end
      }
    ],
    case2: [
      {
        name: "Bajo",
        start: RANGES.initial,
        end: 589
      },
      {
        name: "Regular",
        start: 590,
        end: 663
      },
      {
        name: "Bueno",
        start: 664,
        end: 721
      },
      {
        name: "Excelente",
        start: 722,
        end: RANGES.end
      }
    ],
    case3: [
      {
        name: "Bajo",
        start: RANGES.initial,
        end: 589
      },
      {
        name: "Regular",
        start: 590,
        end: 663
      },
      {
        name: "Bueno",
        start: 664,
        end: 721
      },
      {
        name: "Excelente",
        start: 722,
        end: RANGES.end
      }
    ]
  }
};

export const INITIAL_DATA_TEMPLATE = {
  currentStep: 1,
  name: "",
  templateId: "",
  logo: null,
  color: {
    primary: COLORS.primary,
    secondary: COLORS.secondary
  },
  scenaries: [],
  components: [],
  fieldsComponent: {
    score: "Tu Puntaje",
    points: "Conoce más sobre tu puntaje",
    history: "¿Cómo ha evolucionado tu puntaje?",
    notify: "Recibe actualizaciones de tu score"
  },
  notifications: []
};

export const environment = {
  path_prefix: {
    public: "/authentication",
    platform: "/platform",
    users: "/users",
    dashboard: "/statistic"
  },
  endpoints: {
    login: "/authentication/login",
    logout: "/authentication/logout",
    refreshToken: "/authentication/token/refresh",
    getTemplate: "/platform/template",
    addNewTemplate: "/platform/template/name",
    uploadImageTemplate: "/platform/file/upload",
    addTemplateAppearance: "/platform/template/appearance",
    addTemplateStage: "/platform/template/stage",
    getTemplateById: "/platform/template/",
    downloadImageTemplate: "/platform/file/download",
    addNewUser: "/users"
  }
};

export const graphic = [
  {
    date: "2022/07",
    "722 a 850": 116.5,
    "722 a 850 color": "#4CB86A",
    "664 a 721": 127,
    "664 a 721 color": "#3F59A7",
    "590 a 663": 93.2,
    "590 a 663 color": "#F3A260",
    "300 a 589": 84,
    "300 a 589 color": "#D6CA46",
    nohit: 228.4,
    nohitColor: "#90909A"
  },
  {
    date: "2022/08",
    "722 a 850": 116.5,
    "722 a 850 color": "#4CB86A",
    "664 a 721": 217,
    "664 a 721 color": "#3F59A7",
    "590 a 663": 132,
    "590 a 663 color": "#F3A260",
    "300 a 589": 181,
    "300 a 589 color": "#D6CA46",
    nohit: 101.3,
    nohitColor: "#90909A"
  },
  {
    date: "2022/09",
    "722 a 850": 116.5,
    "722 a 850 color": "#4CB86A",
    "664 a 721": 27,
    "664 a 721 color": "#3F59A7",
    "590 a 663": 132,
    "590 a 663 color": "#F3A260",
    "300 a 589": 48,
    "300 a 589 color": "#D6CA46",
    nohit: 39.4,
    nohitColor: "#90909A"
  },
  {
    date: "2022/10",
    "722 a 850": 216.5,
    "722 a 850 color": "#4CB86A",
    "664 a 721": 127,
    "664 a 721 color": "#3F59A7",
    "590 a 663": 132,
    "590 a 663 color": "#F3A260",
    "300 a 589": 48,
    "300 a 589 color": "#D6CA46",
    nohit: 43.3,
    nohitColor: "#90909A"
  },
  {
    date: "2022/11",
    "722 a 850": 56.5,
    "722 a 850 color": "#4CB86A",
    "664 a 721": 57,
    "664 a 721 color": "#3F59A7",
    "590 a 663": 132,
    "590 a 663 color": "#F3A260",
    "300 a 589": 82,
    "300 a 589 color": "#D6CA46",
    nohit: 82.5,
    nohitColor: "#90909A"
  },
  {
    date: "2022/12",
    "722 a 850": 216.5,
    "722 a 850 color": "#4CB86A",
    "664 a 721": 327,
    "664 a 721 color": "#3F59A7",
    "590 a 663": 32,
    "590 a 663 color": "#F3A260",
    "300 a 589": 38,
    "300 a 589 color": "#D6CA46",
    nohit: 44.3,
    nohitColor: "#90909A"
  },
  {
    date: "2023/01",
    "722 a 850": 126.5,
    "722 a 850 color": "#4CB86A",
    "664 a 721": 127,
    "664 a 721 color": "#3F59A7",
    "590 a 663": 232,
    "590 a 663 color": "#F3A260",
    "300 a 589": 58,
    "300 a 589 color": "#D6CA46",
    nohit: 92.5,
    nohitColor: "#90909A"
  },
  {
    date: "2023/02",
    "722 a 850": 36.5,
    "722 a 850 color": "#4CB86A",
    "664 a 721": 37,
    "664 a 721 color": "#3F59A7",
    "590 a 663": 332,
    "400 a 4 99 color": "#F3A260",
    "300 a 589": 38,
    "300 a 589 color": "#D6CA46",
    nohit: 40.4,
    nohitColor: "#90909A"
  },
  {
    date: "2023/03",
    "722 a 850": 316.5,
    "722 a 850 color": "#4CB86A",
    "664 a 721": 67,
    "664 a 721 color": "#3F59A7",
    "590 a 663": 132,
    "590 a 663 color": "#F3A260",
    "300 a 589": 48,
    "300 a 589 color": "#D6CA46",
    nohit: 71.3,
    nohitColor: "#90909A"
  },
  {
    date: "2023/04",
    "722 a 850": 206.4,
    "722 a 850 color": "#4CB86A",
    "664 a 721": 131.4,
    "664 a 721 color": "#3F59A7",
    "590 a 663": 132.3,
    "590 a 663 color": "#F3A260",
    "300 a 589": 38.3,
    "300 a 589 color": "#D6CA46",
    nohit: 222.1,
    nohitColor: "#90909A"
  },
  {
    date: "2023/05",
    "722 a 850": 116.5,
    "722 a 850 color": "#4CB86A",
    "664 a 721": 127,
    "664 a 721 color": "#3F59A7",
    "590 a 663": 232,
    "590 a 663 color": "#F3A260",
    "300 a 589": 78,
    "300 a 589 color": "#D6CA46",
    nohit: 60.5,
    nohitColor: "#90909A"
  }
];

export const lineal = [
  {
    statement: "it's good",
    participation: 0,
    stronglyAgree: 22,
    agree: 14,
    disagree: 29,
    stronglyDisagree: 20
  },
  {
    statement: "it's sweet",
    participation: 6,
    stronglyAgree: 13,
    agree: 0,
    disagree: 10,
    stronglyDisagree: 12
  },
  {
    statement: "it's spicy",
    participation: 28,
    stronglyAgree: 20,
    agree: 17,
    disagree: 17,
    stronglyDisagree: 27
  },
  {
    statement: "worth eating",
    participation: 21,
    stronglyAgree: 26,
    agree: 18,
    disagree: 21,
    stronglyDisagree: 22
  },
  {
    statement: "worth buying",
    participation: 12,
    stronglyAgree: 24,
    agree: 2,
    disagree: 25,
    stronglyDisagree: 4
  }
];

export const geoLocate = [
  {
    percent: 34,
    entity: "Aguascalientes",
    users: 45,
    id: "MX-AGU",
    isAvailable: true
  },
  {
    percent: 85,
    entity: "Baja California Norte",
    users: 488,
    id: "MX-BCN",
    isAvailable: true
  },
  {
    percent: 85,
    entity: "Baja California Sur",
    users: 123,
    id: "MX-BCS",
    isAvailable: true
  },
  {
    percent: 27,
    entity: "Campeche",
    users: 4543,
    id: "MX-CAM",
    isAvailable: true
  },
  {
    percent: 73,
    entity: "Chiapas",
    users: 4574,
    id: "MX-CHP",
    isAvailable: true
  },
  {
    percent: 66,
    entity: "Chihuaha",
    users: 89000,
    id: "MX-CHH",
    isAvailable: true
  },
  {
    percent: 80,
    entity: "Coahuila",
    users: 90000,
    id: "MX-COA",
    isAvailable: true
  },
  {
    percent: 33,
    entity: "Colima",
    users: 90100,
    id: "MX-COL",
    isAvailable: true
  },
  {
    percent: 52,
    entity: "Ciudad de México",
    users: 12000,
    id: "MX-DIF",
    isAvailable: true
  },
  {
    percent: 87,
    entity: "Distrito Federal",
    users: 23400,
    id: "MX-DIF",
    isAvailable: false
  },
  {
    percent: 77,
    entity: "Durango",
    users: 23400,
    id: "MX-DUR",
    isAvailable: true
  },
  {
    percent: 22,
    entity: "Guanajuato",
    users: 23450,
    id: "MX-GUA",
    isAvailable: true
  },
  {
    percent: 33,
    entity: "Guerrero",
    users: 34514.4,
    id: "MX-GRO",
    isAvailable: true
  },
  {
    percent: 99,
    entity: "Hidalgo",
    users: 45670,
    id: "MX-HID",
    isAvailable: true
  },
  {
    percent: 83,
    entity: "Jalisco",
    users: 56780,
    id: "MX-JAL",
    isAvailable: true
  },
  {
    percent: 20,
    entity: "México",
    users: 67890,
    id: "MX-MEX",
    isAvailable: true
  },
  {
    percent: 11,
    entity: "Michoacán",
    users: 78901,
    id: "MX-MIC",
    isAvailable: true
  },
  {
    percent: 46,
    entity: "Morelos",
    users: 89012,
    id: "MX-MOR",
    isAvailable: true
  },
  {
    percent: 99,
    entity: "Nayarit",
    users: 90123,
    id: "MX-NAY",
    isAvailable: true
  },
  {
    percent: 94,
    entity: "Nuevo León",
    users: 91234,
    id: "MX-NLE",
    isAvailable: true
  },
  {
    percent: 45,
    entity: "Oaxaca",
    users: 12345,
    id: "MX-OAX",
    isAvailable: true
  },
  {
    percent: 23,
    entity: "Puebla",
    users: 23456,
    id: "MX-PUE",
    isAvailable: true
  },
  {
    percent: 7,
    entity: "Querétaro",
    users: 34567,
    id: "MX-QUE",
    isAvailable: true
  },
  {
    percent: 37,
    entity: "Qintana Roo",
    users: 45678,
    id: "MX-ROO",
    isAvailable: true
  },
  {
    percent: 32,
    entity: "San Luis Potosí",
    users: 56789,
    id: "MX-SLP",
    isAvailable: true
  },
  {
    percent: 99,
    entity: "Sinaloa",
    users: 67890,
    id: "MX-SIN",
    isAvailable: true
  },
  {
    percent: 79,
    entity: "Sonora",
    users: 98765,
    id: "MX-SON",
    isAvailable: true
  },
  {
    percent: 77,
    entity: "Tabasco",
    users: 87654,
    id: "MX-TAB",
    isAvailable: true
  },
  {
    percent: 34,
    entity: "Tamaulipas",
    users: 76543,
    id: "MX-TAM",
    isAvailable: true
  },
  {
    percent: 44,
    entity: "Tlaxcala",
    users: 65432,
    id: "MX-TLA",
    isAvailable: true
  },
  {
    percent: 54,
    entity: "Veracruz",
    users: 54321,
    id: "MX-VER",
    isAvailable: true
  },
  {
    percent: 28,
    entity: "Yucatán",
    users: 43210,
    id: "MX-YUC",
    isAvailable: true
  },
  {
    percent: 48,
    entity: "Zacatecas",
    users: 32109,
    id: "MX-ZAC",
    isAvailable: true
  }
];

export const INPUT_PROVIDENCE = [
  {
    providence: "Aguascalientes",
    id: "AGS"
  },
  {
    providence: "Baja California Norte",
    id: "BC"
  },
  {
    providence: "Baja California Sur",
    id: "BCS"
  },
  {
    providence: "Campeche",
    id: "CAMP"
  },
  {
    providence: "Chiapas",
    id: "CHIS"
  },
  {
    providence: "Chihuaha",
    id: "CHIH"
  },
  {
    providence: "Coahuila",
    id: "COAH"
  },
  {
    providence: "Colima",
    id: "COL"
  },
  {
    providence: "Ciudad de México",
    id: "CDMX"
  },
  {
    providence: "Distrito Federal",
    id: "DF"
  },
  {
    providence: "Durango",
    id: "DGO"
  },
  {
    providence: "Guanajuato",
    id: "GTO"
  },
  {
    providence: "Guerrero",
    id: "GRO"
  },
  {
    providence: "Hidalgo",
    id: "HGO"
  },
  {
    providence: "Jalisco",
    id: "JAL"
  },
  {
    providence: "México",
    id: "MEX"
  },
  {
    providence: "Michoacán",
    id: "MICH"
  },
  {
    providence: "Morelos",
    id: "MOR"
  },
  {
    providence: "Nayarit",
    id: "NAY"
  },
  {
    providence: "Nuevo León",
    id: "NL"
  },
  {
    providence: "Oaxaca",
    id: "OAX"
  },
  {
    providence: "Puebla",
    id: "PUE"
  },
  {
    providence: "Querétaro",
    id: "MX-QRO"
  },
  {
    providence: "Qintana Roo",
    id: "QROO"
  },
  {
    providence: "San Luis Potosí",
    id: "SLP"
  },
  {
    providence: "Sinaloa",
    id: "SIN"
  },
  {
    providence: "Sonora",
    id: "SON"
  },
  {
    providence: "Tabasco",
    id: "TAB"
  },
  {
    providence: "Tamaulipas",
    id: "TAMP"
  },
  {
    providence: "Tlaxcala",
    id: "TLAX"
  },
  {
    providence: "Veracruz",
    id: "VER"
  },
  {
    providence: "Yucatán",
    id: "YUC"
  },
  {
    providence: "Zacatecas",
    id: "ZAC"
  }
];

export const additionalDataBeforeAdd = {
  components: [
    { type: "PUNTAJE_CREDITICIO", sort: 1 },
    { type: "COMPOSICIÓN_PUNTAJE_ESTRATEGIAS", sort: 2 },
    { type: "PUNTAJE_HISTÓRICO_COMPARATIVA", sort: 3 },
    { type: "SUSCRIPCIÓN_NOTIFICACIONES", sort: 4 }
  ],
  content:
    "Tu puntaje crediticio ha sido actualizado, ingresa a la app para obtener más detalles.",
  notifications: ["15_DIAS"]
};

export const MARKDOWN_CONTENT = `
## Credenciales de acceso API Hub

Para el consumo de los recursos que Círculo de Crédito y de sus diversas soluciones, es necesario que el otorgante (próximamente denominara como cliente) debe contar con credenciales de acceso para uso de estos servicios, por ello existe una guía que permite al cliente obtener mencionados accesos, a continuación se adjunta el enlace  [API Hub - Círculo de Crédito](https://pdmx-qa-drupal8.circuloapps.com/guia-de-inicio).

## Solicitar código de acceso a Score as a Service

Para consumir el servicio de Score as a Service se requiere tener un código de acceso que permite asociar la sesión actual del usuario final con la aplicación, para ello se ocupa la siguiente definición.

### Headers

Requiere del siguiente encabezado para su correcto funcionamiento.

| Encabezado  | Detalle |
|  :------------: | ------------- |
| XTokenAPIHub  | Token de Api Hub  |

### URL
**POST:** https://circulodecredito-dev.apigee.net/v1/scoreAsaService/auth/generate-access

### Body
El presente endpoint requiere de la siguiente información para verificar si existen datos del usuario.

~~~json
{  
  "nombre": "JUAN",
  "apellidoPaterno": "PRUEBA",
  "apellidoMaterno": "CUATRO",
  "fechaNacimiento": "04-01-1980",
  "direccion": "INSURGENTES SUR 1006",
  "colonia": "Centro",
  "ciudad": "BENITO JUAREZ",
  "rfc": "PUSJ800107H2O",
  "estado": "DF",
  "pais": "MX",
  "delegacionMunicipio": "BENITO JUAREZ",
  "cp": "04480",
  "telefono": "+5483741843",
  "idOtorgante": "00006",
  "autorizacion": true
}
~~~

**Nota:** Los campos a continuación  requieren de las siguientes consideraciones.

- **telefono:** Requiere adjuntar el prefijo del país seguido del número de teléfono del usuario.
- **autorizacion:** Se espera un valor booleano en true, asumiendo que el otorgante informo y solicito al usuario final el consentimiento para acceder a su información.
- **idOtorgante:** identificador del otorgante

\n &nbsp;

### Response
Ejemplo de un resultado correcto.

~~~json
{  
  "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzOTI0YzJiODNjMjViZDg1YmJjMGNiYiIsImlhdCI6MTY3MjIzNzgwNywiZXhwIjoxNjcyMjQ3ODA3fQ.0xXL9fsBwd93298LUtv85QhqRv10hN4Ai3vVF2ikBOo"
}
~~~

**Nota:** El código de acceso es un token que permite mantener la sesión activa entre Score as a Service y  el usuario final únicamente por 15 minutos, luego de este tiempo se deberá obtener un nuevo código de acceso.

Posibles respuestas que se pueden llegar a obtener en la petición.

\n &nbsp;

| Encabezado  | Tipo | Detalle |
|  :------------: | ------------- | ------------- |
| 200  | Ok  | Petición correcta.  |
| 400  | Petición incorrecta | Parámetros incorrectos.  |
| 401  | Sin autorización  | El token está expirado o no es válido, favor de solicitar uno nuevo.  |
| 404  | Recurso no encontrado  | No se ha encontrado el recurso solicitado.  |
| 500  | Error inesperado  | Error interno del servidor.  |

## Renderizar PWA Score as a Service
Como siguiente paso, se encuentra renderizar la aplicación PWA dentro de su aplicación principal embebida dentro de un WebView.

**Nota:** La URL para renderizar Score as a Service requiere de un parámetro que será establecido por Query Parameters (key=value), en base al formato la URL quedará de la siguiente manera. [https://url?token=token](https://url?token=token)

### EXPO
Se desarrolló una app mobile con EXPO muy sencilla, con un logo cliqueable, el cual renderiza el widget. En este punto el código de acceso se enviará por query params en la URL.

El código de acceso se obtiene del paso previo, apartado **Solicitar código de acceso a Score as a Service**

![nuevo](https://gamma-dev.circuloapps.com/img/background-saas.jpg)

En la app y dentro de un webView se configuró la lógica para la renderización del widget, enviado el código de acceso  Score as Service por query params.

### URL

**POST:** https://gamma-dev.circuloapps.com?token=token

### WebView
El siguiente fragmento de código permite establecer la carga del contenedor.

~~~java
export default function App() { 
  return (
    <WebView style={styles.container} 
      source={{ uri: https://gamma-dev.circuloapps.com?token=token 
    /> 
  ); 
}
~~~

### Android
Para agregar un elemento **WebView** a su aplicación, puede incluir el **<WebView>** elemento en el diseño de su actividad o configurar toda la ventana de Actividad como un  **WebView** archivo **onCreate()**.

#### Agregue un WebView en el diseño de la actividad
Para agregar un **WebView** a su aplicación en el diseño, agregue el siguiente código al archivo XML de diseño de su actividad:

~~~java
<WebView
    android:id="@+id/webview"
    android:layout_width="match_parent"
    android:layout_height="match_parent"
/>
~~~

Para cargar una página web en el **WebView**, use **loadUrl()**. Por ejemplo:

~~~java
WebView myWebView = (WebView) findViewById(R.id.webview);
myWebView.loadUrl("https://gamma-dev.circuloapps.com?token=token");
~~~

#### Agregar una WebView en onCreate()
Para agregar **WebView** a tu aplicación en el **onCreate()** método de una actividad, usa una lógica similar a la siguiente:

~~~java
WebView myWebView = new WebView(activityContext);
setContentView(myWebView);
~~~

Luego cargue la página con:

~~~java
myWebView.loadUrl("https://gamma-dev.circuloapps.com?token=token");
~~~

Sin embargo, antes de que esto funcione, su aplicación debe tener acceso a Internet. Para obtener acceso a Internet, solicite el **INTERNET** permiso en su archivo de manifiesto. Por ejemplo:

~~~xml
<manifest ... >
    <uses-permission android:name="android.permission.INTERNET" />
    ...
</manifest>
~~~

Eso es todo lo que necesita para un básico **WebView** que muestra una página web

**Nota:** Para obtener más información diríjase a la sección Desarrolladores de Android   a través del siguiente enlace [Cree aplicaciones web en WebView](https://developer.android.com/develop/ui/views/layout/webapps/webview).

`;
