import React, { useState } from 'react'
import Slider from 'react-slick'
import PanelComponent from '../Panel'
import PieChartComponent from '../Graphics/PieChartComposition'
import SeparatorComponent from '../Separator'
import ImageComponent from '../Images/ImageComponent'
import GraphicBarComponent from '../../components/GraphicBar/GraphicNormal'
import { Box, Typography, Button, ListItem } from '@mui/material'
import common from '../../common.json'
import InfoIcon from '../../static/icons/info.svg'
import ArrowUpIcon from '../../static/icons/arrow-up.svg'
import ArrowDownIcon from '../../static/icons/arrow-down.svg'
import ScheduleIcon from '../../static/icons/schedule.svg'
import ArrowChevronLeftIcon from '../../static/icons/arrow-chevron-left.svg'
import ArrowChevronRightIcon from '../../static/icons/arrow-chevron-right.svg'

const settings = {
	dots: true,
	infinite: false,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: false,
	slickNext: false,
	slickPrevious: false,
	swipe: true,
	adaptiveHeight: true,
}

const { sections, graphic } = common

const SliderComposition = () => {
	const [isCollapsedItems, setCollapsableItems] = useState(false)
	const [isCollapsed, setCollapsable] = useState(false)
	const [isCollapsedActiveAccount, setCollapsedActiveAccount] = useState(false)
	const [isCollapsedChart, setCollapsableChart] = useState(false)
	const [isCollapsedBar, setCollapsableBar] = useState(false)
	const [isCollapsableBarChart, setCollapsableBarChart] = useState(false)

	const handleCollapsablePanel = (idCase) => {
		if (idCase === 6) {
			setCollapsable(!isCollapsed)
		}

		if (idCase === 2) {
			setCollapsableBar(!isCollapsedBar)
		}

		if (idCase === 4) {
			setCollapsableBarChart(!isCollapsableBarChart)
		}

		if (idCase === 5) {
			setCollapsedActiveAccount(!isCollapsedActiveAccount)
		}

		if (idCase === 1) {
			setCollapsableItems(!isCollapsedItems)
		}

		if (idCase === 3) {
			setCollapsableChart(!isCollapsedChart)
		}
	}

	return (
		<Slider {...settings}>
			<div
				key={1}
				id="1"
				style={{
					display: 'block',
					overflow: 'hidden',
					padding: '10px',
					width: '100%',
				}}
			>
				<ListItem
					style={{
						padding: '10px 4px',
					}}
				>
					<Typography variant="headingIndicator" className="padding">
						{'Tu score se calculó teniendo en cuenta los siguientes factores:'}
					</Typography>
				</ListItem>

				<PanelComponent
					source={sections.betterScore}
					itemToShow={4}
					entireList={false}
				/>

				<>
					{isCollapsedItems && (
						<Box
							components={'div'}
							className="flex-row flex-start padding-content green-bg"
						>
							<ImageComponent source={InfoIcon} alt="info" />
							<Typography
								variant="infoText"
								style={{
									padding: '0px 10px',
								}}
							>
								Estos son los factores que más han reducido tu puntaje, en orden
								de mayor a menor impacto en tu puntaje. Sugerimos que trates de
								mejorar en estos factores para que tu puntaje aumente
								rápidamente.
							</Typography>
						</Box>
					)}
				</>

				<Box
					className="center"
					style={{
						marginTop: '10px',
						padding: '10px 4px',
					}}
					onClick={() => handleCollapsablePanel(1)}
				>
					<Button variant="link">
						{!isCollapsedItems ? 'Conocer más' : 'Ocultar'}
						{!isCollapsedItems ? (
							<ImageComponent source={ArrowDownIcon} alt="arrow" />
						) : (
							<ImageComponent source={ArrowUpIcon} alt="arrow" />
						)}
					</Button>
				</Box>
				<SeparatorComponent />
			</div>
		</Slider>
	)
}

export default SliderComposition
