import React, { useState } from 'react'
import {
	Box,
	ListItemText,
	Typography,
	Button,
	Grid,
	List,
	ListItem,
} from '@mui/material'

import ImageComponent from '../Images/ImageComponent'
import ArrowUpIcon from '../../static/icons/arrow-up.svg'
import ArrowDownIcon from '../../static/icons/arrow-down.svg'
import LightIcon from '../../static/icons/light.svg'
import LightIconSvg from '../../../icons/light';

const categoryItemComponent = (el) => {
	return (
		<Box component={'div'} xs={{ margin: '10px 0' }}>
			<ListItem>
				<Typography variant="categoryText">{el.heading}</Typography>
			</ListItem>
			{el.categoryList.map((indicator, index) => {
				return (
					<ListItem key={index}>
						<>
							{!indicator.icon && (
								<Typography className="bullet" variant="indicatorBullshit">
									•
								</Typography>
							)}
						</>
						<>
							{indicator.icon && (
								<Box component={'div'} className="bullshit-icon">
									<ImageComponent source={indicator.icon} alt="icon" />
								</Box>
							)}
						</>
						<ListItemText className="indicatorTextPreview" primary={indicator.text} />
					</ListItem>
				)
			})}
		</Box>
	)
}

const itemComponent = (el, secondaryColor) => {
	return (
		<>
			<ListItem>
				<Typography variant="indicatorItem">{el.title}</Typography>
			</ListItem>
			<ListItem>
				<>
					{!el.icon && <Typography variant="indicatorBullshit">•</Typography>}
				</>
				<>
					{el.icon && (
						<Box component={'div'} className="bullshit-icon">
							<LightIconSvg color={secondaryColor} />
						</Box>
					)}
				</>
				<ListItemText primary={el.text} className="indicatorTextPreview" />
			</ListItem>
		</>
	)
}
const PanelComponent = ({ source, itemToShow = 3, entireList = true, secondaryColor }) => {
	const {
		heading,
		data,
		actionButtonUrl,
		actionButtonText,
		actionHideButtonText,
		isCollapsable,
	} = source
	const [isCollapsed, setCollapsable] = useState(false)

	const handleCollapsablePanel = () => {
		setCollapsable(!isCollapsed)
	}

	return (
		<Grid item xs={12} md={6}>
			{heading && (
				<Typography variant="headingIndicator" className="padding">
					{heading}
				</Typography>
			)}
			<List dense={true} className="list">
				{data.map((el, i) => {
					if (!isCollapsed) {
						if (i + 1 <= itemToShow) {
							if (el?.heading && el?.categoryList) {
								return categoryItemComponent(el)
							} else {
								return itemComponent(el, secondaryColor)
							}
						}
					} else {
						if (el?.heading && el?.categoryList) {
							return categoryItemComponent(el, )
						} else {
							return itemComponent(el, secondaryColor)
						}
					}
				})}
			</List>

			{entireList && (
				<>
					<Box component={'div'} className="flex-column flex-start bg-main preview">
						<>
							{isCollapsable && data.length > itemToShow && (
								<>
									{!actionButtonUrl && (
										<Box className="center inherit-bg" onClick={handleCollapsablePanel}>
											<Button variant="link">
												{!isCollapsed ? actionButtonText : actionHideButtonText}
												{!isCollapsed ? (
													<ImageComponent source={ArrowDownIcon} alt="arrow" />
												) : (
													<ImageComponent source={ArrowUpIcon} alt="arrow" />
												)}
											</Button>
										</Box>
									)}
								</>
							)}
						</>
						<>
							{!isCollapsable && (
								<>
									{actionButtonText && actionButtonUrl && (
										<Box className="center inherit-bg">
											<Button href={actionButtonUrl} variant="link">
												{actionButtonText}
											</Button>
										</Box>
									)}
								</>
							)}
						</>
					</Box>
				</>
			)}
		</Grid>
	)
}

export default PanelComponent
