import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { tokenRefresh } from "../../services/authentication.service";
import { getParsedDataForRefresh, getTokenValues, setCtxSession } from "../../helper/authMapper";
import { useAuth } from "../../hooks/useAuth";
import { useIdleTimer } from "react-idle-timer";

function SessionIdleTimeout() {
  const timeout = 1000 * 60 * 15;
  const { handleLogout, forceLogout } = useAuth();
  const navigate = useNavigate();

  /**
   *  Logout by Screen inactivity
   * @param null
   */
  const handleOnIdle = async () => {
    await handleLogout();
    navigate("/", { replace: true });
  };

  /**
   * Refresh token every 15 minutes
   * @param null
   */
  const refreshSession = async () => {
    const token = getTokenValues();
    const { data } = await tokenRefresh({
      tokenRenovacion: token?.tokenRenovation
    });
    const { displayName } = token;
    const parsedData = getParsedDataForRefresh(data, displayName);
    setCtxSession(parsedData);
  };

  useEffect(() => {
    const token = getTokenValues();

    if (!token?.tokenRenovation) {
      return forceLogout();
    }

    refreshSession();
    const timeToRefresh = 1000 * 60 * 14;
    const interval = setInterval(() => {
      refreshSession();
    }, timeToRefresh);

    return () => clearInterval(interval);
  }, []);

  useIdleTimer({
    timeout,
    onIdle: handleOnIdle
  });

  return <></>
}

export default SessionIdleTimeout
