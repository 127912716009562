import { createContext, useContext, useMemo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import {
  getTokenValues,
  setCtxSession,
  setCookieData,
  removeCtxSession
} from "../helper/authMapper";
import { logIn, logout } from "../services/authentication.service";
import { isCookiesExist } from "../utils/context";
import { SESSION } from "../constant";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  const [isPending, setIsPending] = useState(false);
  const [user, setUser] = useLocalStorage(SESSION.type, null);
  const navigate = useNavigate();

  const onUserIsLogged = () => {
    const token = getTokenValues();

    if (token && token?.tokenExpiration) {
      setCtxSession(token);
      setUser({
        type: 1,
        displayName: token?.displayName
      });
    } else {
      window.localStorage.removeItem(SESSION.type);
      navigate("/", { replace: true });
    }
  };

  useEffect(() => {
    onUserIsLogged();
  }, [isCookiesExist]);

  /**
   * It should logout session
   * @param null
   */
  const handleLogout = async () => {
    try {
      const tokenSession = getTokenValues();
      const response = await logout({
        tokenRenovacion: tokenSession?.tokenRenovation
      });
      if (response) {
        removeCtxSession();
        window.localStorage.removeItem(SESSION.type);
        forceLogout();
      }
    } catch (error) {}
  };

  /**
   * It should user logged in
   * @param payload
   */
  const handleAuthentication = async (payload) => {
    setIsPending(true);

    try {
      const {gToken, ...data} = payload

      const response = await logIn(data, gToken);
      if (response?.data) {
        const tokenData = await setCookieData(response?.data);
        const ctx = await setCtxSession(tokenData);

        if (ctx) {
          setUser({
            type: 1,
            displayName: tokenData?.displayName
          });
          setIsPending(false);
          navigate("/dashboard", { replace: true });
        }
      }
    } catch (error) {
      console.log(error);
      setIsPending(false);
    }
  };

  const forceLogout = () => {
    setUser(null)
  }

  const value = useMemo(
    () => ({
      user,
      handleAuthentication,
      handleLogout,
      forceLogout,
      isPending
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
