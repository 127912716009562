import React from "react";
import NotFoundIcon from "../../static/not-found.svg";
import Typography from "../../components/common/typography";
import { palette, variants } from "../../theme/partials";

const NotFoundTemplateTable = ({ openCreateTemplateModal, show }) => {
  return (
    <>
      {show && (
        <div className="not-found-table">
          <img src={NotFoundIcon} alt={"not found icon"} loading="lazy" />
          <Typography
            text={"No tienes plantillas"}
            variant={variants.small}
            color={palette.neutral20}
            asTag={"h3"}
          />
          <Typography
            text={"Empieza creando tus plantillas personalizadas"}
            variant={variants.extraSmall}
            color={palette.neutral30}
            asTag={"p"}
          />
          <Typography
            text={"+ Crear plantilla"}
            variant={variants.small}
            color={palette.primary}
            asTag={"a"}
            handleTagClicked={openCreateTemplateModal}
          />
        </div>
      )}
    </>
  );
};

export default NotFoundTemplateTable;
