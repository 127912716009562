import styled from "styled-components";
import { palette } from "../../../theme/partials";

const FormContent = styled.form`
  align-items: center;
  background-color: ${palette.white};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 26px 80px;


  h3 {
    margin-bottom: 15px;
  }

  p {
    margin: 12px 0;
    text-align: center;
    width: 370px;
  }
`;

const HeadingWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  p {
    text-align: center;
    width: 370px;
  }
`;

const FieldWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 20px;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  justify-content: space-between;
  margin: 48px 0 20px 0;
  height: 100px;
`;

const CheckBoxWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  padding: 0 40px;
  width: 100%;
`;

const DescriptionWrapper = styled.div`
  display: flex;

  p {
    line-height: 1.6;
  }
`
const logInFormStyles = {
  FormContent,
  FieldWrapper,
  HeadingWrapper,
  ButtonWrapper,
  CheckBoxWrapper,
  DescriptionWrapper
};

export default logInFormStyles;
