import React from 'react'
import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'

const ScoreStatusComponent = ({
	source: value,
	scoreSize = 'medium',
	isFirst = false,
}) => {
	const getIndicatorColor = (value) => {
		if (value < 550) return 'bullshit-score-low'

		if (value > 549 && value < 600) return 'bullshit-score-basic'

		if (value > 599 && value < 660) return 'bullshit-score-medium'

		if (value > 659 && value < 710) return 'bullshit-score-good'

		if (value > 709) return 'bullshit-score-perfect'
	}

	return (
		<Box
			components={'div'}
			className={'flex-row item-padding justify-center align-center'}
		>
			<Typography variant={getIndicatorColor(value)} className={scoreSize} />
			<Typography
				variant={isFirst ? 'headingIndicator' : 'headingGrayIndicator'}
				className={scoreSize}
			>
				{value}
			</Typography>
		</Box>
	)
}

export default ScoreStatusComponent
