import { axiosPrivate } from "./axiosInstance";
import { environment } from "../constant";

/**
 * It should login
 * @param payload: Object
 * @param token
 */
export const logIn = (payload, token) => {
  return axiosPrivate.post(`${environment.path_prefix.public}/login`, payload,
      {
          headers: {
              ['g-token']: token
          },
      },
  );
};

/**
 * It should logout
 * @param payload: Object
 */
export const logout = (payload) => {
  return axiosPrivate.post(`${environment.path_prefix.public}/logout`, payload);
};

/**
 * It should refresh token
 * @param payload: Object
 */
export const tokenRefresh = (payload) => {
  return axiosPrivate.post(
    `${environment.path_prefix.public}/token/refresh`,
    payload
  );
};
