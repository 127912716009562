import * as React from "react";
import AlertStyles from "./alert.styled";
import { palette, variants } from "../../../theme/partials";
import Typography from "../../common/typography";
import Button from "../../common/button";

const AlertComponent = ({
  text,
  icon,
  title,
  description,
  sub,
  onAlertClickButton,
  onSecondaryClickButton,
  secondBtnText,
}) => {
  return (
    <AlertStyles.Container>
      {icon && <img src={`${icon}`} alt={"icon"} loading="lazy" />}

      {title && (
        <Typography
          text={title}
          variant={variants.secondary}
          color={palette.neutral30}
          asTag={"h4"}
        />
      )}
      {description && (
        <Typography
          text={description}
          variant={variants.normal}
          color={palette.neutral30}
          asTag={"p"}
        />
      )}
      
      {sub && (
        <Typography
          text={sub}
          variant={variants.normal}
          color={palette.neutral30}
          asTag={"p"}
        />
      )}

      {text && (
        <Button
          variant={"contained"}
          text={text}
          handleClickButton={onAlertClickButton}
        />
      )}

      {secondBtnText && (
        <Typography
          text={secondBtnText}
          variant={variants.small}
          color={palette.neutral30}
          asTag={"a"}
          hasDecoration
          handleTagClicked={onSecondaryClickButton}
        />
      )}
    </AlertStyles.Container>
  );
};

export default AlertComponent;
