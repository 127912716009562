import { geoLocate } from "../constant";

export const MONTHS = {
  1: "Ene",
  2: "Feb",
  3: "Mar",
  4: "Abr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Ago",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dic"
};

export const MONTHS_YEARS = {
  "Enero": "01",
  "Febrero": "02",
  "Marzo": "03",
  "Abril": "04",
  "Mayo": "05",
  "Junio": "06",
  "Julio": "07",
  "Agosto": "08",
  "Septiembre": "09",
  "Octubre": "10",
  "Noviembre": "11",
  "Diciembre": "12"
};

/**
 * It should set graphic bar data
 * @param data: Object
 * @param year: String
 */
export const setGraphicBarData = (data) => {
  let graphicBarData = [];

  const years = Object.keys(data);

  years.map((year) => {
    const dataByYear = data[year];
    const monthByYear = Object.keys(dataByYear).reverse();

    monthByYear.map((month) => {
      const dataByMonth = dataByYear[month].filter((res) => {
        return res;
      });

      graphicBarData.push({
        date: `${year}/${MONTHS_YEARS[month]}`,
        "722 a 850": dataByMonth[4].porcentaje,
        "722 a 850 color": "#90909A",
        "664 a 721": dataByMonth[3].porcentaje,
        "664 a 721 color": "#B9C3FF",
        "590 a 663": dataByMonth[2].porcentaje,
        "590 a 663 color": "#8FA8FD",
        "300 a 589": dataByMonth[1].porcentaje,
        "300 a 589 color": "#B9C3FF",
        nohit: dataByMonth[0].porcentaje,
        nohitColor: "#90909A"
      });
    });
  });

  return graphicBarData;
};

/**
 * It should set graphic map data
 * @param data: Object
 */
export const setGraphicMapData = (data) => {
  const dataMap = Object.entries(data).map((it) => {
    const info = it && it[1];
    const filterData = geoLocate.filter((o) => o.id === it[0].toString());
    return {
      percent: info?.porcentaje,
      entity: filterData[0]?.entity,
      users: info?.totalRegistros,
      id: it[0],
      isAvailable: filterData[0]?.entity ? true : false
    };
  });

  return dataMap;
};

/**
 * It should set graphic lineal data
 * @param data: Object
 */
export const setGraphicLinealData = (data, picker) => {
  const { start, end } = picker;
  const dataExtracted = Object.entries(data).map((list) => list[1]);

  const startDateMonth = MONTHS[start[1]];
  const endDateMonth = MONTHS[end[1]];

  let customData = [
    dataExtracted.length === 1
      ? ["Puntaje", `${endDateMonth} - ${start[0]}`]
      : [
        "Puntaje",
        `${startDateMonth} - ${start[0]}`,
        `${endDateMonth} - ${end[0]}`
      ],
    ["355"],
    ["410"],
    ["465"],
    ["520"],
    ["575"],
    ["630"],
    ["685"],
    ["740"],
    ["795"],
    ["850"]
  ];

  dataExtracted.forEach((list) => {
    list.forEach((item, index) => {
      if (!item?.promedio) customData[index + 1].push(item.total);
    });
  });

  return customData;
};

export const getScoreMonthly = (data) => {
  let customData = {
    start: 0,
    end: 0
  };
  const dataExtracted = Object.entries(data).map((list) => list[1]);

  dataExtracted.forEach((list, index) => {
    list.forEach((item) => {
      if (item?.promedio) {
        if (index === 0) {
          customData = {
            ...customData,
            start: index === 0 && item?.promedio
          };
        } else {
          customData = {
            ...customData,
            end: index === 1 && item?.promedio
          };
        }
      }
    });
  });

  return customData;
};

export const userDataGraphicsMapper = (data) => {
  return {
    newUsers: data?.usuariosNuevos,
    recurrentUser: data?.usuariosRecurrentes,
    registeredUsers: data?.usuariosSuscritos
  }
}