import styled from "styled-components";
import { palette } from "../../theme/partials";

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${palette.white};
  justify-content: center;
  padding: 0 20px 20px 20px;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  svg {
    margin-right: 6px;
    padding-left: 0;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0 20px 0;
  width: 100%;

  .search-control {
    width: 260px;

    svg {
        color: ${palette.neutral30};
    }
  }
`;

const TemplateStyle = {
    MainContent,
    Actions,
    Buttons
};

export default TemplateStyle;
