import React, { useState } from "react";
import TemplateStyles from "../createTemplate.styled";
import Button from "@mui/material/Button";
import Typography from "../../common/typography";
import Divider from "@mui/material/Divider";
import { palette, variants } from "../../../theme/partials";
import InputComponents from "../components/InputComponents";
import GraphicLineScenariesComponent from "../components/GraphicLineScenaries";
import CreateScenariesComponent from "../components/CreateScenaries";
import { translation, DATA_STUB } from "../../../constant";
import { addTemplateStage } from "../../../services/platform.service";
import {
  setPayloadScenariesAndComponents,
  getCustomInfoScenariesMap,
} from "../../../helper/templateMappers";
import {
  removeScenarieByIdCreate,
  updateScenarieByIdx,
} from "../../../utils/filterTemplate";
import { NAMES_REGEX, TITLE_REGEX } from "../../../helper/regex";

const Step2 = ({
  templateInfo,
  onNextStepHandler,
  handleCloseCreateTemplate,
  refreshListOfTemplates,
}) => {
  const { staticText } = translation;
  const { name, templateId } = templateInfo;
  const [data, setData] = useState({
    scenaries: DATA_STUB.scenaries.case1,
    fieldsComponent: DATA_STUB.inputFieldsComponents,
    loadCase: 1,
    errorMessage: null,
  });

  /**
   * It should set input components
   * @param e: Event
   * @param name: String
   */
  const setInputFieldComponents = (e, name) => {
    const value = e.target.value
    const cleanValue = value.replace(TITLE_REGEX, '')

    setData({
      ...data,
      fieldsComponent: {
        ...data?.fieldsComponent,
        [name]: cleanValue,
      },
    });
  };

  /**
   * It should add new scenaries
   * @param null
   */
  const addNewScenaries = () => {
    const { loadCase } = data;

    if (loadCase === 1) {
      setData({
        ...data,
        scenaries: [...DATA_STUB.scenaries.case2],
        loadCase: 2,
      });
    }

    if (loadCase === 2) {
      setData({
        ...data,
        scenaries: [...DATA_STUB.scenaries.case3],
        loadCase: 3,
      });
    }

    if (loadCase === 3) {
      setData({
        ...data,
        scenaries: [...DATA_STUB.scenaries.case1],
        loadCase: 1,
      });
    }
  };

  /**
   * It should remove scenaries on list
   * @param idx: Number
   */
  const removeScenarie = (idx) => {
    const { tempArr, load } = removeScenarieByIdCreate(idx, data?.scenaries);
    setData({
      ...data,
      loadCase: load,
      scenaries: [...tempArr],
    });
  };

  /**
   * It should update scenaries on list
   * @param idx: Number
   * @param scenarie: Object
   */
  const updateScenarie = (idx, scenarie) => {
    const scenarieSelected = { ...scenarie, idx };
    const { errorMessage, tempArr } = updateScenarieByIdx(
      scenarieSelected,
      data?.scenaries
    );

    if (errorMessage) {
      setData({
        ...data,
        errorMessage,
      });
      return;
    }

    setData({
      ...data,
      scenaries: [...tempArr],
      errorMessage: null,
    });
  };

  /**
   * It should save scenaries and components for step 3 and go the next step
   * @param payload - Object
   */
  const setPayloadScenariesAndComponent = () => {
    const dataInfo = {
      templateId,
      scenaries: data?.scenaries,
      fieldsComponent: data?.fieldsComponent,
    };
    const payload = setPayloadScenariesAndComponents(dataInfo);
    saveScenariesAndComponents(payload);
  };

  /**
   * It should save scenaries and components for step 3 and go the next step
   * @param payload - Object
   */
  const saveScenariesAndComponents = async (payload) => {
    try {
      const { data } = await addTemplateStage(payload);

      if (data?.escenarios || data?.idPlantilla) {
        const customScenaries = getCustomInfoScenariesMap(data?.escenarios);

        onNextStepHandler({
          nextStep: 4,
          customScenaries,
          fieldsComponent: payload?.componentes,
          errorMessage: null,
        });
      }
      refreshListOfTemplates();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {data && data?.scenaries && (
        <>
          <GraphicLineScenariesComponent sourceData={data?.scenaries} />

          <CreateScenariesComponent
            sourdeData={data?.scenaries}
            addNewScenaries={addNewScenaries}
            errorMessage={data?.errorMessage}
            updateScenarie={updateScenarie}
            removeScenarie={removeScenarie}
          />
        </>
      )}

      <Divider />

      {data && data?.fieldsComponent && (
        <InputComponents
          templateName={name}
          sourceData={data?.fieldsComponent}
          setInputFieldComponents={setInputFieldComponents}
        />
      )}

      <TemplateStyles.ButtonWrapper>
        <Button
          variant={"contained"}
          disabled={
            (!data?.fieldsComponent?.score &&
              !data?.fieldsComponent?.points &&
              !data?.fieldsComponent?.history &&
              !data?.fieldsComponent?.notify) ||
            data?.scenaries.length < 1
          }
          type="button"
          onClick={setPayloadScenariesAndComponent}
        >
          {staticText.btnSaveAndContinue}
        </Button>

        <span style={{ marginTop: "30px" }} onClick={() => handleCloseCreateTemplate(3)}>
          <Typography
            text={staticText.btnExit}
            variant={variants.small}
            color={palette.neutral20}
            asTag={"a"}
            hasDecoration
          />
        </span>
      </TemplateStyles.ButtonWrapper>
    </>
  );
};

export default Step2;
