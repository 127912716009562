import React from 'react'
import { Grid } from '@mui/material'

const GridComponent = ({
	totalColumns = 1,
	totalGap = 1,
	totalFraction = 1,
	children,
}) => {
	return (
		<Grid
			display={'grid'}
			gridTemplateColumns={`repeat(${totalColumns}, ${totalFraction}fr)`}
			gap={totalGap}
			padding={'0 8px'}
		>
			{children}
		</Grid>
	)
}

export default GridComponent
