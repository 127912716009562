import React from "react";
import { palette } from "../../../theme/partials";

export default function LightIcon({
  color = palette.neutral100,
  width = 20,
  height = 20
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.20833 17.5002H8.54167C8.54167 18.4168 7.79167 19.1668 6.875 19.1668C5.95833 19.1668 5.20833 18.4168 5.20833 17.5002ZM3.54167 16.6668H10.2083V15.0002H3.54167V16.6668ZM13.125 8.75016C13.125 11.9335 10.9083 13.6335 9.98333 14.1668H3.76667C2.84167 13.6335 0.625 11.9335 0.625 8.75016C0.625 5.30016 3.425 2.50016 6.875 2.50016C10.325 2.50016 13.125 5.30016 13.125 8.75016ZM17.1833 6.97516L16.0417 7.50016L17.1833 8.02516L17.7083 9.16683L18.2333 8.02516L19.375 7.50016L18.2333 6.97516L17.7083 5.8335L17.1833 6.97516ZM15.2083 5.8335L15.9917 4.11683L17.7083 3.3335L15.9917 2.55016L15.2083 0.833496L14.425 2.55016L12.7083 3.3335L14.425 4.11683L15.2083 5.8335Z"
        fill={color}
      />
    </svg>
  );
}
