import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import AnswerIcon from "../../icons/answer";
import CustomTypography from "../typography";
import { palette, variants } from "../../../theme/partials";

export default function TooltipComponent({
  hasIcon = false,
  btnText = "Tooltip",
  title = "",
  description = "",
  btnTextVariant = variants.normal,
  btnTextColor = palette.black,
  btnTextTag = "h6"
}) {
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9"
    }
  }));

  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          <React.Fragment>
            {title && <Typography color="inherit">{title}</Typography>}
          </React.Fragment>
          <React.Fragment>
            {description && <React.Fragment>{description}</React.Fragment>}
          </React.Fragment>
        </React.Fragment>
      }
    >
      <span className="info-icon">
        {hasIcon ? (
          <AnswerIcon height={18} width={18} />
        ) : (
          <CustomTypography
            text={btnText}
            variant={btnTextVariant}
            color={btnTextColor}
            asTag={btnTextTag}
          />
        )}
      </span>
    </HtmlTooltip>
  );
}
