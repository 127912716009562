import React from 'react'
import { Box, Skeleton, Typography } from '@mui/material'
import BasicCardComponent from '../Cards/BasicCard'

const SkeletonLoaderComponent = () => {
	return (
		<>
			<Box component={'div'} className="section">
				<BasicCardComponent
					cardHeading={<Skeleton variant="text" height={40} />}
				>
					<Box component={'div'} className="flex-column">
						<Box
							component={'div'}
							className="flex-column justify-center padding-content"
						>
							<Box
								components={'div'}
								className={'flex-row item-padding justify-center align-center'}
							>
								<Typography variant="skeleton-bull">
									<Skeleton variant="circular" width={16} height={16} />
								</Typography>

								<Typography variant="headingIndicator">
									<Skeleton variant="text" width={80} height={50} />
								</Typography>
							</Box>

							<Typography variant="indicatorText">
								<Skeleton variant="text" height={40} />
							</Typography>
						</Box>
						<br />
						<Box
							component={'div'}
							className="flex-row space-between padding-content inicator-chart"
						>
							<Typography variant="barGraphicIndicator">
								<Skeleton variant="text" width={80} height={20} />
							</Typography>
							<Typography variant="barGraphicIndicator">
								<Skeleton variant="text" width={80} height={20} />
							</Typography>
						</Box>
						<Box
							component={'div'}
							className="flex-column flex-start padding-content bg-main no-bottom"
						>
							<Box
								component={'div'}
								className="flex-row flex-start align-center padding-content indicator-text full-width mt-10"
							>
								<Box component={'div'}>
									<Skeleton variant="text" width={10} height={20} />
								</Box>
								<Typography variant="lastedUpdated">
									<Skeleton variant="text" width={270} height={20} />
								</Typography>
							</Box>
							<Box
								component={'div'}
								className="flex-row flex-start align-center padding-content indicator-text full-width"
							>
								<Box component={'div'}>
									<Skeleton variant="text" width={10} height={20} />
								</Box>
								<Typography variant="lastedUpdated">
									<Skeleton variant="text" width={270} height={20} />
								</Typography>
							</Box>
						</Box>
					</Box>
				</BasicCardComponent>
			</Box>

			<Box component={'div'} className="section">
				<BasicCardComponent
					cardHeading={<Skeleton variant="text" height={40} />}
				>
					<Box component={'div'} className="flex-column">
						<Box
							component={'div'}
							className="flex-column justify-center padding-content"
						>
							<Typography variant="indicatorText">
								<Skeleton variant="text" height={30} />
							</Typography>

							<Typography variant="indicatorText">
								<Skeleton variant="text" height={20} />
							</Typography>

							<Typography variant="indicatorText">
								<Skeleton variant="text" height={20} />
							</Typography>

							<Typography variant="indicatorText">
								<Skeleton variant="text" height={20} />
							</Typography>

							<Typography variant="indicatorText">
								<Skeleton variant="text" height={20} />
							</Typography>

							<Typography variant="indicatorText">
								<Skeleton variant="text" height={17} />
							</Typography>

							<Typography variant="indicatorText">
								<Skeleton variant="text" height={16} />
							</Typography>

							<Typography variant="indicatorText">
								<Skeleton variant="text" height={16} />
							</Typography>
						</Box>
						<Box component={'div'} className="no-bottom">
							<Box
								component={'div'}
								className="padding-content full-width mt-10"
							>
								<Box
									component={'div'}
									className="flex-row flex-start justify-center padding-content full-width mt-10"
								>
									<Skeleton variant="circular" width={14} height={14} />
									<Skeleton variant="circular" width={14} height={14} />
									<Skeleton variant="circular" width={14} height={14} />
									<Skeleton variant="circular" width={14} height={14} />
								</Box>
							</Box>
						</Box>
					</Box>
				</BasicCardComponent>
			</Box>

			<Box component={'div'} className="section">
				<BasicCardComponent
					cardHeading={<Skeleton variant="text" height={40} />}
				>
					<Box component={'div'} className="flex-column">
						<Box
							component={'div'}
							className="flex-row space-evenly padding-content skeleton-header"
							sx={{ padding: '0 0 10px 0 !important' }}
						>
							<Skeleton variant="text" width={20} height={30} />
							<Typography variant="currentYearGraphic">
								<Skeleton variant="text" width={100} height={30} />
							</Typography>
							<Skeleton variant="text" width={20} height={30} />
						</Box>
						<Box
							component={'div'}
							className="flex-column justify-center padding-content skeleton"
						>
							<Typography variant="indicatorText">
								<Skeleton variant="rectangular" height={200} />
							</Typography>
						</Box>
					</Box>
				</BasicCardComponent>
			</Box>

			<Box component={'div'} className="section">
				<BasicCardComponent
					cardHeading={<Skeleton variant="text" height={40} />}
				>
					<Box component={'div'} className="flex-column">
						<Box
							component={'div'}
							className="flex-column justify-center padding-content"
						>
							<Typography variant="indicatorText">
								<Skeleton variant="text" height={20} />
							</Typography>

							<Typography variant="indicatorText">
								<Skeleton variant="text" height={12} />
							</Typography>

							<Typography variant="indicatorText">
								<Skeleton variant="text" height={12} />
							</Typography>
						</Box>

						<Box
							component={'div'}
							className="flex-column justify-center padding-content"
						>
							<Typography variant="indicatorText">
								<Skeleton variant="text" height={56} />
								<Skeleton variant="text" height={56} />
							</Typography>
						</Box>

						<Box
							component={'div'}
							className="flex-column flex-start padding-content bg-main no-bottom"
						>
							<Box
								component={'div'}
								className="flex-row flex-start align-center padding-content indicator-text full-width mt-10"
							>
								<Skeleton variant="text" width={'100%'} height={56} />
							</Box>
						</Box>
					</Box>
				</BasicCardComponent>
			</Box>
		</>
	)
}

export default SkeletonLoaderComponent
