import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes";
import { AuthProvider } from "./hooks/useAuth";
import GamaAdminThemeProvider from "./theme/theme";
import reportWebVitals from "./reportWebVitals";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
      <BrowserRouter>
        <AuthProvider>
            <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY ?? ''}
                scriptProps={{
                    async: false,
                    defer: false,
                    appendTo: 'head',
                    nonce: undefined,
                }}
            >
              <GamaAdminThemeProvider>
                <AppRoutes />
              </GamaAdminThemeProvider>
            </GoogleReCaptchaProvider>
        </AuthProvider>
      </BrowserRouter>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
