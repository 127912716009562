import { Colors, Fonts, GraphicColors } from "./themeConst";

const cardRadius = 12;

/* THEME */
export const generateCustomMuiTheme = ({ primaryColor, secondaryColor }) => {
  const paletteFontColor = primaryColor ?? Colors.primary;
  const paletteBackgroundColor = secondaryColor ?? Colors.secondary;

  const muiTheme = {
    palette: {
      primary: {
        main: paletteFontColor,
        light: Colors.primaryLight,
        graphicLow: GraphicColors.low,
        iconsColor: Colors.iconsColorPrimary
      },
      secondary: {
        main: Colors.secondary
      },
      success: {
        main: Colors.success
      },
      info: {
        main: Colors.info
      },
      warning: {
        main: Colors.warning
      },
      error: {
        main: Colors.error
      },
      text: {
        primary: Colors.text
      },
      background: {
        default: Colors.white,
        paper: Colors.white
      },
      uncheckedInput: {
        main: Colors.uncheckedInput
      }
    },
    typography: {
      fontFamily: Fonts.primary
    },
    components: {
      /* GENERAL */
      MuiCssBaseline: {
        styleOverrides: {
          ".MuiBox-root": {
            ".preview": {
              backgroundColor: "#fff !important",
              borderRadius: "0 !important"
            },
            ".inherit-bg": {
              backgroundColor: "transparent !important",
              borderRadius: "0 !important",
              span: {
                fontSize: "10px !important"
              }
            },
            ".no-bg": {
              backgroundColor: "#f7f8ff !important",
              borderRadius: "0 !important"
            },
            ".rec.rec-arrow": {
              display: "none"
            },
            ".rec-dot": {
              backgroundColor: Colors.dotColorBg,
              opacity: 0.44,
              boxShadow: "none !important"
            },
            ".rec-dot_active": {
              backgroundColor: Colors.dotColor,
              boxShadow: "none !important"
            },
            ".rec-pagination": {
              marginTop: "-25px"
            },
            "&.main-layout": {
              marginLeft: "16px",
              marginRight: "16px"
            },
            ".intro": {
              marginTop: "60px",
              textAlign: "center"
            },
            ".bg-white": {
              background: `${Colors.white} !important`
            },
            ".text-center": {
              textAlign: "center",
              marginTop: "20px"
            },
            "&.wrapper-gradient": {
              marginLeft: "16px",
              marginRight: "16px"
            },
            "&.main-layout-gradient": {
              height: "100vh",
              overflowY: "hidden",
              background:
                "linear-gradient(rgba(255, 255, 255, 1) 0%, #DDE1FF 100%)"
            },
            ".heading": {
              height: "100px",
              marginTop: "30px",
              marginBottom: "20px"
            },
            ".m-10": {
              margin: "10px 0"
            },
            ".mr-10": {
              marginRight: "10px"
            },
            ".no-padding": {
              padding: "0 !important"
            },
            ".wrapper-circle": {
              width: "297px",
              margin: "0 auto"
            },
            ".full-width": {
              width: "100%"
            },
            "&.flex-row": {
              display: "flex",
              flexDirection: "row"
            },
            "&.flex-column": {
              display: "flex",
              flexDirection: "column"
            },
            "&.flex-start": {
              alignItems: "flex-start"
            },
            "&.align-center": {
              alignItems: "center"
            },
            "&.flex-end": {
              alignItems: "flex-end"
            },
            "&.space-evenly": {
              alignItems: "center",
              justifyContent: "space-evenly"
            },
            "&.space-between": {
              alignItems: "center",
              justifyContent: "space-between"
            },
            "&.justify-center": {
              justifyContent: "center",
              textAlign: "center"
            },
            "&.justify-start": {
              alignItems: "center",
              justifyContent: "start"
            },
            "&.text-start": {
              textAlign: "left"
            },
            "&.section": {
              marginTop: "20px",
              marginBottom: "20px"
            },
            "&.expanded": {
              textAlign: "center",
              width: "100%",
              maxWidth: "100%"
            },
            "&.center": {
              textAlign: "center",
              width: "100%",
              maxWidth: "100%",
              paddingTop: "0 !important",
              img: {
                marginLeft: "6px"
              }
            },
            "&.padding-content": {
              padding: "2px 16px !important",
              marginBottom: "8px !important",
              "&.inicator-chart": {
                padding: "0 20px !important",
                marginBottom: 20
              },
              "&.skeleton": {
                padding: "0 20px !important",
                paddingBottom: "16px !important"
              },
              "&.skeleton-header": {
                padding: "10px 20px !important"
              },
              "&.indicator-category": {
                marginTop: 5,
                textAlign: "center"
              },
              "&.items-content": {
                marginBottom: 5
              },
              "&.no-bottom": {
                marginBottom: "0 !important",
                paddingBottom: "6px !important"
              },
              "&.indicator-text": {
                padding: "0 10px !important",
                marginBottom: 10,
                img: {
                  width: "14px",
                  marginRight: "8px"
                },
                span: {
                  marginTop: 2
                }
              },
              "&.pd-more": {
                padding: "10px 16px !important",
                img: {
                  width: "34px",
                  margin: "0 auto"
                }
              },
              "&.generic": {
                padding: "10px 16px 0 16px !important"
              }
            },
            "&.mt-10": {
              marginTop: "10px"
            },
            "&.category-item": {
              width: "33.33333%"
            },
            "&.category-item-label": {
              width: "auto"
            },
            "&.card-info": {
              backgroundColor: Colors.cardHeaderBackground,
              borderRadius: 8,
              padding: "6px 10px 14px 10px !important"
            },
            "&.years": {
              padding: "12px !important"
            },
            "&.bg-main": {
              backgroundColor: Colors.cardHeaderBackground
            },
            "&.wrapper-nivo&.fico-score": {
              height: "50px !important",
              position: "relative",
              "& > div": {
                width: "90% !important",
                margin: "0 auto",
              },
              "> .bar-status": {
                position: "absolute",
                width: "5px !important",
                height: "20px",
                top: "-6px",
                backgroundColor: "#65C24E",
                borderRadius: "0 0 8px 8px",
                right: "80px",
              }
            },
            "&.wrapper-nivo&.bar-chart": {
              height: "300px !important"
            },
            "&.wrapper-nivo&.pie-chart": {
              height: "200px !important",
              position: "relative",
              zIndex: 2,
              "& > div": {
                height: "240px !important"
              },
              "& >div.scoret": {
                position: "absolute",
                top: "90px",
                zIndex: 3,
                color: Colors.darkText,
                width: "100%"
              },
              "& > span": {
                textAlign: "center",
                color: Colors.darkText
              }
            },
            "&.display-avatar": {
              backgroundColor: Colors.cardBackground,
              padding: "10px",
              borderRadius: "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              "&.extra-large": {
                height: "100px",
                width: "100px",
                img: {
                  height: "60px",
                  width: "70px"
                }
              }
            },
            "&.item-padding": {
              padding: "4px 0 !important",

              "& > img": {
                marginRight: "6px"
              }
            },
            "&.footer-tab": {
              position: "absolute",
              width: "100%",
              bottom: 0,
              left: 0,
              padding: "20px"
            },
            "&.green-bg": {
              backgroundColor: "#F4FFF1 !important",
              padding: "12px !important",
              borderRadius: "0 !important",
              "& > img": {
                marginLeft: "6px",
                marginTop: "2px"
              }
            },
            "&.item-menu-auth": {
              borderBottom: "1px solid blue",
              minHeight: "70px"
            },
            "&.item-menu-auth-single": {
              minHeight: "70px"
            },
            "&.heading-auth": {
              padding: "80px 15px 20px 15px !important",
              textAlign: "center"
            },
            "&.heading-onboarding": {
              padding: "40px 15px 40px 15px !important",
              textAlign: "center"
            },
            "&.item-text": {
              padding: "10px !important",
              width: "80%"
            },
            "&.item-icon": {
              padding: "10px !important",
              width: "10%"
            },
            ".slick-slide": {
              paddingBottom: "48px !important"
            },
            ".slick-dots": {
              bottom: "16px",
              li: {
                width: "8px",
                "button:before": {
                  fontSize: "9px",
                  color: Colors.dotColorBg
                }
              },
              ".slick-active button:before": {
                color: Colors.dotColor
              }
            },
            ".slick-arrow": {
              display: "none"
            },
            "&.error-wrapper": {
              backgroundColor: "#F3F0F5",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",

              "& > img": {
                width: "50px"
              },
              ".error-message": {
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "20px",
                textAlign: "center",
                letterSpacing: "0",
                color: Colors.AuthHeading
              }
            },
            "&.reload-wrapper": {
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: "80px",

              "& > img": {
                width: "100%",
                margin: "20px 0",
                padding: "20px 0"
              },
              ".reload-heading": {
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "24px",
                textAlign: "center",
                letterSpacing: "0.1px",
                color: Colors.AuthHeading,
                margin: "6px 0"
              },
              ".reload-message": {
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "20px",
                margin: "6px 0",
                textAlign: "center",
                letterSpacing: "0.25px",
                color: Colors.reloadText
              }
            }
          },
          ".Mui-tab-content": {
            ".MuiBox-root": {
              padding: "12px 0 0 0"
            }
          },
          "button.Mui-tab-header": {
            fontSize: "12px",
            fontFamily: Fonts.primary,
            fontStyle: "normal",
            fontWeight: "500",
            minHeight: "40px !important",
            lineHeight: "20px",
            color: Colors.AuthHeading,
            textTransform: "inherit",
            borderBottom: `none`,
            margin: "0 16px",
            padding: "14px 0px",

            "&.Mui-selected": {
              color: paletteFontColor,
              fontWeight: "500",
              fontSize: "12px !important",
              lineHeight: "20px",
              borderBottom: `3px solid ${paletteFontColor}`
            }
          },
          ".Mui-tab-content .bullshit-icon": {
            padding: "0 10px 10px 0"
          },
          ".bullet": {
            padding: "0 10px 10px 0"
          },
          ".bullet-generic": {
            padding: "0 !important",
            margin: "0 !important"
          }
        }
      },
      /* CARD */
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: cardRadius,
            background: Colors.cardBackground,
            boxShadow: "none"
          }
        }
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            background: Colors.cardHeaderBackground
          }
        }
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            background: Colors.cardBg,
            padding: "0 !important",
            border: "1px solid #F2F2FE"
          }
        }
      },

      MuiDivider: {
        styleOverrides: {
          root: {
            "&::before": {
              borderTop: `thin solid ${Colors.lineSeparator}`
            },
            "&::after": {
              borderTop: `thin solid ${Colors.lineSeparator}`
            }
          }
        }
      },
      MuiGrid: {
        styleOverrides: {
          root: {
            ul: {
              padding: "10px 16px"
            }
          }
        }
      },
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            height: "64px !important",
            width: "64px !important",
            color: "#79DB90"
          }
        }
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            minHeight: "40px",
            padding: "0 16px",
            background: Colors.tabHeaderBackground,
            MuiButtonBase: {
              styleOverrides: {
                root: {
                  fontSize: "10px",
                  "&.MuiTabs-indicator": {
                    borderBottom: `1px solid ${paletteFontColor}`
                  }
                }
              }
            },
            ".MuiTabs-indicator": {
              backgroundColor: `${paletteFontColor} !important`
            },
            ".MuiTabs-flexContainer": {}
          }
        }
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            padding: "0",
            paddingLeft: "4px"
          }
        }
      },
      /* TYPOGRAPHY */
      MuiTypography: {
        styleOverrides: {
          root: {
            fontFamily: Fonts.primary,
            "&.MuiCardHeader-title": {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "24px",
              color: Colors.darkText
            },
            ".indicatorTextPreview": {
              span: {
                fontSize: "13px !important"
              }
            }
          }
        },
        variants: [
          {
            props: { variant: "heading" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "18px",
              lineHeight: "18px",
              color: Colors.darkText
            }
          },
          {
            props: { variant: "caption" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "15px",
              lineHeight: "24px",
              color: `${Colors.greenBar} !important`
            }
          },
          {
            props: { variant: "normal" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "18px",
              color: Colors.darkText
            }
          },
          {
            props: { variant: "normal" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "18px",
              color: Colors.darkText
            }
          },
          {
            props: { variant: "textAds" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "24px",
              fontSize: "16px",
              color: Colors.darkText
            }
          },
          {
            props: { variant: "lastedUpdated" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "14px",
              color: Colors.darkText,
              marginTop: "6px",
              padding: "0 6px"
            }
          },
          {
            props: { variant: "headingIndicator" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "24px",
              fontSize: "14px",
              color: Colors.darkText,
              "&.small": {
                fontSize: "12px"
              },
              "&.medium": {
                fontSize: "16px"
              },
              "&.large": {
                fontSize: "22px"
              },
              "&.extra-large": {
                fontSize: "28px",
                lineHeight: "36px"
              },
              "&.padding": {
                padding: "0 16px"
              }
            }
          },
          {
            props: { variant: "headingGrayIndicator" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "24px",
              fontSize: "16px",
              color: Colors.scoreListText,
              "&.small": {
                fontSize: "12px"
              },
              "&.medium": {
                fontSize: "16px"
              },
              "&.large": {
                fontSize: "22px"
              },
              "&.extra-large": {
                fontSize: "28px"
              },
              "&.padding": {
                padding: "0 16px"
              }
            }
          },
          {
            props: { variant: "barGraphicIndicator" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "16px",
              color: Colors.indicator,
              fontSize: "11px"
            }
          },
          {
            props: { variant: "indicatorItem" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "14px",
              color: Colors.darkText
            }
          },
          {
            props: { variant: "submittedHeader" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "15px",
              lineHeight: "24px",
              color: Colors.darkText,
              margin: "14px 0 !important"
            }
          },
          {
            props: { variant: "calendarText" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "20px",
              color: Colors.darkText,
              margin: "4px 0 !important"
            }
          },
          {
            props: { variant: "submittedInfo" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "20px",
              color: Colors.indicatorText,
              padding: "0 20px"
            }
          },
          {
            props: { variant: "errorMessage" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "20px",
              color: Colors.error
            }
          },
          {
            props: { variant: "infoCard" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "120x",
              lineHeight: "16px",
              padding: "0 12px",
              color: Colors.indicatorText
            }
          },
          {
            props: { variant: "greenText" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "20px",
              color: "#70AD47"
            }
          },
          {
            props: { variant: "yellowText" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "20px",
              color: "#FFC000"
            }
          },
          {
            props: { variant: "redText" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "20px",
              color: "#FF0000"
            }
          },
          {
            props: { variant: "greenYearSm" },
            style: {
              fontFamily: `${Fonts.secondary}`,
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "12px",
              lineHeight: "16px",
              color: "#70AD47",
              paddingBottom: "2px"
            }
          },
          {
            props: { variant: "greenYearXl" },
            style: {
              fontFamily: `${Fonts.secondary}`,
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "28px",
              lineHeight: "30px",
              color: "#70AD47"
            }
          },
          {
            props: { variant: "authHeading" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "28px",
              lineHeight: "36px",
              color: Colors.darkText,
              margin: "10px 0"
            }
          },
          {
            props: { variant: "authSubHeading" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "20px",
              lineHeight: "28px",
              color: Colors.indicatorText,
              margin: "10px 0",
              padding: "0 16px"
            }
          },
          {
            props: { variant: "authText" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "20px",
              color: Colors.indicatorText
            }
          },
          {
            props: { variant: "linkText" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "20px",
              marginLeft: "4px",
              color: paletteFontColor
            }
          },
          {
            props: { variant: "timeText" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "20px",
              color: Colors.indicatorText,
              padding: "0 60px",
              marginBottom: "50px",
              marginTop: "10px"
            }
          },
          {
            props: { variant: "onboardingText" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "20px",
              color: Colors.indicatorText,
              padding: "0 20px",
              marginBottom: "50px",
              marginTop: "10px"
            }
          },
          {
            props: { variant: "authLabelBold" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "16px",
              lineHeight: "24px",
              color: Colors.blackText,
              padding: "0 8px"
            }
          },
          {
            props: { variant: "indicatorText" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "20px",
              color: Colors.darkText
            }
          },
          {
            props: { variant: "pieTextCategory" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "11px",
              lineHeight: "16px",
              color: Colors.indicatorText
            }
          },
          {
            props: { variant: "categoryText" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "20px",
              color: Colors.blackText
            }
          },
          {
            props: { variant: "oldHeadingText" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "11px",
              lineHeight: "16px",
              color: Colors.blackText,
              textAlign: "center"
            }
          },
          {
            props: { variant: "separatorText" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "24px",
              color: Colors.blackText
            }
          },
          {
            props: { variant: "currentYearGraphic" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "22px",
              lineHeight: "28px",
              color: Colors.darkText
            }
          },
          {
            props: { variant: "grayNormal" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "14px",
              color: Colors.text
            }
          },
          {
            props: { variant: "bullshit-score-low" },
            style: {
              backgroundColor: "#FF452E",
              marginRight: "6px",
              borderRadius: "40px",
              height: "8px",
              width: "8px"
            }
          },
          {
            props: { variant: "bullshit-score-basic" },
            style: {
              backgroundColor: "#FF9A00",
              marginRight: "6px",
              borderRadius: "40px",
              height: "8px",
              width: "8px"
            }
          },
          {
            props: { variant: "cat1" },
            style: {
              backgroundColor: "hsl(96, 42%, 48%)",
              marginRight: "6px",
              borderRadius: "40px",
              height: "8px",
              width: "8px"
            }
          },
          {
            props: { variant: "cat2" },
            style: {
              backgroundColor: "hsl(45, 100%, 50%)",
              marginRight: "6px",
              borderRadius: "40px",
              height: "8px",
              width: "8px"
            }
          },
          {
            props: { variant: "cat3" },
            style: {
              backgroundColor: "hsl(0, 100%, 50%)",
              marginRight: "6px",
              borderRadius: "40px",
              height: "8px",
              width: "8px"
            }
          },
          {
            props: { variant: "bullshit-score-medium" },
            style: {
              backgroundColor: "#FFCC00",
              marginRight: "6px",
              borderRadius: "40px",
              height: "8px",
              width: "8px"
            }
          },
          {
            props: { variant: "bullshit-score-good" },
            style: {
              backgroundColor: "#85E756",
              marginRight: "6px",
              borderRadius: "40px",
              height: "8px",
              width: "8px"
            }
          },
          {
            props: { variant: "indicatorBullshit" },
            style: {
              marginRight: "10px"
            }
          },
          {
            props: { variant: "bullshit-score-perfect" },
            style: {
              backgroundColor: "#01B11D",
              marginRight: "6px",
              borderRadius: "40px",
              height: "8px",
              width: "8px"
            }
          },
          {
            props: { variant: "skeleton-bull" },
            style: {
              marginRight: "6px",
              borderRadius: "40px"
            }
          },
          {
            props: { variant: "grayBold" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "14px",
              lineHeight: "14px",
              color: Colors.text
            }
          },
          {
            props: { variant: "targetText" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "11px",
              lineHeight: "16px",
              color: Colors.indicatorText,
              paddingLeft: "8px"
            }
          },
          {
            props: { variant: "scoreList" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "20px",
              color: Colors.scoreListText,
              paddingLeft: "8px"
            }
          },
          {
            props: { variant: "scoreList-generic" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "20px",
              color: Colors.scoreListText,
              paddingLeft: "8px",
              marginTop: "2px"
            }
          },
          {
            props: { variant: "activeAccounts" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "11px",
              lineHeight: "16px",
              color: Colors.indicatorText,
              marginBottom: "8px"
            }
          },
          {
            props: { variant: "infoText" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "16px",
              color: Colors.AuthHeading,
              paddingLeft: "8px",
              textAlign: "left"
            }
          },
          {
            props: { variant: "scoreMesage" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "13px",
              lineHeight: "20px",
              color: Colors.scoreListMessage,
              paddingLeft: "8px"
            }
          },
          {
            props: { variant: "scoreListHead" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "13px",
              lineHeight: "20px",
              color: Colors.darkText,
              paddingLeft: "8px"
            }
          },
          {
            props: { variant: "darkBold" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "700",
              fontSize: "14px",
              lineHeight: "130%",
              color: Colors.darkText
            }
          },
          {
            props: { variant: "darkLightSm" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "120%",
              color: Colors.darkText
            }
          }
        ]
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            marginTop: "12px",
            marginBottom: "12px",
            width: "100%"
          }
        }
      },
      MuiButton: {
        variants: [
          {
            props: { variant: "link" },
            style: {
              fontFamily: Fonts.primary,
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "20px",
              color: paletteFontColor,
              textTransform: "inherit !important",
              marginBottom: "12px"
            }
          }
        ],
        styleOverrides: {
          root: {},
          contained: {
            backgroundColor: paletteFontColor,
            color: Colors.white,
            textAlign: "center",
            padding: "10px 16px",
            width: "100%",
            borderRadius: 24,
            letterSpacing: "2px",
            fontSize: "12px",
            lineHeight: "20px",
            fontWeight: "500",
            fontStyle: "normal",
            fontFamily: Fonts.primary,
            marginTop: "10px",
            marginBottom: "10px",
            textTransform: "inherit !important",
            fontSize: "10px !important",
            "&:hover": {
              backgroundColor: paletteFontColor,
              color: Colors.white,
              opacity: 0.9
            }
          },
          containedOnboard: {
            backgroundColor: paletteFontColor,
            color: Colors.white,
            textAlign: "center",
            padding: "8px 16px",
            width: "210px",
            borderRadius: 24,
            letterSpacing: "2px",
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: "500",
            fontStyle: "normal",
            fontFamily: Fonts.primary,
            marginTop: "10px",
            marginBottom: "10px",
            textTransform: "inherit !important",
            "&:hover": {
              backgroundColor: paletteFontColor,
              color: Colors.white,
              opacity: 0.9
            }
          },
          outlined: {
            backgroundColor: "none",
            color: paletteFontColor,
            textAlign: "center",
            padding: "8px 16px",
            width: "100%",
            borderRadius: 24,
            letterSpacing: "2px",
            fontSize: "14px",
            lineHeight: "20px",
            border: "none",
            fontWeight: "500",
            fontStyle: "normal",
            fontFamily: Fonts.primary,
            marginTop: "10px",
            marginBottom: "10px",
            textTransform: "inherit !important",
            "&:hover": {
              backgroundColor: "none",
              color: paletteFontColor,
              opacity: 0.9,
              border: "none"
            }
          },
          outlinedOnboard: {
            backgroundColor: "none",
            color: paletteFontColor,
            textAlign: "center",
            padding: "8px 16px",
            width: "210px",
            borderRadius: 24,
            letterSpacing: "2px",
            fontSize: "14px",
            lineHeight: "20px",
            border: "none",
            fontWeight: "500",
            fontStyle: "normal",
            fontFamily: Fonts.primary,
            marginTop: "10px",
            marginBottom: "10px",
            textTransform: "inherit !important",
            "&:hover": {
              backgroundColor: "none",
              color: paletteFontColor,
              opacity: 0.9,
              border: "none"
            }
          }
        }
      }
    }
  };
  return muiTheme;
};
