import React, { useState, useEffect } from "react";
import TemplateStyles from "../createTemplate.styled";
import Typography from "../../common/typography";
import { palette, variants } from "../../../theme/partials";
import ChevronRight from "../../../static/chevron-right.svg";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FieldTextLimit from "../../../helper/field";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Accordion from "@mui/material/Accordion";
import FormGroup from "@mui/material/FormGroup";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import DragAndDrop from "../../dragAndDrop/drapAndDrop";
import {TITLE_REGEX} from "../../../helper/regex";

const EditExpandedTemplate = ({
  customEdit,
  handleComponentsModal,
  updateDataScenaries,
  templateName,
}) => {
  const [dataInfo, setDataInfo] = useState(null);
  const [expandedAccordion, setExpandedAccordion] = useState(false);

  /**
   * It should expand or collapse panel
   * @param event: Event
   * @param isExpanded: Boolean
   */
  const handleChangeStep3 = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : false);
  };

  /**
   * It should update checkbox list with checked
   * @param e: Event
   */
  const handleCheckboxNotification = (e, item) => {
    let checkboxList = dataInfo?.notifications;

    for (let i = 0; i < checkboxList.length; i++) {
      if (checkboxList[i].enum === item.enum) {
        checkboxList[i].checked = e.target.checked;
      }
    }

    setDataInfo({
      ...dataInfo,
      notifications: checkboxList,
    });
  };

  /**
   * It should set input content message
   * @param e: Event
   */
  const onChangeContent = (e) => {
    const value= e.target.value
    const cleanValue = value.replace(TITLE_REGEX, '')

    setDataInfo({
      ...dataInfo,
      content: cleanValue,
    });
  };

  /**
   * It should drag and drop components
   * @param components: Array
   */
  const updateDragAndDrop = (components) => {
    setDataInfo({
      ...dataInfo,
      components,
    });
  };

  /**
   * It should sent template to save
   * @param null
   */
  const onConfirmEdition = () => {
    updateDataScenaries(dataInfo);
  };

  useEffect(() => {
    setDataInfo(customEdit);
  }, [customEdit]);

  return (
    <>
      <TemplateStyles.breadcrumb>
        <Typography
          text={"Customización de escenarios"}
          variant={variants.small}
          color={palette.neutral}
          asTag={"a"}
          hasDecoration
        />
        <img src={`${ChevronRight}`} alt={"icon"} loading="lazy" />
        <Typography
          text={templateName}
          variant={variants.small}
          color={palette.primary}
          asTag={"a"}
          hasDecoration
        />
      </TemplateStyles.breadcrumb>

      <TemplateStyles.AccordionPanel>
        <Accordion
          expanded={expandedAccordion === "panel1"}
          onChange={handleChangeStep3("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography
              text={"Orden de los componentes"}
              variant={variants.normal}
              color={palette.secondaryContainer}
              asTag={"h5"}
            />
          </AccordionSummary>
          <AccordionDetails>
            <DragAndDrop
              source={dataInfo?.components}
              updateComponentList={updateDragAndDrop}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expandedAccordion === "panel2"}
          onChange={handleChangeStep3("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography
              text={"Configuración de las notifcaciones"}
              variant={variants.normal}
              color={palette.secondaryContainer}
              asTag={"h5"}
            />
          </AccordionSummary>
          <AccordionDetails>
            <TemplateStyles.defaultWrapper
              style={{ justifyContent: "flex-start", paddingLeft: "1px" }}
            >
              <Typography
                text={
                  "El usuario puede elegir la frecuencia en que recibirá notificaciones "
                }
                variant={variants.small}
                color={palette.neutral20}
                asTag={"h5"}
              />
            </TemplateStyles.defaultWrapper>

            <TemplateStyles.GridRanges>
              {dataInfo?.notifications &&
                dataInfo?.notifications.map((item) => {
                  return (
                    <TemplateStyles.CheckBoxWrapper
                      key={item.id}
                      className={"wrapper-checkbox"}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id={item.label}
                              name={item.label}
                              checked={item.checked}
                              onChange={(e) =>
                                handleCheckboxNotification(e, item)
                              }
                              required
                            />
                          }
                          label={item.label}
                        />
                      </FormGroup>
                    </TemplateStyles.CheckBoxWrapper>
                  );
                })}
            </TemplateStyles.GridRanges>
            {dataInfo?.notifications.filter((i) => i.checked) === 0 && (
              <div id="help-error">
                {"Debes seleccionar al menos una opción para continuar"}
              </div>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expandedAccordion === "panel3"}
          onChange={handleChangeStep3("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography
              text={"Contenido de las notificaciones"}
              variant={variants.normal}
              color={palette.secondaryContainer}
              asTag={"h5"}
            />
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              text={
                "Personaliza el mensaje de las notificaciones que recibirá el usuario"
              }
              variant={variants.small}
              color={palette.neutral30}
              asTag={"p"}
            />
            <TemplateStyles.FieldWrapper>
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Ingresa el contenido del mensaje
                </InputLabel>
                <OutlinedInput
                  id={"contents"}
                  name={"content"}
                  label={"Ingresa el contenido del mensaje"}
                  value={dataInfo && dataInfo?.content}
                  type={"text"}
                  placeholder={"Ingresa el contenido del mensaje"}
                  required
                  onChange={(e) => onChangeContent(e)}
                  inputProps={{ maxLength: 200 }}
                />
                <FieldTextLimit
                  currentValue={dataInfo && dataInfo?.content}
                  limit={200}
                />
              </FormControl>
            </TemplateStyles.FieldWrapper>
          </AccordionDetails>
        </Accordion>
      </TemplateStyles.AccordionPanel>

      <TemplateStyles.ButtonWrapper>
        <Button
          variant={"contained"}
          type="button"
          onClick={() => onConfirmEdition()}
        >
          {"Confirmar edición"}
        </Button>

        <span style={{ marginTop: "30px" }} onClick={() => handleComponentsModal()}>
          <Typography
            text={"Cancelar edición"}
            variant={variants.small}
            color={palette.neutral20}
            asTag={"a"}
            hasDecoration
          />
        </span>
      </TemplateStyles.ButtonWrapper>
    </>
  );
};

export default EditExpandedTemplate;
