import * as React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
};

const ModalComponent = ({ isOpen, handleCloseModal, children, styleName, className }) => {
  return (
    <Modal
      open={isOpen}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          handleCloseModal();
        }
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={`base-modal ${className}`}
    >
      <Box sx={style} id="box-modal-step" style={styleName}>
        <IconButton
          style={{ position: "absolute", top: "5px", right: "5px" }}
          onClick={handleCloseModal}
        >
          <CloseIcon />
        </IconButton>
        {children}
      </Box>
    </Modal>
  );
};

export default ModalComponent;
