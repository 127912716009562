import React from "react";
import Typography from "../../components/common/typography";
import logInStyles from "./logIn.styled";
import Logo from "../../components/common/logo";
import { palette, variants } from "../../theme/partials";
import LogInForm from "../../components/form/logInForm/logInForm";

const LogInPage = () => {
  return (
    <logInStyles.Container>
      <logInStyles.LogoWrapper>
        <Logo />
      </logInStyles.LogoWrapper>

      <LogInForm
        title={"Bienvenido"}
        description={
          "Te invitamos a pasar con tus credenciales de tus servicios en Círculo de crédito."
        }
        hasTooltip
      />

      <logInStyles.CopyrightWrapper>
        <Typography
          text={"Derechos Reservados | Círculo de Crédito 2022"}
          variant={variants.extraSmall}
          color={palette.purpleCaption}
          asTag={"p"}
        />
        <Typography
          text={"Presentas dudas, puedes escribir un correo a ayuda@circulodigital.com.mx "}
          variant={variants.extraSmall}
          color={palette.purpleCaption}
          asTag={"p"}
        />
      </logInStyles.CopyrightWrapper>
    </logInStyles.Container>
  );
};

export default LogInPage;
