import React from "react";
import Button from "@mui/material/Button";

const ButtonComponent = ({ variant, text, handleClickButton, isDisabled }) => {
  return (
    <Button
      variant={variant}
      disabled={isDisabled}
      onClick={() => handleClickButton()}
    >
      {text}
    </Button>
  );
};

export default ButtonComponent;
