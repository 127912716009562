import React from "react";
import Skeleton from "react-loading-skeleton";

const LoaderIndcator = ({ show = false }) => {
  return (
    <>
      {show && (
        <>
          <div style={{ width: "100%" }}>
            <Skeleton height={"14px"} count={1} />
          </div>
          <div style={{ width: "100%" }}>
            <Skeleton height={"36px"} count={1} />
          </div>
        </>
      )}
    </>
  );
};

export default LoaderIndcator;
