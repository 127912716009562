import React from "react";
import FormHelperText from "@mui/material/FormHelperText";

const FieldTextLimit = ({ currentValue, limit = 30 }) => {
  const MyFormHelperText = () => {
    const helperText = React.useMemo(() => {
      return `${currentValue.length}/${limit}`;
    }, []);

    return (
      <FormHelperText className={"form-help"}>{helperText}</FormHelperText>
    );
  };

  return <>{currentValue && <MyFormHelperText />}</>;
};

export default FieldTextLimit;
