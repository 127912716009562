import React, { useState, useEffect } from "react";
import TemplateStyles from "../../../../components/createTemplate/createTemplate.styled";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "../../../../components/common/typography";
import { palette, variants } from "../../../../theme/partials";
import addIcon from "../../../../static/add.svg";
import FieldTextLimit from "../../../../helper/field";
import CheckDoneIcon from "../../../../static/done-check.svg";
import DeleteIconX from "../../../../static/delete-x.svg";
import EditIcon from "../../../../static/edit.svg";
import DeleteIcon from "../../../../static/delete.svg";
import ModalComponent from "../../../../components/common/modal";
import AlertComponent from "../../../../components/common/alert";
import warning from "../../../../static/warning.svg";
import { translation, DATA_STUB } from "../../../../constant";
import GraphicLineScenariesComponent from "../../../../components/createTemplate/components/GraphicLineScenaries";
import {
  removeScenarieById,
  updateScenarieByIdx,
  addNewScenarieList
} from "../../../../utils/filterTemplate";
import {NAMES_REGEX} from "../../../../helper/regex";

const UpdateScenariesComponent = ({
  sourceData,
  updateScenarieList,
  handleScenariesModal
}) => {
  const { staticText } = translation;
  const [data, setData] = useState({
    isEditingScenaries: null,
    loadCase: 1,
    errorMessage: null,
    isRemoveScenaries: false,
    indexRow: null,
    hideButtons: false,
    idx: null,
    scenariesSelectedToRemove: []
  });
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [newScenarie, setNewScenarie] = useState({
    name: "",
    start: 0,
    end: 0
  });
  const [isEditingItem, setIsEditingItem] = useState(false);

  /**
   * It should add new scenaries
   * @param null
   */
  const addNewScenaries = async () => {
    const result = await addNewScenarieList(
      newScenarie,
      data?.isEditingScenaries
    );

    if (result?.errorMessage) {
      setData({
        ...data,
        errorMessage: result?.errorMessage
      });

      setTimeout(() => {
        setData({
          ...data,
          errorMessage: null
        });
      }, 3000);

      return;
    }

    setData({
      ...data,
      isEditingScenaries: [...result?.tempArr],
      errorMessage: null
    });
    setIsAddingNew(false);
    setNewScenarie({
      name: "",
      start: 0,
      end: 0
    });
  };

  useEffect(() => {
    setData({
      ...data,
      isEditingScenaries: sourceData
    });
  }, [sourceData]);

  /**
   * It should remove blue indicator graphic
   * @param index: Number
   */
  const removeBlueIndicator = (index) => {
    let elPoint;

    const elLabel = document.getElementById(`label-0${index}`);
    const elBg = document.getElementById(`bg-0${index}`);
    const elForm = document.getElementById(`0-${index}`);

    if (index === 0) {
      elPoint = document.getElementById(`point-${index + 1}`);
      elPoint.className = "point";
    } else if (
      index > 1 &&
      (index === data?.isEditingScenaries.length - 1 ||
        index === data?.isEditingScenaries.length - 2)
    ) {
      elPoint = document.getElementById(`point-${index}`);
      elPoint.className = "point";
    } else {
      elPoint = document.getElementById(`point-${index}`);
      elPoint.className = "point";
    }

    elLabel.style.display = "block";
    elForm.style.display = "none";
    elBg.style.backgroundColor = "#F4F4F4";
    elBg.style.border = "1px solid #F4F4F4";

    setData({
      ...data,
      indexRow: null,
      hideButtons: false
    });

    setIsEditingItem(false);
  };

  /**
   * It should cancel values form
   * @param index: Number
   */
  const closeAddNewScenarie = (index) => {
    setNewScenarie({
      name: "",
      start: 0,
      end: 0
    });

    removeBlueIndicator(index);
  };

  /**
   * It should set new values form
   * @param name: String
   * @param e: Event
   */
  const setInputData = (name, e) => {
    const value= e.target.value
    const cleanValue = value.replace(NAMES_REGEX, '')

    setNewScenarie({
      ...newScenarie,
      [name]: cleanValue
    });
  };

  /**
   * It should show form to edit scenarie
   * @param idx: Number
   * @param item: Object
   * @param isOpen: Boolean
   */
  const editScenarieSelected = (index, item, isOpen) => {
    let elPoint;

    const elLabel = document.getElementById(`label-0${index}`);
    const elBg = document.getElementById(`bg-0${index}`);
    const elForm = document.getElementById(`0-${index}`);

    if (index === 0) {
      elPoint = document.getElementById(`point-${index + 1}`);
      elPoint.className = "point active";
    } else if (
      index > 1 &&
      (index === data?.isEditingScenaries.length - 1 ||
        index === data?.isEditingScenaries.length - 2)
    ) {
      elPoint = document.getElementById(`point-${index}`);
      elPoint.className = "point active";
    } else {
      elPoint = document.getElementById(`point-${index}`);
      elPoint.className = "point active";
    }

    if (isOpen) {
      setNewScenarie({
        ...item
      });

      elBg.style.backgroundColor = "#F6F8FF";
      elBg.style.border = "1px solid blue";
      elForm.style.display = "block";
      elLabel.style.display = "none";
      setIsEditingItem(true);
    } else {
      elBg.style.backgroundColor = "#F4F4F4";
      elBg.style.border = "1px solid #F4F4F4";
      elLabel.style.display = "block";
      elForm.style.display = "none";
      setIsEditingItem(false);
    }

    setData({
      ...data,
      hideButtons: true
    });
  };

  /**
   * It should update scenaries on list
   * @param idx: Number
   * @param scenarie: Object
   */
  const updateScenarieSelected = (idx, scenarie) => {
    const scenarieSelected = { ...scenarie, idx };
    const { errorMessage, tempArr } = updateScenarieByIdx(
      scenarieSelected,
      data?.isEditingScenaries
    );

    if (errorMessage) {
      setData({
        ...data,
        errorMessage,
        hideButtons: false
      });
      return;
    }

    removeBlueIndicator(idx);

    setData({
      ...data,
      isEditingScenaries: [...tempArr],
      errorMessage: null,
      hideButtons: false
    });
  };

  /**
   * It should open remove alert
   * @param idx: Number
   */
  const openRemoveAlertModal = (item) => {
    setData({
      ...data,
      idx: item?.scenarieId ? item?.scenarieId : "",
      isRemoveScenaries: true
    });
  };

  /**
   * It should remove scenaries on list
   * @param null
   */
  const removeScenarieSelected = async () => {
    const { tempArr, load } = await removeScenarieById(
      data?.idx,
      data?.isEditingScenaries
    );

    setData({
      ...data,
      loadCase: load,
      isEditingScenaries: [...tempArr],
      isRemoveScenaries: false,
      scenariesSelectedToRemove: [...data.scenariesSelectedToRemove, data?.idx]
    });
  };

  return (
    <>
      <GraphicLineScenariesComponent
        hasClassName
        sourceData={data && data?.isEditingScenaries}
      />

      <TemplateStyles.PreviewtWrapper>
        {data?.isEditingScenaries && data?.isEditingScenaries.length < 5 ? (
          <>
            <TemplateStyles.Steps
              className={
                !isAddingNew && !isEditingItem
                  ? "add-content"
                  : "add-content-disabled"
              }
              onClick={() => {
                setIsAddingNew(true);
              }}
            >
              <img src={`${addIcon}`} alt={"icon"} loading="lazy" />
              <Typography
                text={staticText.addScenaries}
                variant={variants.small}
                color={palette.primary}
                asTag={"h5"}
              />
            </TemplateStyles.Steps>
          </>
        ) : (
          <TemplateStyles.Steps
            className={"add-content-disabled"}
            onClick={() => {
              setIsAddingNew(true);
            }}
          >
            <img src={`${addIcon}`} alt={"icon"} loading="lazy" />
            <Typography
              text={staticText.addScenaries}
              variant={variants.small}
              color={palette.primary}
              asTag={"h5"}
            />
          </TemplateStyles.Steps>
        )}

        <>
          {data?.isEditingScenaries &&
            data?.isEditingScenaries.map((item, index) => {
              return (
                <TemplateStyles.Steps
                  id={`bg-0${index}`}
                  className={"preview-content"}
                  key={`${index}-${item.name}`}
                  onMouseOver={() =>
                    setData({
                      ...data,
                      indexRow: index
                    })
                  }
                  onMouseOut={() =>
                    setData({
                      ...data,
                      indexRow: null
                    })
                  }
                >
                  <>
                    <div id={`label-0${index}`}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center"
                        }}
                      >
                        <Typography
                          text={item.name}
                          variant={variants.normal}
                          color={palette.neutral30}
                          asTag={"h5"}
                        />
                        {data?.indexRow === index &&
                          !data?.hideButtons &&
                          !isAddingNew && (
                            <span
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center"
                              }}
                            >
                              <>
                                <>
                                  {data?.isEditingScenaries.length - 1 !==
                                    index && (
                                    <img
                                      className="icons"
                                      src={EditIcon}
                                      onClick={() =>
                                        editScenarieSelected(index, item, true)
                                      }
                                      style={{
                                        cursor: "pointer"
                                      }}
                                    />
                                  )}
                                </>
                                <>
                                  {data?.isEditingScenaries &&
                                    data?.isEditingScenaries.length > 3 && (
                                      <img
                                        className="icons delete"
                                        src={DeleteIcon}
                                        style={{
                                          cursor: "pointer"
                                        }}
                                        onClick={() => {
                                          openRemoveAlertModal(item);
                                        }}
                                      />
                                    )}
                                </>
                              </>
                            </span>
                          )}
                      </div>
                      <Typography
                        text={`Puntaje desde ${item.start} hasta ${item.end}`}
                        variant={variants.normal}
                        color={palette.neutral30}
                        asTag={"p"}
                      />
                    </div>

                    <div
                      id={`0-${index}`}
                      style={{ display: "none", width: "100%" }}
                    >
                      <TemplateStyles.RowStyle>
                        <TemplateStyles.FieldWrapper className="values">
                          <FormControl variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">
                              {staticText.name}
                            </InputLabel>
                            <OutlinedInput
                              id={"name"}
                              name={"name"}
                              label={"nombre"}
                              type={"text"}
                              placeholder={"Nombre"}
                              required
                              value={newScenarie.name}
                              onChange={(e) => {
                                setInputData("name", e);
                              }}
                              inputProps={{ maxLength: 14 }}
                            />
                            <FieldTextLimit
                              currentValue={newScenarie?.name}
                              limit={14}
                            />
                          </FormControl>
                        </TemplateStyles.FieldWrapper>
                        <TemplateStyles.FieldWrapper className="values">
                          <FormControl variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">
                              {staticText.start}
                            </InputLabel>
                            <OutlinedInput
                              id={"start"}
                              name={"start"}
                              label={"Desde"}
                              type={"text"}
                              placeholder={"Desde"}
                              required
                              value={newScenarie.start}
                              disabled={true}
                              inputProps={{ maxLength: 3 }}
                            />
                          </FormControl>
                        </TemplateStyles.FieldWrapper>
                        <TemplateStyles.FieldWrapper className="values">
                          <FormControl variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">
                              {staticText.end}
                            </InputLabel>
                            <OutlinedInput
                              id={"end"}
                              name={"end"}
                              label={"Hasta"}
                              type={"text"}
                              placeholder={"Hasta"}
                              value={newScenarie.end}
                              required
                              onChange={(e) => {
                                setInputData("end", e);
                              }}
                              inputProps={{ maxLength: 3 }}
                            />
                          </FormControl>
                        </TemplateStyles.FieldWrapper>
                      </TemplateStyles.RowStyle>

                      <TemplateStyles.option style={{ marginBottom: "16px" }}>
                        <Button
                          onClick={() => closeAddNewScenarie(index)}
                          className={"button-x close-scenarie"}
                        >
                          <img src={DeleteIconX} alt={"icon"} loading="lazy" />
                        </Button>
                        <Button
                          id="check"
                          disabled={!newScenarie.name || !newScenarie.end}
                          onClick={() =>
                            updateScenarieSelected(index, newScenarie)
                          }
                          className={"button-x add-scenarie"}
                        >
                          <img
                            src={CheckDoneIcon}
                            alt={"icon"}
                            loading="lazy"
                          />
                        </Button>
                      </TemplateStyles.option>

                      {data?.errorMessage && (
                        <div
                          style={{
                            textAlign: "left !important",
                            width: "100%"
                          }}
                        >
                          <p
                            style={{
                              color: "red",
                              fontSize: "10px",
                              fontWeight: 500,
                              padding: "0 20px",
                              marginBottom: "10px"
                            }}
                          >
                            {data?.errorMessage}
                          </p>
                        </div>
                      )}
                    </div>
                  </>
                </TemplateStyles.Steps>
              );
            })}
          <>
            {isAddingNew && (
              <div>
                <TemplateStyles.RowStyle>
                  <TemplateStyles.FieldWrapper className="values">
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password">
                        {staticText.name}
                      </InputLabel>
                      <OutlinedInput
                        id={"name"}
                        name={"name"}
                        label={"nombre"}
                        type={"text"}
                        placeholder={"Nombre"}
                        required
                        value={newScenarie.name}
                        onChange={(e) => {
                          setInputData("name", e);
                        }}
                        inputProps={{ maxLength: 14 }}
                      />
                      <FieldTextLimit
                        currentValue={newScenarie?.name}
                        limit={14}
                      />
                    </FormControl>
                  </TemplateStyles.FieldWrapper>
                  <TemplateStyles.FieldWrapper className="values">
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password">
                        {staticText.start}
                      </InputLabel>
                      <OutlinedInput
                        id={"start"}
                        name={"start"}
                        label={"Desde"}
                        type={"text"}
                        placeholder={"Desde"}
                        required
                        value={newScenarie.start}
                        disabled={false}
                        inputProps={{ maxLength: 3 }}
                        onChange={(e) => {
                          setInputData("start", e);
                        }}
                      />
                    </FormControl>
                  </TemplateStyles.FieldWrapper>
                  <TemplateStyles.FieldWrapper className="values">
                    <FormControl variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password">
                        {staticText.end}
                      </InputLabel>
                      <OutlinedInput
                        id={"end"}
                        name={"end"}
                        label={"Hasta"}
                        type={"text"}
                        placeholder={"Hasta"}
                        value={newScenarie.end}
                        required
                        onChange={(e) => {
                          setInputData("end", e);
                        }}
                        inputProps={{ maxLength: 3 }}
                      />
                    </FormControl>
                  </TemplateStyles.FieldWrapper>
                </TemplateStyles.RowStyle>

                <TemplateStyles.option style={{ marginBottom: "16px" }}>
                  <Button
                    onClick={() => {
                      setIsAddingNew(false);
                      setData({
                        ...data,
                        errorMessage: null
                      });
                      setNewScenarie({
                        name: "",
                        start: 0,
                        end: 0
                      });
                    }}
                    className={"button-x close-scenarie"}
                  >
                    <img src={DeleteIconX} alt={"icon"} loading="lazy" />
                  </Button>
                  <Button
                    id="check"
                    disabled={
                      !newScenarie.name ||
                      !newScenarie.start ||
                      !newScenarie.end
                    }
                    onClick={() => addNewScenaries()}
                    className={"button-x add-scenarie"}
                  >
                    <img src={CheckDoneIcon} alt={"icon"} loading="lazy" />
                  </Button>
                </TemplateStyles.option>

                {data?.errorMessage && (
                  <div
                    style={{
                      textAlign: "left !important",
                      width: "100%"
                    }}
                  >
                    <p
                      style={{
                        color: "red",
                        fontSize: "10px",
                        fontWeight: 500,
                        padding: "0 20px",
                        marginBottom: 0
                      }}
                    >
                      {data?.errorMessage}
                    </p>
                  </div>
                )}
              </div>
            )}
          </>
        </>
      </TemplateStyles.PreviewtWrapper>
      <TemplateStyles.ButtonWrapper>
        <Button
          variant={"contained"}
          type="button"
          onClick={() => {
            updateScenarieList({
              scenariesUpdated: data?.isEditingScenaries,
              scenariesRemoved: data?.scenariesSelectedToRemove
            });
            handleScenariesModal();
          }}
        >
          {"Confirmar edición"}
        </Button>

        <span
          style={{ marginTop: "30px" }}
          onClick={() => {
            setData({
              isEditingScenaries: null,
              loadCase: 1,
              errorMessage: null,
              isRemoveScenaries: false,
              indexRow: null,
              hideButtons: false,
              idx: null,
              scenariesSelectedToRemove: []
            });
            handleScenariesModal();
          }}
        >
          <Typography
            text={"Cancelar edición"}
            variant={variants.small}
            color={palette.neutral20}
            asTag={"a"}
            hasDecoration
          />
        </span>
      </TemplateStyles.ButtonWrapper>

      <ModalComponent
        isOpen={data?.isRemoveScenaries}
        handleCloseModal={() =>
          setData({
            ...data,
            isRemoveScenaries: false,
            idx: null
          })
        }
      >
        <AlertComponent
          icon={warning}
          title={staticText.removeScenarieTitle}
          description={staticText.removeScenarieDescription}
          sub={staticText.removeScenarieSubDescription}
          text={staticText.yesRemove}
          secondBtnText={staticText.noClose}
          onAlertClickButton={removeScenarieSelected}
          onSecondaryClickButton={() =>
            setData({
              ...data,
              isRemoveScenaries: false,
              idx: null
            })
          }
        />
      </ModalComponent>
    </>
  );
};

export default UpdateScenariesComponent;
