import styled from "styled-components";

const LogoWrapper = styled.div`
  .logo-mobile {
    display: block;
    padding-left: 10px;
  }

  .logo-desktop {
    display: none;
  } 

  @media screen and (min-width: 992px) {
    .logo-mobile {
      display: none;
    } 
    
    .logo-desktop {
      display: block;
    } 
  }
`;

const LogoStyles = {
  LogoWrapper,
};

export default LogoStyles;
