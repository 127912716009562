import { ThemeProvider } from '@mui/material/styles';
import GlobalStyle from "./globalStyles";
import muiTheme from './muiStyles'

const GamaAdminThemeProvider = ({ children }) => (
  <>
    <GlobalStyle />
    <ThemeProvider theme={muiTheme}>{children}</ThemeProvider>
  </>
);

export default GamaAdminThemeProvider;
