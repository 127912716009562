import styled from "styled-components";
import { palette } from "../../theme/partials";

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${palette.white};
  justify-content: center;
  padding: 20px;
`;

const TemplateStyle = {
  MainContent
};

export default TemplateStyle;
