import React from "react";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialLight } from "react-syntax-highlighter/dist/esm/styles/prism";
import remarkMath from "remark-math";
import remarkGfm from "remark-gfm";
import rehypeKatex from "rehype-katex";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography
} from "@mui/material";
import uuid from "react-uuid";

export default function MarkDownComponent({ content }) {
  const onCopyTextChanged = (id) => {
    const el = document.getElementById(id);

    el.innerHTML = "Copiado!";

    setTimeout(() => {
      el.innerHTML = "Copiar";
    }, 2000);
  };

  return (
    <ReactMarkdown
      children={content}
      remarkPlugins={[remarkGfm, remarkMath]}
      remarkToRehypeOptions={{ allowDangerousHtml: true }}
      rehypePlugins={[rehypeKatex]}
      components={{
        h1: (props) => (
          <Typography
            variant="h1"
            sx={{
              marginBottom: "1.5rem",
              fontSize: "32px !important",
              fontWeight: "bold"
            }}
            {...props}
          />
        ),
        h2: (props) => (
          <Typography
            variant="h2"
            sx={{
              marginBottom: "1.5rem",
              fontSize: "24px !important",
              fontWeight: "600"
            }}
            {...props}
          />
        ),
        h3: (props) => (
          <Typography
            variant="h3"
            sx={{
              marginBottom: "1.5rem",
              fontSize: "18px !important",
              fontWeight: "600"
            }}
            {...props}
          />
        ),
        h4: (props) => (
          <Typography
            variant="h4"
            sx={{ marginBottom: "1.5rem", fontSize: "14px !important" }}
            {...props}
          />
        ),
        h5: (props) => (
          <Typography
            variant="h5"
            sx={{ marginBottom: "1.5rem", fontSize: "14px !important" }}
            {...props}
          />
        ),
        h6: (props) => (
          <Typography
            variant="h6"
            sx={{ marginBottom: "1.5rem", fontSize: "14px !important" }}
            {...props}
          />
        ),
        p: (props) => (
          <Typography
            variant="body"
            sx={{ marginBottom: "1.5rem", fontSize: "14px !important" }}
            {...props}
          />
        ),
        table: (props) => (
          <TableContainer
            component={(props) => <Paper elevation={1} {...props} />}
            sx={{ marginBottom: "2rem" }}
          >
            <Table {...props}>{props.children}</Table>
          </TableContainer>
        ),
        thead: (props) => <TableHead {...props} />,
        tr: (props) => <TableRow {...props} />,
        th: (props) => <TableCell {...props} />,
        tbody: (props) => <TableBody {...props} />,
        td: (props) => <TableCell {...props} />,
        code({ node, inline, className, children, ...props }, index) {
          const match = /language-(\w+)/.exec(className || "");
          const currentId = uuid();
          return !inline && match ? (
            <>
              <CopyToClipboard
                text={String(children)}
                onCopy={() => onCopyTextChanged(currentId)}
              >
                <button>
                  <span id={currentId}>Copiar</span>
                  <ContentCopyIcon />
                </button>
              </CopyToClipboard>
              <SyntaxHighlighter
                children={String(children).replace(/\n$/, "")}
                style={materialLight}
                language={match[1]}
                PreTag="div"
                {...props}
              />
            </>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        }
      }}
    />
  );
}
