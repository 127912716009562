import { Typography, Divider } from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles'

const WrapperSeparator = styled('div')((size) => ({
	width: size ? `${size}%` : '100%',
	margin: '0 4px',
}))

const SeparatorComponent = ({ text, size }) => {
	return (
		<WrapperSeparator size={size}>
			<Divider>
				{text && <Typography variant="separatorText">{text}</Typography>}
			</Divider>
		</WrapperSeparator>
	)
}

export default SeparatorComponent
