import React from "react";
import logo from "../../../static/logo.svg";
import logoMobile from "../../../static/logo-mobile.svg";
import LogoStyles from "./logo.styled"

const Logo = () => {
  return (
    <LogoStyles.LogoWrapper>
      <img
        src={`${logo}`}
        className="logo-desktop"
        alt={"logo"}
        loading="lazy"
      />
      <img
        src={`${logoMobile}`}
        className="logo-mobile"
        alt={"logo"}
        loading="lazy"
      />
    </LogoStyles.LogoWrapper>
  );
};

export default Logo;
