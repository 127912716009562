import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Typography from "../typography";
import { palette, variants } from "../../../theme/partials";

const CheckboxComponent = ({
  label,
  isDisabled = false,
  isChecked,
  hasLink,
  textLink,
  handleTextClicked,
  handleCheckboxSelected
}) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            disabled={isDisabled}
            checked={isChecked}
            onChange={handleCheckboxSelected}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label={label}
      />
      {hasLink && (
        <Typography
          text={textLink}
          variant={variants.small}
          color={palette.primary}
          asTag={"a"}
          handleTagClicked={handleTextClicked}
        />
      )}
    </FormGroup>
  );
};

export default CheckboxComponent;
