import React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from '@mui/material/InputLabel';
import FormControl from "@mui/material/FormControl";

const SelectComponent = ({ id, onSelectedOption, currentValue, source, label, isDisabled, formStyle }) => {
  return (
    <FormControl fullWidth sx={formStyle}>
      <InputLabel id="demo-simple-select-label">{label}</InputLabel>
      <Select
        id={id}
        label={label}
        value={currentValue}
        onChange={(e) => onSelectedOption(e, id)}
        displayEmpty
        disabled={isDisabled}
        inputProps={{ "aria-label": "Without label" }}
      >
        <MenuItem disabled value="">
          <em>Seleccione una opción</em>
        </MenuItem>
        {source &&
          source.map((it, index) => (
            <MenuItem key={index} value={it}>
              {it}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default SelectComponent;
