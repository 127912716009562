import React from "react";
import TemplateStyles from "../createTemplate.styled";
import Typography from "../../common/typography";
import { palette, variants } from "../../../theme/partials";
import editIcon from "../../../static/edit.svg";

const ShowScenariesComponent = ({ sourceData, scenarieSelected }) => {
  return (
    <TemplateStyles.GridRanges>
      {sourceData &&
        sourceData.map((item, index) => {
          return (
            <TemplateStyles.Range key={`${index}-${item.name}`}>
              <div className="head">
                <Typography
                  text={item.name}
                  variant={variants.normal}
                  color={palette.neutral20}
                  asTag={"h5"}
                />
                <img
                  className="options"
                  onClick={() => scenarieSelected(item)}
                  src={`${editIcon}`}
                  alt={"icon"}
                  loading="lazy"
                />
              </div>
              <Typography
                text={`${item.start} - ${item.end}`}
                variant={variants.normal}
                color={palette.neutral30}
                asTag={"p"}
              />
            </TemplateStyles.Range>
          );
        })}
    </TemplateStyles.GridRanges>
  );
};

export default ShowScenariesComponent;
