import React from "react";
import Skeleton from "react-loading-skeleton";

const LoaderMap = ({ show = false }) => {

    return (
        <>
            {show && (
                <div style={{ width: '100%' }}>
                    <Skeleton height={'110px'} count={3} />
                </div>
            )}
        </>
    );
};

export default LoaderMap;
