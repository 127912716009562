import React, { useState, useEffect, useMemo } from "react";
import Button from "@mui/material/Button";
import TemplateStyle from "../../pages/template/template.styled";
import AddIcon from "@mui/icons-material/Add";
import TableHeader from "../common/table/headers";
import TableBody from "../common/table/body";
import TablePagination from "../common/table/pagination";
import TableSearch from "../common/table/search";
import { useNavigate } from "react-router-dom";
import { DATA_STUB, translation } from "../../constant";
import ModalComponent from "../../components/common/modal";
import AlertComponent from "../../components/common/alert";
import MobilePreview from "../common/phone";
import success from "../../static/success.svg";
import warning from "../../static/warning.svg";
import CreateTemplateForAdmin from "../../components/createTemplate";
import LoaderTable from "../skeleton/loaderTable";
import NotFoundTemplateTable from "./notFoundTemplateTable";
import {
  getTemplates,
  getPreviewTemplate,
  removeTemplate,
  publishTemplate
} from "../../services/platform.service";

const TemplateTableComponent = () => {
  const navigate = useNavigate();
  const { staticText } = translation;
  const [headers] = useState(DATA_STUB.templateTable.headers);
  const [body, setBody] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [showLoader, setShowLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [previewInformation, setPreviewInformation] = useState({ score: "", apiResponse: "" });
  const [templateInfo, setTemplateInfo] = useState({
    templateSelected: null,
    removeTemplateConfirmation: false,
    isTemplateRemoved: false,
    saveDraftConfirmation: false,
    openCreateTemplateModal: false,
    isCreateTemplateSucess: false,
    templateOptions: null,
    isTemplatePreviewActive: false
  });

  const ITEMS_PER_PAGE = 10;

  /**
   * It should close and show alert modal
   * @param step: Number
   */
  const onClickExitAndCloseModal = (step) => {
    if (step > 1 && step < 5) {
      setTemplateInfo({
        ...templateInfo,
        saveDraftConfirmation: true
      });
    }
  };

  const sortTemplatesByDate = (listOfTemplates, order = 'desc') => {
    return [...listOfTemplates].sort((a, b) => {
      const dateA = new Date(a.fechaModificacion);
      const dateB = new Date(b.fechaModificacion);

      if (order.toLowerCase() === 'desc') return dateB - dateA;
      return dateA - dateB;
    })
  }

  /**
   * It should open create template modal
   * @param null
   */
  const openCreateTemplateModal = () => {
    setTemplateInfo({
      ...templateInfo,
      openCreateTemplateModal: true
    });
  };
  /**
   * It should close create template modal
   * @param null
   */
  const closeCreateTemplateModal = () => {
    setTemplateInfo({
      ...templateInfo,
      openCreateTemplateModal: false,
      templateSelected: null
    });
  };

  /**
   * It should close create template sucess modal
   * @param null
   */
  const handleCloseSuccessModal = () => {
    setTemplateInfo({
      ...templateInfo,
      isCreateTemplateSucess: false
    });
  };

  /**
   * It should close preview template
   * @param null
   */
  const handleClosePreviewTemplateModal = () => {
    setTemplateInfo({
      ...templateInfo,
      isTemplatePreviewActive: false
    });
  };

  /**
   * It should close create template modal and show create template modal success
   * @param null
   */
  const handleShowTemplateCreationSuccess = () => {
    setTemplateInfo({
      ...templateInfo,
      openCreateTemplateModal: false,
      isCreateTemplateSucess: true
    });
    getTemplatesCreated();
  };

  /**
   * It should get all templates created
   * @param null
   */
  const getTemplatesCreated = async () => {
    setShowLoader(true);
    try {
      const { data } = await getTemplates();
      const sortedData = sortTemplatesByDate(data);
      if (sortedData) {
        setBody(sortedData);
      }
    } catch (error) {
      setBody([]);
    } finally {
      setShowLoader(false)
    }
  };

  const showPreviewTemplate = async (templateId) => {
    try {
      const score = Math.floor(Math.random() * (850 - 300) + 300);
      const { data } = await getPreviewTemplate(templateId, score);
      setTemplateInfo({
          ...templateInfo,
          isTemplatePreviewActive: true
      })
      setPreviewInformation({
          score,
          apiResponse: data,
      })
    } catch (error) {
        console.log(error)
    }
  };

  useEffect(() => {
    getTemplatesCreated();
  }, []);

  /**
   * It should define an option by case§
   * @param template: Object
   * @param option: String
   */
  const optionSelected = (template, option) => {
    if (option === "publish") {
      setShowLoader(true);
      const { idPlantilla: id } = template
      publishTemplate(id)
      .then(() => getTemplatesCreated())
      .finally(() => setShowLoader(false));
    }
  
    if (option === "remove") {
      setTemplateInfo({
        ...templateInfo,
        templateOptions: template,
        removeTemplateConfirmation: true
      });
    }

    if (option === "edit") {
      if (
        template?.etapa === "NOMBRE_CREADO" ||
        template?.etapa === "APARIENCIA_CREADA"
      ) {
        setTemplateInfo({
          ...templateInfo,
          templateSelected: template,
          openCreateTemplateModal: true
        });
        return;
      }

      navigate(`/template/${template?.idPlantilla}`);
    }

    if (option === "show") {
      showPreviewTemplate(template?.idPlantilla);
    }

    if (option === "copy") {
      console.log("copy");
    }
  };

  /**
   * It should close remove template confirmation modal
   * @param null
   */
  const onRemoveCancelButton = () => {
    setTemplateInfo({
      ...templateInfo,
      removeTemplateConfirmation: false
    });
  };

  /**
   * It should remove template on table by Id
   * @param null
   */
  const onRemoveTemplateButton = async () => {
    try {
      const response = await removeTemplate(
        templateInfo?.templateOptions?.idPlantilla
      );
      if (response) {
        setTemplateInfo({
          ...templateInfo,
          removeTemplateConfirmation: false
        });

        const data = await bodyData.filter(
          (temp) =>
            temp.idPlantilla !== templateInfo?.templateOptions.idPlantilla
        );

        if (data) setBody(data);

        setTimeout(() => {
          setTemplateInfo({
            ...templateInfo,
            removeTemplateConfirmation: false,
            isTemplateRemoved: true
          });
          getTemplatesCreated();
        }, 1000);
      }
    } catch (error) {}
  };

  const bodyData = useMemo(() => {
    let computedBody = body;

    if (search) {
      computedBody = computedBody.filter((comment) =>
        comment.nombre.toLowerCase().includes(search.toLowerCase())
      );
    }

    setTotalItems(computedBody.length);

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedBody = computedBody.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    return computedBody.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [body, currentPage, search, sorting]);

  return (
    <>
      <TemplateStyle.Actions>
        <TemplateStyle.Buttons>
          <Button variant="contained" onClick={openCreateTemplateModal}>
            <AddIcon /> {staticText.createNewTemplate}
          </Button>
        </TemplateStyle.Buttons>

        <TableSearch
          onSearch={(value) => {
            setSearch(value);
            setCurrentPage(1);
          }}
        />
      </TemplateStyle.Actions>
      <table>
        <TableHeader
          headers={headers}
          onSorting={(field, order) => setSorting({ field, order })}
        />
        <TableBody
          body={bodyData}
          optionSelected={optionSelected}
          show={!showLoader && bodyData.length > 0}
        />
      </table>
      <LoaderTable show={showLoader} />

      <NotFoundTemplateTable
        openCreateTemplateModal={openCreateTemplateModal}
        show={!showLoader && bodyData.length === 0}
      />

      <TablePagination
        showPagination={!showLoader && bodyData.length > 0}
        total={totalItems}
        itemsPerPage={ITEMS_PER_PAGE}
        currentPage={currentPage}
        onPageChange={(page) => setCurrentPage(page)}
      />

      {/* ALLOW SHOW TEMPLATE BY ID BEFORE REMOVE IT - MODAL  */}
      <ModalComponent
        isOpen={templateInfo?.removeTemplateConfirmation}
        handleCloseModal={() =>
          setTemplateInfo({
            ...templateInfo,
            removeTemplateConfirmation: false
          })
        }
      >
        <AlertComponent
          icon={warning}
          title={`${staticText.removeTemplate} "${templateInfo?.templateOptions?.nombre}"`}
          description={staticText.removeTemplateDescription}
          sub={staticText.removeTemplateSub}
          text={staticText.yesRemove}
          secondBtnText={staticText.noContinue}
          onAlertClickButton={onRemoveTemplateButton}
          onSecondaryClickButton={onRemoveCancelButton}
        />
      </ModalComponent>
      {/* END - ALLOW SHOW TEMPLATE BY ID BEFORE REMOVE IT - MODAL  */}

      {/* ALLOW SHOW TENPLATE REMOVED AFTER CONFIRMATION - MODAL */}
      <ModalComponent
        isOpen={templateInfo?.isTemplateRemoved}
        handleCloseModal={() => {
          setTemplateInfo({
            ...templateInfo,
            isTemplateRemoved: false
          });          
        }}
      >
        <AlertComponent
          icon={success}
          title={staticText.templateRemoved}
          description={staticText.templateDescriptionRemoved}
        />
      </ModalComponent>
      {/* END ALLOW SHOW TENPLATE REMOVED AFTER CONFIRMATION - MODAL */}

      {/* ALLOW CREATE NEW TEMPLATE - MODAL  */}
      <ModalComponent
        isOpen={templateInfo?.openCreateTemplateModal}
        handleCloseModal={closeCreateTemplateModal}
        className={"expand"}
      >
        <CreateTemplateForAdmin
          handleCloseCreateTemplate={onClickExitAndCloseModal}
          handleShowTemplateCreationSuccess={handleShowTemplateCreationSuccess}
          isTemplateSelected={templateInfo?.templateSelected}
          refreshListOfTemplates={getTemplatesCreated}
        />
      </ModalComponent>
      {/* END - ALLOW CREATE NEW TEMPLATE - MODAL  */}

      {/* ALLOW SHOW TEMPLATE CREATED - MODAL  */}
      <ModalComponent
        isOpen={templateInfo?.isCreateTemplateSucess}
        handleCloseModal={handleCloseSuccessModal}
      >
        <AlertComponent
          icon={success}
          title={staticText.templateCreated}
          description={staticText.templateCreatedSuccessfully}
        />
      </ModalComponent>
      {/* END ALLOW SHOW TEMPLATE CREATED - MODAL  */}

      {/* ALLOW PRESENT PREVIEW TEMPLATE - MODAL  */}
      <ModalComponent
        isOpen={templateInfo?.isTemplatePreviewActive}
        handleCloseModal={handleClosePreviewTemplateModal}
        className={"no-bg"}
      >
        <MobilePreview score={previewInformation.score} apiResponse={previewInformation.apiResponse} />
      </ModalComponent>
      {/* END PRESENT PREVIEW TEMPLATE - MODAL  */}

      {/* ALLOW SHOW ALERT BEFORE CLOSE CREATE TEMPLATE - MODAL  */}
      <ModalComponent
        isOpen={templateInfo?.saveDraftConfirmation}
        handleCloseModal={() => {
          setTemplateInfo({
            ...templateInfo,
            saveDraftConfirmation: false
          });
          getTemplatesCreated();
        }}
      >
        <AlertComponent
          icon={warning}
          title={`${staticText.draftTitle}`}
          description={staticText.draftDescription}
          sub={staticText.draftSubDescription}
          text={staticText.draftConfirm}
          secondBtnText={staticText.draftCancel}
          onAlertClickButton={() =>
            setTemplateInfo({
              ...templateInfo,
              saveDraftConfirmation: false,
              openCreateTemplateModal: false
            })
          }
          onSecondaryClickButton={() =>
            setTemplateInfo({
              ...templateInfo,
              saveDraftConfirmation: false
            })
          }
        />
      </ModalComponent>
      {/* END - ALLOW ALERT BEFORE CLOSE CREATE TEMPLATE - MODAL  */}
    </>
  );
};

export default TemplateTableComponent;
