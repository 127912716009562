import React from 'react'
import { Box } from '@mui/system'

const LayoutContent = ({ children }) => {

	return (
		<Box container component={'div'} className="main-layout">
			{children}
		</Box>
	)
}

export default LayoutContent
