import { DATA_STUB, translation, TYPES } from "../constant";
import { encode } from "../utils/encrypt";

/**
 * It should set templateName payload
 * @param templateInfo: Object
 */
export const setPayloadInitialStep = (templateInfo) => {
  const { name } = templateInfo;

  return {
    nombre: name
  };
};

/**
 * It should merge scenaries
 * @param scenariesEdited: Object
 * @param scenarieList: Array
 */
export const mergeScenariesEdited = (sceanariesEdited, scenarielist) => {
  let scenaries = [];
  for (let index = 0; index < sceanariesEdited.length; index++) {
    scenaries.push({
      components: scenarielist[0].components,
      content: scenarielist[0].content,
      end: sceanariesEdited[index].end,
      name: sceanariesEdited[index].name,
      notifications: scenarielist[0].notifications,
      scenarieId:
        scenarielist[index].scenarieId && scenarielist[index].scenarieId,
      start: sceanariesEdited[index].start
    });
  }

  return scenaries;
};

/**
 * It should set Appearance payload
 * @param templateInfo: Object
 */
export const setPayloadAppearence = (templateInfo) => {
  const { logo, color, templateId } = templateInfo;

  return {
    logo,
    color: {
      primario: color?.primary,
      secundario: color?.secondary
    },
    idPlantilla: templateId
  };
};

/**
 * It should get notification checked
 * @param notifications: Array
 */
export const getNotificationByEnumChecked = (notifications) => {
  return notifications
    .filter((item) => item.checked)
    .map((i) => {
      return i.enum;
    });
};

/**
 * It should transform scenaries for cases
 * @param scenaries: Array
 */
export const getScenariesForCases = (scenaries) => {
  const notificationFiltered = getNotificationByEnumChecked(
    DATA_STUB.notificationData
  );
  return scenaries.map((item) => {
    return {
      idEscenario: item.scenarieId,
      nombre: item.name,
      desde: item.start,
      hasta: item.end,
      componentes: [
        {
          tipo: TYPES.PUNTAJE_CREDITICIO,
          orden: 1
        },
        {
          tipo: TYPES.COMPOSICIÓN_PUNTAJE_ESTRATEGIAS,
          orden: 2
        },
        {
          tipo: TYPES.PUNTAJE_HISTÓRICO_COMPARATIVA,
          orden: 3
        },
        {
          tipo: TYPES.SUSCRIPCIÓN_NOTIFICACIONES,
          orden: 4
        }
      ],
      notificaciones: notificationFiltered,
      contenido: translation.staticText.scoreUpdated
    };
  });
};

/**
 * It should get payload scenaries and components
 * @param data: Object
 */
export const setPayloadScenariesAndComponents = (data) => {
  const {
    templateId,
    scenaries,
    fieldsComponent: { score, notify, history, points }
  } = data;
  const scenariesForCases = getScenariesForCases(scenaries);
  return {
    idPlantilla: templateId,
    escenarios: scenariesForCases,
    componentes: [
      {
        tipo: TYPES.PUNTAJE_CREDITICIO,
        titulo: score
      },
      {
        tipo: TYPES.COMPOSICIÓN_PUNTAJE_ESTRATEGIAS,
        titulo: points
      },
      {
        tipo: TYPES.PUNTAJE_HISTÓRICO_COMPARATIVA,
        titulo: history
      },
      {
        tipo: TYPES.SUSCRIPCIÓN_NOTIFICACIONES,
        titulo: notify
      }
    ]
  };
};

/**
 * It should get token Value
 * @param data: Object
 */
export const setTokenValues = (data) => {
  const {
    tokenAcceso,
    tokenAccesoExpiracion,
    tokenRenovacion,
    tokenRenovacionExpiracion
  } = data;

  return encode(
    JSON.stringify({
      token: tokenAcceso,
      tokenExpiration: tokenAccesoExpiracion,
      tokenRenovation: tokenRenovacion,
      renovationTimeExpiration: tokenRenovacionExpiracion
    })
  );
};

/**
 * It should get formData image
 * @param file: File
 */
export const setImageValuesByType = (file) => {
  const formData = new FormData();
  formData.append("archivo", file);
  return formData;
};

/**
 * It should get edit template data transformed
 * @param data: Object
 */
export const setEditDataTemplate = (data) => {
  return {
    templateId: data?.idPlantilla,
    name: data?.nombre,
    logo: data?.logo,
    color: {
      primary: data?.color?.primario,
      secondary: data?.color?.secundario
    },
    components: data?.componentes,
    scenaries: data?.escenarios
  };
};

/**
 * It should get custom scenaries info transformed
 * @param data: Object
 */
export const getCustomInfoScenariesMap = (data) => {
  return data.map((item) => {
    return {
      scenarieId: item.idEscenario,
      name: item.nombre,
      start: item.desde,
      end: item.hasta,
      notifications: item.notificaciones,
      content: item.contenido,
      components: item.componentes.map((sub) => {
        return {
          type: sub.tipo,
          sort: sub.orden
        };
      })
    };
  });
};

/**
 * It should update scenaries
 * @param data: Object
 */
export const setUpdateScenarieUpdate = (data) => {
  return {
    idEscenario: data.idEscenario,
    notificaciones: data.notificaciones,
    contenido: data.contenido,
    componentes: data.componentes.map((ox) => {
      return {
        tipo: ox.type,
        orden: ox.sort
      };
    })
  };
};

/**
 * It should get notifications checked
 * @param itemNotifications: Array
 */
const setNotificationList = (itemNotifications) => {
  let defaultNotifications = DATA_STUB.notifications;
  let matches = defaultNotifications.map((item) => {
    return {
      ...item,
      checked: itemNotifications.find((i) => item.enum === i) ? true : false
    };
  });

  return matches;
};

/**
 * It should get components with title
 * @param itemComponents: Array
 * @param titleComponents: Object
 */
const setComponentsWithTitle = (itemComponents, titleComponents) => {
  let componentResult = [];

  for (let i = 0; i < itemComponents.length; i++) {
    for (let j = 0; j < titleComponents.length; j++) {
      if (itemComponents[i].type === titleComponents[j].tipo) {
        componentResult.push({
          type: itemComponents[i].type,
          sort: itemComponents[i].sort,
          title: titleComponents[j].titulo
        });
      }
    }
  }

  return componentResult;
};

/**
 * It should get edit template data transformed
 * @param data: Object
 */
export const setItemSelectedMap = (item, titleComponents) => {
  const { scenarieId, name, start, end, content, components, notifications } =
    item;

  return {
    scenarieId,
    name,
    start,
    end,
    notifications: setNotificationList(notifications),
    content,
    components: setComponentsWithTitle(components, titleComponents)
  };
};

/**
 * It should get current title
 * @param type: String
 * @param titleComponents: Object
 */
const getCurrentTitle = (type, titleComponents) => {
  if (type === "PUNTAJE_CREDITICIO") {
    return titleComponents.score;
  }

  if (type === "COMPOSICIÓN_PUNTAJE_ESTRATEGIAS") {
    return titleComponents.points;
  }

  if (type === "PUNTAJE_HISTÓRICO_COMPARATIVA") {
    return titleComponents.history;
  }

  if (type === "SUSCRIPCIÓN_NOTIFICACIONES") {
    return titleComponents.notify;
  }
};

/**
 * It should get item selected mapper
 * @param item: Object
 * @param titleComponents: Object
 */
export const setItemSelectedEditMap = (item, titleComponents) => {
  const { scenarieId, name, start, end, content, components, notifications } =
    item;

  return {
    scenarieId,
    name,
    start,
    end,
    notifications: setNotificationList(notifications),
    content,
    components: components.map((i) => {
      return {
        type: i.type,
        sort: i.sort,
        title: getCurrentTitle(i.type, titleComponents)
      };
    })
  };
};

/**
 * It should get custom components payload
 * @param data: Object
 */
export const setPayloadCustomComponents = (data) => {
  const { components, content, notifications, scenarieId } = data;

  return {
    idEscenario: scenarieId,
    componentes: components,
    notificaciones: [
      ...notifications
        .filter((item) => item.checked)
        .map((e) => {
          return e.enum;
        })
    ],
    contenido: content
  };
};

/**
 * It should get notifications checked
 * @param data: Array
 */
export const setCheckedNotifications = (data) => {
  const { notifications } = data;

  return [
    ...notifications
      .filter((item) => item.checked)
      .map((e) => {
        return e.enum;
      })
  ];
};

/**
 * It should transform scenaries for cases
 * @param scenaries: Array
 */
export const getEditScenariesForCases = (scenaries) => {
  return scenaries.map((item) => {
    return {
      idEscenario: item.scenarieId,
      nombre: item.name,
      desde: item.start,
      hasta: item.end,
      componentes: [
        {
          tipo: item?.components[0].type,
          orden: 1
        },
        {
          tipo: item?.components[1].type,
          orden: 2
        },
        {
          tipo: item?.components[2].type,
          orden: 3
        },
        {
          tipo: item?.components[3].type,
          orden: 4
        }
      ],
      notificaciones: item.notifications,
      contenido: item.content
    };
  });
};

/**
 * It should get edit template payload
 * @param data: Object
 */
export const setEditTemplatePayload = (data) => {
  const { templateId, name, scenaries, mainComponents, color } = data;
  let payload = { idPlantilla: templateId };
  if (name) {
    payload = { ...payload, nombre: name };
  }

  if (color) {
    payload = {
      ...payload,
      color: {
        primario: color?.primary,
        secundario: color?.secondary
      }
    };
  }

  if (scenaries) {
    payload = { ...payload, escenarios: getEditScenariesForCases(scenaries) };
  }

  if (mainComponents) {
    payload = {
      ...payload,
      componentes: [
        {
          tipo: TYPES.PUNTAJE_CREDITICIO,
          titulo: mainComponents?.score
        },
        {
          tipo: TYPES.COMPOSICIÓN_PUNTAJE_ESTRATEGIAS,
          titulo: mainComponents?.points
        },
        {
          tipo: TYPES.PUNTAJE_HISTÓRICO_COMPARATIVA,
          titulo: mainComponents?.history
        },
        {
          tipo: TYPES.SUSCRIPCIÓN_NOTIFICACIONES,
          titulo: mainComponents?.notify
        }
      ]
    };
  }

  return payload;
};

export const nameComponentByType = (type) => {
  if (type === "PUNTAJE_CREDITICIO") {
    return "Puntaje crediticio";
  }

  if (type === "COMPOSICIÓN_PUNTAJE_ESTRATEGIAS") {
    return "Composición del puntaje y estrategias para incrementarlo";
  }

  if (type === "PUNTAJE_HISTÓRICO_COMPARATIVA") {
    return "Puntaje histórico y comparativa con otros usuarios";
  }

  if (type === "SUSCRIPCIÓN_NOTIFICACIONES") {
    return "Suscripción a notificaciones";
  }
};
