import mem from "mem";
import { tokenRefresh } from "./authentication.service";
import {
  getTokenValues,
  setCtxSession,
  setCookieData,
  removeCtxSession
} from "../helper/authMapper";

const refreshToken = async () => {
  try {
    const ctx = getTokenValues();

    const response = await tokenRefresh({
      tokenRenovacion: ctx?.tokenRenovation
    });

    if (response && response.data) {
      const tokenData = await setCookieData(response?.data);
      await setCtxSession(tokenData);
    }
  } catch (error) {
    removeCtxSession();
  }
};

const maxAge = 10000;

export const memoizedRefreshToken = mem(refreshToken, {
  maxAge
});
