import { SESSION } from "../constant";
import { decode, encode } from "../utils/encrypt";
import { setCookie, getCookie, isCookiesExist } from "../utils/context";

/**
 * It should get token
 * @param null
 */
export const getTokenValues = () => {
  if (!isCookiesExist) {
    return null;
  }

  const cookieSession = getCookie(SESSION?.name);

  if (cookieSession) {
    return JSON.parse(decode(cookieSession));
  }
};

/**
 * It should set token data
 * @param data: Object
 */
export const setCookieData = (data) => {
  const {
    tokenAcceso,
    tokenAccesoExpiracion,
    tokenRenovacion,
    tokenRenovacionExpiracion,
    otorgante: { nombre: firstname, apellidos: lastname }
  } = data;

  return {
    token: tokenAcceso,
    tokenExpiration: tokenAccesoExpiracion,
    tokenRenovation: tokenRenovacion,
    renovationTimeExpiration: tokenRenovacionExpiracion,
    displayName: `${firstname} ${lastname}`
  };
};

export const getParsedDataForRefresh = (data, displayName) => {
  const {
    tokenAcceso,
    tokenAccesoExpiracion,
    tokenRenovacion,
    tokenRenovacionExpiracion,
  } = data;

  return {
    displayName,
    token: tokenAcceso,
    tokenExpiration: tokenAccesoExpiracion,
    tokenRenovation: tokenRenovacion,
    renovationTimeExpiration: tokenRenovacionExpiracion,
  };
}

/**
 * It should set token
 * @param data: Object
 */
export const setCtxSession = (data) => {
  const {
    token,
    tokenExpiration,
    tokenRenovation,
    renovationTimeExpiration,
    displayName
  } = data;

  const ctxEncrypted = encode(
    JSON.stringify({
      token,
      tokenExpiration,
      tokenRenovation,
      renovationTimeExpiration,
      displayName
    })
  );

  setCookie(SESSION.name, ctxEncrypted);
  setCookie(SESSION.type, 1);

  return true;
};

/**
 * It should remove token
 * @param null
 */
export const removeCtxSession = async () => {
  document.cookie = SESSION?.name + "=; Max-Age=-99999999;";
  setCookie(SESSION.type, 0);
};

const mappers = {
  getTokenValues,
  setCtxSession,
  removeCtxSession
};

export default mappers;
