import React from 'react'
import { Box } from '@mui/material'
import common from './common.json'
import './index.css'
// 
import FicoScoreComponent from './components/Graphics/FicoScore'
import GraphicBarComponent from './components/GraphicBar/Graphic'
import ComparateScoreComponent from './components/Score/comparateScore'

import SliderComposition from './components/Composition'
import HowItsWorkComponent from './components/how-its-work'
import PanelComponent from './components/Panel'
import SubscribeFormCompoent from './components/SubscribeForm'

const { sections, graphic } = common

export const COMPONENTS_CATEGORIES = {
  GENERAL: 'general',
  SUBSCRIPTION: 'subscription'
}

const NOTIFICATIONS_PERIODS = {
  '15_DIAS': 'Cada medio mes',
  '1_MES': 'Cada 1 mes',
  '2_MESES': 'Cada 2 meses',
  '3_MESES': 'Cada 3 meses',
  '6_MESES': 'Cada medio año'
}

export const getScoreComponent = (params) => {
  const { id, order, title, color, score } = params
  const { principal: primaryColor, secundario: secondaryColor } = color

  const scoreComponent = {
    id,
    order: order,
    type: COMPONENTS_CATEGORIES.GENERAL,
    heading: title,
    content: [
      {
        id: 0,
        name: 'FICO® Score',
        icon: '',
        content: <FicoScoreComponent
          score={score}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
         />,
      },
      {
        id: 1,
        name: '¿Para qué sirve?',
        icon: '',
        content: <HowItsWorkComponent
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
        />,
      },
    ],
  }

  return scoreComponent
}

export const getCompositionComponent = (params) => {
  const { id, order, title, color } = params
  const { principal: primaryColor, secundario: secondaryColor } = color

  const compositionComponent = {
    id,
    order: order,
    type: COMPONENTS_CATEGORIES.GENERAL,
    heading: title,
    content: [
      {
        id: 0,
        name: 'Composición',
        icon: '',
        content: <SliderComposition
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
        />,
      },
      {
        id: 1,
        name: '¿Cómo incrementarlo?',
        icon: '',
        content: <PanelComponent
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          source={sections.howImplement} />,
      },
    ],
  }

  return compositionComponent;
}

export const getComparativeComponent = (params) => {
  const { id, order, title, color } = params
  const { principal: primaryColor, secundario: secondaryColor } = color

  const comparativeComponent = {
    id,
    order: order,
    type: COMPONENTS_CATEGORIES.GENERAL,
    heading: title,
    content: [
      {
        id: 0,
        name: 'Tu histórico',
        icon: '',
        content: <GraphicBarComponent
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          source={graphic.dataPerMonths} />,
      },
      {
        id: 1,
        name: title,
        icon: '',
        content: (
          <ComparateScoreComponent
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            source={sections.comparateScore} />
        ),
      },
    ],
  }

  return comparativeComponent;
}

export const getSubscriptionComponent = (params) => {
  const { id, order, title, color, notifications } = params
  const { principal: primaryColor, secundario: secondaryColor } = color

  const subscriptionOptions = notifications.map(notification => {
    const value = notification
    const label = NOTIFICATIONS_PERIODS[notification]

    return { label, value }
  })

  const subscriptionComponent = {
    id,
    order: order,
    type: COMPONENTS_CATEGORIES.SUBSCRIPTION,
    heading: title,
    content:
      <>
        <Box component={'div'} className="section">
          <SubscribeFormCompoent
            title={title}
            subscriptionOptions={subscriptionOptions}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
          />
        </Box>
      </>
  }

  return subscriptionComponent;
}
