import React from "react";
import { Box } from "@mui/material";
import "./index.css";
import BasicCardComponent from "./components/Cards/BasicCard";
import TabComponent from "./components/Tabs";
import {
  COMPONENTS_CATEGORIES,
  getComparativeComponent,
  getCompositionComponent,
  getScoreComponent,
  getSubscriptionComponent
} from "./previewComponents";

const getComponentsData = (components, scenarios) => {
  const componentsData = {};

  for (const component of components) {
    const { tipo, titulo } = component;
    componentsData[tipo] = { title: titulo };
  }

  for (const component of scenarios[0].componentes) {
    const { tipo, orden } = component;
    componentsData[tipo] = { ...componentsData[tipo], order: orden };
  }

  return componentsData;
};

const COMPONENTS_TYPES = {
  SCORE: "PUNTAJE_CREDITICIO",
  COMPOSITION: "COMPOSICIÓN_PUNTAJE_ESTRATEGIAS",
  COMPARATIVE: "PUNTAJE_HISTÓRICO_COMPARATIVA",
  SUBSCRIPTION: "SUSCRIPCIÓN_NOTIFICACIONES"
};

const getSortedComponentsList = (componentsList) => {
  return [...componentsList].sort((a, b) => a.order - b.order);
};

const getComponentsList = ({ score, color, componentsData, notifications }) => {
  const scoreComponent = getScoreComponent({
    score,
    color,
    id: COMPONENTS_TYPES.SCORE,
    order: componentsData[COMPONENTS_TYPES.SCORE].order,
    title: componentsData[COMPONENTS_TYPES.SCORE].title
  });
  const compositionComponent = getCompositionComponent({
    color,
    id: COMPONENTS_TYPES.COMPOSITION,
    order: componentsData[COMPONENTS_TYPES.COMPOSITION].order,
    title: componentsData[COMPONENTS_TYPES.COMPOSITION].title
  });
  const comparativeComponent = getComparativeComponent({
    color,
    id: COMPONENTS_TYPES.COMPARATIVE,
    order: componentsData[COMPONENTS_TYPES.COMPARATIVE].order,
    title: componentsData[COMPONENTS_TYPES.COMPARATIVE].title
  });
  const subscriptionComponent = getSubscriptionComponent({
    color,
    id: COMPONENTS_TYPES.SUBSCRIPTION,
    order: componentsData[COMPONENTS_TYPES.SUBSCRIPTION].order,
    title: componentsData[COMPONENTS_TYPES.SUBSCRIPTION].title,
    notifications
  });

  const componentsList = [
    scoreComponent,
    compositionComponent,
    comparativeComponent,
    subscriptionComponent
  ];

  return componentsList;
};

export const renderPreviewComponents = ({ score, apiResponse }) => {
  const { color, componentes, escenarios } = apiResponse;
  const notifications = escenarios[0].notificaciones;

  const componentsData = getComponentsData(componentes, escenarios);
  const componentsList = getComponentsList({
    score,
    color,
    componentsData,
    notifications
  });
  const sortedComponentsList = getSortedComponentsList(componentsList);

  return (
    <>
      {sortedComponentsList &&
        sortedComponentsList.map((component) => {
          if (component.type === COMPONENTS_CATEGORIES.SUBSCRIPTION) return (
            <React.Fragment key={component.id}>
              {component.content}
            </React.Fragment>
          );

          return (
            <Box key={component.id} component={"div"} className="section bs-borderbox">
              <BasicCardComponent cardHeading={component.heading}>
                <TabComponent source={component.content} />
              </BasicCardComponent>
            </Box>
          );
        })}
    </>
  );
};
