import {
    PRIMARY,
    PRIMARY_CONTAINER,
    TERTIARY,
    TERTIARY_CONTAINER,
    BLACK,
    NEUTRAL_VARIANT_10,
    NEUTRAL_VARIANT_20,
    NEUTRAL_VARIANT_30,
    NEUTRAL,
    NEUTRAL_VARIANT_95,
    NEUTRAL_VARIANT_100,
    ERROR,
    ERROR_CONTAINER,
    SECONDARY,
    SECONDARY_CONTAINER,
    ON_BORDER,
    ON_IPHONE_CONTAINER,
    ON_IPHONE_BUTTON,
    ON_SECUNDARY,
    ON_SECUNDARY_CONTAINER,
    ON_SECUNDARY_OPACITY,
    ON_PURPLE,
    WHITE
} from './variables';

const palette = {
    black: BLACK,
    error: ERROR,
    errorContainer: ERROR_CONTAINER,
    neutral: NEUTRAL,
    neutral10: NEUTRAL_VARIANT_10,
    neutral20: NEUTRAL_VARIANT_20,
    neutral30: NEUTRAL_VARIANT_30,
    neutral95: NEUTRAL_VARIANT_95,
    neutral100: NEUTRAL_VARIANT_100,
    onSecondary: ON_SECUNDARY,
    onSecondaryContainer: ON_SECUNDARY_CONTAINER,
    onSecondaryOpacity: ON_SECUNDARY_OPACITY,
    primary: PRIMARY,
    primaryContainer: PRIMARY_CONTAINER,
    secondary: SECONDARY,
    iphoneContainer: ON_IPHONE_CONTAINER,
    iphoneButtonContainer: ON_IPHONE_BUTTON,
    onBorder: ON_BORDER,
    secondaryContainer: SECONDARY_CONTAINER,
    tertiary: TERTIARY,
    tertiaryContainer: TERTIARY_CONTAINER,
    purpleCaption: ON_PURPLE,
    white: WHITE
}

export default palette;