import styled from "styled-components";
import { palette } from "../../theme/partials";

const ItemDraggable = styled.div`
  background-color: ${palette.white};
  margin: 10px 0;
  border-radius: 8px;
  width: 100%;
  border: 1px solid ${palette.onBorder};
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    border: 1px solid ${palette.primary};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 0;
`;

const Icon = styled.div`
  padding: 0 0 0 14px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 6px 16px;
  
  p {
    margin: 4px 0;
  }

  h4 {
    font-weight: 400 !important;
  }
`;

const DragStyles = {
  ItemDraggable,
  Content,
  Info,
  Icon,
};

export default DragStyles;
