import React, { useState, useEffect } from "react";
import TemplateStyles from "../createTemplate.styled";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "../../common/typography";
import { palette, variants } from "../../../theme/partials";
import FieldTextLimit from "../../../helper/field";
import CheckDoneIcon from "../../../static/done-check.svg";
import DeleteIconX from "../../../static/delete-x.svg";
import ModalComponent from "../../../components/common/modal";
import AlertComponent from "../../../components/common/alert";
import warning from "../../../static/warning.svg";
import { translation } from "../../../constant";
import { NAMES_REGEX } from "../../../helper/regex";

const CreateScenariesComponent = ({
  sourdeData,
  removeScenarie,
  updateScenarie,
  errorMessage,
}) => {
  const { staticText } = translation;
  const [scenaries, setScenaries] = useState(false);
  const [indexRow, setIndex] = useState(null);
  const [indexRemove, setIndexRemove] = useState(null);
  const [isRemoveScenaries, setIsRemoveScenaries] = useState(false);
  const [hideButtons, setHideButtons] = useState(false);
  const [newScenarie, setNewScenarie] = useState({
    name: "",
    start: 0,
    end: 0,
  });

  useEffect(() => {
    setScenaries(sourdeData);
  }, [sourdeData]);

  /**
   * It should remove bluc indicator
   * @param index: Number
   */
  const removeBlueIndicator = (index) => {
    let elPoint;
    const elForm = document.getElementById(`0-${index}`);
    const elPreview = document.getElementById(`01-${index}`);

    if (index === 0) {
      elPoint = document.getElementById(`point-${index + 1}`);
      elPoint.className = "point";
    } else if (
      index > 1 &&
      (index === scenaries.length - 1 || index === scenaries.length - 2)
    ) {
      elPoint = document.getElementById(`point-${index}`);
      elPoint.className = "point";
    } else {
      elPoint = document.getElementById(`point-${index}`);
      elPoint.className = "point";
    }

    elPreview.style.display = "block";
    elForm.style.display = "none";

    setIndex(null);
    setHideButtons(false);
  };

  /**
   * It should update scenaries
   * @param index: Number
   * @param item: Object
   */
  const updateScenaries = (index, item) => {
    updateScenarie(index, item);
    removeBlueIndicator(index);
  };

  /**
   * It should close add new scenarie form
   * @param index: Number
   */
  const closeAddNewScenarie = (index) => {
    setNewScenarie({
      name: "",
      start: 0,
      end: 0,
    });

    removeBlueIndicator(index);
  };

  /**
   * It should set input data
   * @param name: String
   * @param e: Event
   */
  const setInputData = (name, e) => {
    const value = e.target.value
    const cleanValue = value.replace(NAMES_REGEX, '')

    setNewScenarie({
      ...newScenarie,
      [name]: cleanValue,
    });
  };

  /**
   * It should active bluc indicator
   * @param index: Number
   * @param item: Object
   * @param isOpen: Boolean
   */
  const editScenarie = (index, item, isOpen) => {
    let elPoint;
    const elForm = document.getElementById(`0-${index}`);
    const elPreview = document.getElementById(`01-${index}`);

    if (index === 0) {
      elPoint = document.getElementById(`point-${index + 1}`);
      elPoint.className = "point active";
    } else if (
      index > 1 &&
      (index === scenaries.length - 1 || index === scenaries.length - 2)
    ) {
      elPoint = document.getElementById(`point-${index}`);
      elPoint.className = "point active";
    } else {
      elPoint = document.getElementById(`point-${index}`);
      elPoint.className = "point active";
    }

    if (isOpen) {
      setNewScenarie({
        ...item,
      });

      elPreview.style.display = "none";
      elForm.style.display = "block";
    } else {
      elPreview.style.display = "block";
      elForm.style.display = "none";
    }

    setHideButtons(true);
  };

  /**
   * It should remove template button
   * @param null
   */
  const onRemoveTemplateButton = () => {
    removeScenarie(indexRemove);
    setIsRemoveScenaries(false);
  };

  return (
    <>
      <TemplateStyles.PreviewtWrapper>
        <>
          {scenaries &&
            scenaries.map((item, index) => {
              return (
                <TemplateStyles.Steps
                  id={index}
                  className="preview-content"
                  key={`${index}-${item.name}`}
                  onMouseOver={() => setIndex(index)}
                  onMouseOut={() => setIndex(null)}
                >
                  <>
                    <div id={`01-${index}`}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          text={item.name}
                          variant={variants.normal}
                          color={palette.neutral30}
                          asTag={"h5"}
                        />
                        {indexRow === index && !hideButtons && (
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                          </span>
                        )}
                      </div>
                      <Typography
                        text={`Puntaje desde ${item.start} hasta ${item.end}`}
                        variant={variants.normal}
                        color={palette.neutral30}
                        asTag={"p"}
                      />
                    </div>

                    <div id={`0-${index}`} style={{ display: "none" }}>
                      <TemplateStyles.RowStyle>
                        <TemplateStyles.FieldWrapper className="values">
                          <FormControl variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">
                              {staticText.name}
                            </InputLabel>
                            <OutlinedInput
                              id={"name"}
                              name={"name"}
                              label={"nombre"}
                              type={"text"}
                              placeholder={"Nombre"}
                              required
                              value={newScenarie.name}
                              onChange={(e) => {
                                setInputData("name", e);
                              }}
                              inputProps={{ maxLength: 14 }}
                            />
                            <FieldTextLimit
                              currentValue={newScenarie?.name}
                              limit={14}
                            />
                          </FormControl>
                        </TemplateStyles.FieldWrapper>
                        <TemplateStyles.FieldWrapper className="values">
                          <FormControl variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">
                              {staticText.start}
                            </InputLabel>
                            <OutlinedInput
                              id={"start"}
                              name={"start"}
                              label={"Desde"}
                              type={"text"}
                              placeholder={"Desde"}
                              required
                              value={newScenarie.start}
                              disabled={true}
                              inputProps={{ maxLength: 3 }}
                            />
                          </FormControl>
                        </TemplateStyles.FieldWrapper>
                        <TemplateStyles.FieldWrapper className="values">
                          <FormControl variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">
                              {staticText.end}
                            </InputLabel>
                            <OutlinedInput
                              id={"end"}
                              name={"end"}
                              label={"Hasta"}
                              type={"text"}
                              placeholder={"Hasta"}
                              value={newScenarie.end}
                              required
                              onChange={(e) => {
                                setInputData("end", e);
                              }}
                              inputProps={{ maxLength: 3 }}
                            />
                          </FormControl>
                        </TemplateStyles.FieldWrapper>
                      </TemplateStyles.RowStyle>

                      <TemplateStyles.option style={{ marginBottom: "16px" }}>
                        <Button
                          onClick={() => closeAddNewScenarie(index)}
                          className={"button-x close-scenarie"}
                        >
                          <img src={DeleteIconX} alt={"icon"} loading="lazy" />
                        </Button>
                        <Button
                          id="check"
                          disabled={!newScenarie.name || !newScenarie.end}
                          onClick={() => updateScenaries(index, newScenarie)}
                          className={"button-x add-scenarie"}
                        >
                          <img
                            src={CheckDoneIcon}
                            alt={"icon"}
                            loading="lazy"
                          />
                        </Button>
                      </TemplateStyles.option>

                      {errorMessage && (
                        <div
                          style={{
                            textAlign: "left !important",
                            width: "100%",
                          }}
                        >
                          <p
                            style={{
                              color: "red",
                              fontSize: "10px",
                              fontWeight: 500,
                              padding: "0 20px",
                              marginBottom: 0,
                            }}
                          >
                            {errorMessage}
                          </p>
                        </div>
                      )}
                    </div>
                  </>
                </TemplateStyles.Steps>
              );
            })}
        </>
      </TemplateStyles.PreviewtWrapper>

      <ModalComponent
        isOpen={isRemoveScenaries}
        handleCloseActiveModal={() => setIsRemoveScenaries(false)}
      >
        <AlertComponent
          icon={warning}
          title={staticText.removeScenarieTitle}
          description={staticText.removeScenarieDescription}
          sub={staticText.removeScenarieSubDescription}
          text={staticText.yesRemove}
          secondBtnText={staticText.noClose}
          onAlertClickButton={onRemoveTemplateButton}
          onSecondaryClickButton={() => setIsRemoveScenaries(false)}
        />
      </ModalComponent>
    </>
  );
};

export default CreateScenariesComponent;
