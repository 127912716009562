import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Typography from "../../common/typography";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";
import ErrorIcon from "@mui/icons-material/Error";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import forgotPasswordStyles from "./forgotPasswordForm.styled";
import { palette, variants } from "../../../theme/partials";

const validationSchema = Yup.object({
  email: Yup.string("Ingresa tu email")
    .email("Ingresa un email válido")
    .required("Email es requerido")
});

const ForgotPasswordForm = ({ title, description, onCancelHandle }) => {
  const formik = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    }
  });

  return (
    <forgotPasswordStyles.FormContent onSubmit={formik.handleSubmit}>
      <forgotPasswordStyles.HeadingWrapper>
        <>
          {title && (
            <Typography
              text={title}
              variant={variants.large}
              color={palette.secondary}
              asTag={"h3"}
            />
          )}
        </>
        <>
          {description && (
            <Typography
              text={description}
              variant={variants.normal}
              color={palette.neutral30}
              asTag={"p"}
            />
          )}
        </>
      </forgotPasswordStyles.HeadingWrapper>

      <forgotPasswordStyles.FieldWrapper>
        <FormControl
          variant="outlined"
          className={
            formik.touched.email && formik.errors.email && "text-error"
          }
        >
          <InputLabel htmlFor="outlined-adornment-password">Email</InputLabel>
          <OutlinedInput
            id={"email"}
            name={"email"}
            label={"Email"}
            type={"email"}
            endAdornment={
              <>
                {formik.errors.email &&
                  formik.touched.email &&
                  Boolean(formik.errors.email) && (
                    <InputAdornment position="end">
                      <IconButton aria-label="alert" edge="end">
                        <ErrorIcon />
                      </IconButton>
                    </InputAdornment>
                  )}
              </>
            }
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            placeholder={"Ingresa tu correo electrónico"}
            onBlur={formik.handleBlur}
            required
          />
          {formik.errors.email && (
            <div id="help-error">{formik.errors.email}</div>
          )}
        </FormControl>
      </forgotPasswordStyles.FieldWrapper>

      <forgotPasswordStyles.ButtonWrapper>
        <Button variant={"contained"} disabled={!formik.isValid} type="submit">
          Enviar
        </Button>
        <span style={{ marginTop: "30px" }} onClick={onCancelHandle}>
          <Typography
            text={"Cancelar"}
            variant={variants.small}
            color={palette.neutral30}
            asTag={"a"}
          />
        </span>
      </forgotPasswordStyles.ButtonWrapper>
    </forgotPasswordStyles.FormContent>
  );
};

export default ForgotPasswordForm;
