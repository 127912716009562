import React from 'react'
import { Box } from '@mui/system'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	)
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	}
}

const TabComponent = ({ source }) => {
	const [currentTab, setTab] = React.useState(0)

	const handleTabClicked = (event, newValue) => {
		setTab(newValue)
	}

	return (
		<Box sx={{ width: '100%' }}>
			<Box components={'div'}>
				<Tabs
					value={currentTab}
					onChange={handleTabClicked}
					TabIndicatorProps={{
						style: {
							backgroundColor: '#000',
						},
					}}
				>
					{source.map((tab) => {
						return (
							<Tab
								key={tab.id}
								label={tab.name}
								value={tab.id}
								{...a11yProps(tab.id)}
								className="Mui-tab-header"
							/>
						)
					})}
				</Tabs>
			</Box>
			<Box className="bg-white">
				{source.map((panel) => {
					return (
						<TabPanel
							key={panel.id}
							value={currentTab}
							index={panel.id}
							className="Mui-tab-content"
						>
							{panel.content}
						</TabPanel>
					)
				})}
			</Box>
		</Box>
	)
}

export default TabComponent
