import * as React from "react";
import DashboardStyle from "../../pages/dashboard/dashboard.styled";
import GraphicBar from "../../components/graphics/bar";
import GraphicGeo from "../../components/graphics/worldwide";
import GraphicLineal from "../../components/graphics/lineal";
import DashboardIndicators from "../../components/graphics/indicators";

export class ComponentToPrint extends React.PureComponent {
  render() {
    return (
      <div className="relativeCSS" style={{ width: '100%'}}>
        <style type="text/css" media="print">
          {
            "\
     @page { size: landscape; }\
  "
          }
        </style>

        <DashboardStyle.MainContent>
          <DashboardIndicators />

          <DashboardStyle.WrapperGraphics>
            <div className="grid-item item1">
              <GraphicBar />
            </div>
          </DashboardStyle.WrapperGraphics>

          <DashboardStyle.WrapperGraphicsMaps>
            <div className="grid-item item3">
              <GraphicLineal />
            </div>
            <div className="grid-item item4">
              <GraphicGeo />
            </div>
          </DashboardStyle.WrapperGraphicsMaps>
        </DashboardStyle.MainContent>
      </div>
    );
  }
}

export const FunctionalComponentToPrint = React.forwardRef((ref) => {
  return <ComponentToPrint ref={ref} />;
});
