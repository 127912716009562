import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Typography from "../../common/typography";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import ErrorIcon from "@mui/icons-material/Error";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Checkbox from "../../common/checkbox";
import signUpStyles from "./signUpForm.styled";
import { palette, variants } from "../../../theme/partials";
import ModalComponent from "../../common/modal/modal";
import ChangePhoneForm from "../changePhone";
import success from "../../../static/success.svg";
import info from "../../../static/info.svg";
import warning from "../../../static/warning.svg";
import AlertComponent from "../../common/alert";

const validationSchema = Yup.object({
  email: Yup.string("Ingresa tu email")
    .email("Ingresa un email válido")
    .required("Email es requerido"),
  phone: Yup.string("Ingresa tu contraseña")
    .min(10, "Contraseña debe tener mínimo 10 caracteres")
    .required("Contraseña es requerida"),
  rfc: Yup.string("Ingresa tu código RFC").required("RFC es requerido"),
  age18: Yup.bool().oneOf([true], "Acepta soy mayor de edad"),
  terms: Yup.bool().oneOf([true], "Acepta terminos y condiciones"),
  privacy: Yup.bool().oneOf([true], "Acepta aviso de privacidad"),
});

const SignUpForm = ({ title, description }) => {
  const [open, setOpen] = React.useState(false);
  const [openSucsessModal, setOpenSuccess] = React.useState(false);
  const [openInfoModal, setOpenInfo] = React.useState(false);
  const [openWarningModal, setOpenWarning] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  const handleOpenSucess = () => setOpenSuccess(true);
  const handleCloseSuccess = () => setOpenSuccess(false);
  
  const handleOpenInfo = () => setOpenInfo(true);
  const handleCloseInfo = () => setOpenInfo(false);
  
  const handleOpenWarning = () => setOpenWarning(true);
  const handleCloseWarning = () => setOpenWarning(false);
  
  const formik = useFormik({
    initialValues: {
      email: "",
      phone: "",
      rfc: "",
      age18: false,
      terms: false,
      privacy: 18,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  return (
    <signUpStyles.FormContent onSubmit={formik.handleSubmit}>
      <signUpStyles.HeadingWrapper>
        <>
          {title && (
            <Typography
              text={title}
              variant={variants.large}
              color={palette.secondary}
              asTag={"h3"}
            />
          )}
        </>
        <>
          {description && (
            <Typography
              text={description}
              variant={variants.normal}
              color={palette.neutral30}
              asTag={"p"}
            />
          )}
        </>
      </signUpStyles.HeadingWrapper>

      <signUpStyles.FieldWrapper>
        <FormControl
          variant="outlined"
          className={
            formik.touched.email && formik.errors.email && "text-error"
          }
        >
          <InputLabel htmlFor="outlined-adornment-password">Email</InputLabel>
          <OutlinedInput
            id={"email"}
            name={"email"}
            label={"Email"}
            type={"email"}
            endAdornment={
              <>
                {formik.errors.email &&
                  formik.touched.email &&
                  Boolean(formik.errors.email) && (
                    <InputAdornment position="end">
                      <IconButton aria-label="alert" edge="end">
                        <ErrorIcon />
                      </IconButton>
                    </InputAdornment>
                  )}
              </>
            }
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            placeholder={"Ingresa tu correo electrónico"}
            onBlur={formik.handleBlur}
            required
          />
          {formik.errors.email && (
            <div id="help-error">{formik.errors.email}</div>
          )}
        </FormControl>
      </signUpStyles.FieldWrapper>

      <signUpStyles.FieldWrapper>
        <FormControl
          variant="outlined"
          className={
            formik.touched.phone && formik.errors.phone && "text-error"
          }
        >
          <InputLabel htmlFor="outlined-adornment-password">
            Teléfono
          </InputLabel>
          <OutlinedInput
            id={"phone"}
            name={"phone"}
            label={"Phone"}
            type={"text"}
            endAdornment={
              <>
                {!formik.values.phone &&
                  formik.touched.phone &&
                  formik.errors.phone && (
                    <InputAdornment position="end">
                      <IconButton aria-label="alert" edge="end">
                        <ErrorIcon />
                      </IconButton>
                    </InputAdornment>
                  )}
                {formik.values.phone.length === 10 && (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="alert"
                      edge="end"
                      onClick={handleOpen}
                    >
                      <Typography
                        text={"Cambiar teléfono"}
                        variant={variants.small}
                        color={palette.primary}
                        asTag={"a"}
                        hasDecoration
                      />
                    </IconButton>
                  </InputAdornment>
                )}
              </>
            }
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
            placeholder={"Ingresa tu teléfono celular"}
            onBlur={formik.handleBlur}
            required
          />
          {formik.errors.phone && (
            <div id="help-error">{formik.errors.phone}</div>
          )}
        </FormControl>
      </signUpStyles.FieldWrapper>

      <signUpStyles.FieldWrapper>
        <FormControl
          variant="outlined"
          className={formik.touched.rfc && formik.errors.rfc && "text-error"}
        >
          <InputLabel htmlFor="outlined-adornment-password">RFC</InputLabel>
          <OutlinedInput
            id={"rfc"}
            name={"rfc"}
            label={"rfc"}
            type={"text"}
            endAdornment={
              <>
                {formik.touched.rfc && formik.errors.rfc && (
                  <InputAdornment position="end">
                    <IconButton aria-label="alert" edge="end">
                      <ErrorIcon />
                    </IconButton>
                  </InputAdornment>
                )}
              </>
            }
            value={formik.values.rfc}
            onChange={formik.handleChange}
            error={formik.touched.rfc && Boolean(formik.errors.rfc)}
            helperText={formik.touched.rfc && formik.errors.rfc}
            placeholder={"Ingresa tu códico RFC"}
            onBlur={formik.handleBlur}
            required
          />
          {formik.errors.rfc && <div id="help-error">{formik.errors.rfc}</div>}
        </FormControl>
      </signUpStyles.FieldWrapper>

      <signUpStyles.CheckBoxWrapper className={"wrapper-checkbox"}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                id="terms"
                name="terms"
                checked={formik.values.age18}
                onChange={formik.handleChange}
                required
              />
            }
            label={
              "Confirmo que soy responsable por las acciones realizadas bajo el perfil Administrador"
            }
          />
        </FormGroup>
      </signUpStyles.CheckBoxWrapper>

      <signUpStyles.CheckBoxWrapper className={"wrapper-checkbox"}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                id="terms"
                name="terms"
                checked={formik.values.terms}
                onChange={formik.handleChange}
                required
              />
            }
            label={"Acepto los"}
          />
          <Typography
            text={"Términos y Condiciones"}
            variant={variants.small}
            color={palette.primary}
            asTag={"a"}
          />
        </FormGroup>
      </signUpStyles.CheckBoxWrapper>

      <signUpStyles.CheckBoxWrapper>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                id="privacy"
                name="privacy"
                checked={formik.values.privacy}
                onChange={formik.handleChange}
                required
              />
            }
            label="Acepto el"
          />
          <Typography
            text={"Aviso de Privacidad"}
            variant={variants.small}
            color={palette.primary}
            asTag={"a"}
          />
        </FormGroup>
      </signUpStyles.CheckBoxWrapper>

      <signUpStyles.ButtonWrapper>
        <Button variant={"contained"} disabled={!formik.isValid} type="submit">
          Continuar
        </Button>
      </signUpStyles.ButtonWrapper>

      {/* MODAL */}
      <ModalComponent isOpen={open} handleCloseActiveModal={handleClose}>
        <ChangePhoneForm
          title={"Cambiar teléfono"}
          description={
            "Introduce el teléfono celular con el que te quieres identificar"
          }
        />
      </ModalComponent>

      <ModalComponent
        isOpen={openSucsessModal}
        handleCloseActiveModal={handleCloseSuccess}
      >
        <AlertComponent
          icon={success}
          title={"Petición enviada"}
          description={"Su petición fue enviada con exito."}
        />
      </ModalComponent>

      <ModalComponent
        isOpen={openInfoModal}
        handleCloseActiveModal={handleCloseInfo}
      >
        <AlertComponent
          icon={info}
          text={"Entendido"}
          description={
            "A continuación serás redireccionado a un gestor de contraseñas para mayor seguridad"
          }
          onAlertClickButton={handleCloseInfo}
        />
      </ModalComponent>

      <ModalComponent
        isOpen={openWarningModal}
        handleCloseActiveModal={handleCloseWarning}
      >
        <AlertComponent
          icon={warning}
          text={"Solicitar un nuevo link"}
          title={"Lo sentimos, este link ya no es válido"}
          description={"Solicita un nuevo link de acceso aqui abajo."}
          onAlertClickButton={handleCloseWarning}
          secondBtnText={'No, Cancelar'}
        />
      </ModalComponent>
    </signUpStyles.FormContent>
  );
};

export default SignUpForm;
