export const PRIMARY = '#1148F4';
export const PRIMARY_CONTAINER = '#F6F8FF';
export const WHITE = '#FFFFFF';
export const TERTIARY = '#006D31';
export const TERTIARY_CONTAINER = '#C8FCD1';
export const BLACK = '#000000';
export const NEUTRAL_VARIANT_10 = '#d7d7d7';
export const NEUTRAL_VARIANT_20 = '#303034';
export const NEUTRAL_VARIANT_30 = '#45464F';
export const NEUTRAL = '#ACACBF';
export const NEUTRAL_VARIANT_95 = '#F4F4F4';
export const NEUTRAL_VARIANT_100 = '#FFFFFF';
export const ERROR = '#EF3F3F';
export const ERROR_CONTAINER = '#FFDAD6';
export const SECONDARY = '#1F3063';
export const SECONDARY_CONTAINER = '#293E80';
export const ON_SECUNDARY = '#293E80';
export const ON_SECUNDARY_CONTAINER = '#5B73C4';
export const ON_SECUNDARY_OPACITY = 'rgba(41, 62, 128, 0.5)';
export const ON_PURPLE = "#DDE1FF";
export const ON_BORDER = "#E4EBFF";
export const ON_IPHONE_CONTAINER = "#202020";
export const ON_IPHONE_BUTTON = "#3f3f3f";