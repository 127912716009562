import React from 'react'
import { Box, Typography, Button } from '@mui/material'
import { ResponsiveBar } from '@nivo/bar'
import ImageComponent from '../../Images/ImageComponent'
import GridComponent from '../../Grid'
import IndividualValueBarComponent from '../../GraphicBar/IndividualValueBar'
import ArrowChevronLeftIcon from '../../../static/icons/arrow-chevron-left.svg'
import ArrowChevronRightIcon from '../../../static/icons/arrow-chevron-right.svg'

const data = [
	{
		month: '01',
		value: 239,
	},
	{
		month: '02',
		value: 261,
	},
	{
		month: '03',
		value: 455,
	},
	{
		month: '04',
		value: 78,
	},
	{
		month: '05',
		value: 171,
	},
	{
		month: '06',
		value: 456,
	},
	{
		month: '07',
		value: 367,
	},
	{
		month: '08',
		value: 637,
	},
	{
		month: '09',
		value: 67,
	},
	{
		month: '10',
		value: 249,
	},
	{
		month: '11',
		value: 673,
	},
	{
		month: '12',
		value: 672,
	},
]

const GraphicBarComponent = () => {
	return (
		<Box component={'div'}>
			<Box
				component={'div'}
				className="flex-row space-evenly"
				sx={{ padding: '0 0 10px 0 !important' }}
			>
				<Button>
					<ImageComponent source={ArrowChevronLeftIcon} alt={'arrow-left'} />
				</Button>
				<Typography variant="currentYearGraphic">2022</Typography>
				<Button>
					<ImageComponent source={ArrowChevronRightIcon} alt={'arrow-right'} />
				</Button>
			</Box>
			<Box component={'div'} className="wrapper-nivo bar-chart">
				<GridComponent totalColumns={12} totalGap={0}>
					{data.map((c) => {
						return <IndividualValueBarComponent key={c.month} data={c} />
					})}
				</GridComponent>
				<ResponsiveBar
					data={data}
					keys={['value']}
					colors={['#cce4f2']}
					indexBy={(_index) => `${_index.month} `}
					borderRadius={5}
					margin={{ top: 10, bottom: 50 }}
					padding={0.5}
					valueScale={{ type: 'linear' }}
					animate={true}
					enableLabel={false}
					enableGridY={false}
					defs={[
						{
							id: 'LowGradient',
							type: 'linearGradient',
							colors: [
								{ offset: 39, color: '#f7794f' },
								{ offset: 89, color: '#f7734f' },
							],
						},
						{
							id: 'SemiLowGradient',
							type: 'linearGradient',
							colors: [
								{ offset: 0, color: 'rgba(255,239,0,1)' },
								{ offset: 100, color: '#FF5050' },
							],
						},
						{
							id: 'MediumGradient',
							type: 'linearGradient',
							colors: [
								{ offset: 0, color: 'rgba(104,255,0,1)' },
								{ offset: 50, color: 'rgba(255,239,0,1)' },
								{ offset: 100, color: '#FF5050' },
							],
						},
						{
							id: 'gradientD',
							type: 'linearGradient',
							colors: [
								{ offset: 0, color: 'rgba(1,214,65,1)' },
								{ offset: 35, color: 'rgba(104,255,0,1)' },
								{ offset: 65, color: 'rgba(255,239,0,1)' },
								{ offset: 100, color: '#FF5050' },
							],
						},
						{
							id: 'gradientE',
							type: 'linearGradient',
							colors: [
								{ offset: 0, color: 'rgba(0,219,66,1)' },
								{ offset: 25, color: 'rgba(1,214,65,1)' },
								{ offset: 50, color: 'rgba(104,255,0,1)' },
								{ offset: 75, color: 'rgba(255,239,0,1)' },
								{ offset: 100, color: '#FF5050' },
							],
						},
					]}
					fill={[
						{ match: (d) => d.data.data.value < 220, id: 'LowGradient' },
						{
							match: (d) => d.data.data.value > 219 && d.data.data.value < 430,
							id: 'SemiLowGradient',
						},
						{
							match: (d) => d.data.data.value > 429 && d.data.data.value < 580,
							id: 'MediumGradient',
						},
						{
							match: (d) => d.data.data.value > 579 && d.data.data.value < 700,
							id: 'gradientD',
						},
						{ match: (d) => d.data.data.value > 699, id: 'gradientE' },
					]}
				/>
			</Box>
		</Box>
	)
}

export default GraphicBarComponent
