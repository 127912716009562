import React from "react";
import TemplateStyles from "../createTemplate.styled";
import Typography from "../../common/typography";
import { palette, variants } from "../../../theme/partials";
import { RANGES } from "../../../constant";

const GraphicLineScenariesComponent = ({ sourceData, hasClassName }) => {
  
  /**
   * It should print graphic
   * @param null
   */
  const printGraphicPoints = () => {
    let customPointsScenaries = [];
    let customNamesScenaries = [];

    const initial = {
      value: RANGES.initial,
    };
    const end = {
      value: RANGES.end,
    };

    customPointsScenaries.push(initial);

    for (let index = 0; index < sourceData.length; index++) {
      customNamesScenaries.push({
        name: sourceData[index].name,
      });

      if (index === sourceData.length - 1) {
        continue;
      }

      customPointsScenaries.push({
        value: sourceData[index].end,
      });
    }

    const mt20 = hasClassName ? { marginTop: "20px" } : { marginTop: "0" };

    customPointsScenaries.push(end);

    return (
      <TemplateStyles.Steps className="graphic">
        <div className="score">
          {customPointsScenaries &&
            customPointsScenaries.map((item, idx) => {
              return (
                <div className="point" key={`point-${idx}`} id={`point-${idx}`}>
                  <Typography
                    text={item?.value}
                    variant={variants.fit}
                    color={palette.neutral20}
                    asTag={"p"}
                  />
                  <div
                    className={
                      idx === 0 || idx === customPointsScenaries.length - 1
                        ? "circle gray"
                        : "circle"
                    }
                  ></div>
                </div>
              );
            })}
        </div>
        <div className="names">
          {customNamesScenaries &&
            customNamesScenaries.map((item, idx) => {
              return (
                <div className="ref" key={`ref-${idx}`} style={mt20}>
                  <Typography
                    text={item?.name}
                    variant={variants.fit}
                    color={palette.neutral20}
                    asTag={"p"}
                  />
                </div>
              );
            })}
        </div>
      </TemplateStyles.Steps>
    );
  };

  return <>{sourceData && printGraphicPoints()}</>;
};

export default GraphicLineScenariesComponent;
