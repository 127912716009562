import { createGlobalStyle } from "styled-components";
import { palette, fontFamily, variants } from "./partials";
import "react-loading-skeleton/dist/skeleton.css";

export const GlobalStyle = createGlobalStyle`
  html {
    font-family: ${fontFamily.Poppins}, sans-serif !important;
    margin: 0;
    padding: 0;
    overflow-y: hidden;
    background-color: ${palette.white};
  }

  * {
    box-sizing: inherit!important;
    margin: 0;
    outline: none;
    padding: 0;
  }
  
  body {
    background-color: ${palette.secondary};
  }

  #loading-root {
    left: 0;
    position: absolute;
    top: 0;
  }

  a {
    cursor: pointer;
    text-decoration: none;
  }

  .cover-photo {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }

  p {
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    width: 100%;

    &.form-help {
      text-align: right !important;
      margin: 4px 0 !important;
    }
  }

  h3 {
    font-weight: 600;
  }

  ul, li {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  #help-error {
    color: ${palette.error};
    font-size: 12px;
  }

  table {
    display: grid;
    border-collapse: collapse;
    min-width: 100%;
    grid-template-columns: minmax(150px, 2fr) minmax(150px, 2fr) minmax(150px, 1fr) minmax(200px, 2.2fr);
  }

  .last {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .actions {
    display: none;
  }

  .linked {
    border: 1px solid ${palette.primary};
    border-radius: 8px;
    color: ${palette.primary};
    margin-right: 8px;
    padding: 3px 8px;
    position: relative;
    transition: box-shadow 100ms ease-in-out;
    width: fit-content;

    &:not(.linked--disabled):hover {
      box-shadow: 0 0 4px ${palette.primary};
    }

    &--disabled {
      border-color: #CCC;
      color: #CCC;
      cursor: not-allowed;
    }
  }

  .rows {
    .linked, .icons {
      visibility: hidden;
    }

    &:hover {
      .linked, .icons {
        visibility: visible;
        transition: all 200ms ease-in-out;
      }
    }
  }

  .icons {
    align-items: center;
    display: flex;
    height: 20px;
    margin: 0 2px;
    padding: 6px;
    position: relative;
    width: 20px;

    svg {
      z-index: 2;
    }

    &--blue::after,
    &--darkblue::after,
    &--red::after {
      border-radius: 50%;
      content: "";
      height: 100%;
      left: 50%;
      opacity: 0.2;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: background-color 100ms ease-in-out;
      width: 100%;
      z-index: 0;
    }

    &--blue:hover::after {
      background-color: #1148F4;
    }
    &--darkblue:hover::after {
      background-color: #293E80;
    }
    &--red:hover::after {
      background-color: #EF3F3F;
    }

    &--disabled {
      cursor: not-allowed;
      svg path {
        fill: #CCC;
      }
    }

    &--check {
      padding-left: 6px;
    }
  }

  thead,
  tbody,
  tr {
    display: contents;
  }
 
  thead
  td {
    background-color: ${palette.neutral95};
    color: ${palette.neutral20};
    font-size: 14px;
  }
  
  tbody {
    tr {
      td {
        background-color: ${palette.white};
        color: ${palette.neutral20};
        font-size: 14px;
        box-shadow: inset 0px -1px 0px rgba(17, 17, 17, 0.08);
      }

      &:hover {
        background-color: red;
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin-top: 10px;
    padding: 0;

    li {
      cursor: pointer;
      padding: 6px;
      border: none;
      color: ${palette.neutral30};
      padding: 0 10px;

      &:first-child,
      &:last-child {
        position: relative;

        .page-link {
          & span:first-child {
            position: absolute;
            top: -10px;
            left: 6px;
            font-size: 24px;
          }
        }
      }

      &.page-item {
        &.active {
          color: ${palette.primary};
          background-color: ${palette.neutral95};
          border-radius: 2px;
        }

        &.disabled {
          pointer-events: none;
          color: rgb(198, 197, 202);
          border: none;
        }

        &.page-link {
          font-weight: 400;
        }

        .visually-hidden {
          display: none;
        }
      }
     }
   }
   

   .sort-icon {
    margin: 0 5px;
    height: 12px;
    width: 15px;
   }

  tbody {
    tr:hover {
      background-color: ${palette.primaryContainer} !important;
      cursor: pointer;
    }
  }
  
  th,
  td {
    padding: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  th {
    position: sticky;
    top: 0;
    background: #6c7ae0;
    text-align: left;
    font-weight: normal;
    font-size: 1.1rem;
    color: white;
  }
  
  th:last-child {
    border: 0;
  }


  td {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .no-padding-table {
    padding: 0
  }
  
  .not-found-table {
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    h3, p, a {
      margin: 6px 0;
    }
  }

  .desc-tag {
    color: ${palette.neutral20};
    font-size:${variants.small};
  }

  #box-modal-step {
    width: 90%;
    
    /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
    @media (min-width: 768px) {  
      width: 80%;
    }
  
    
    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1024px) {  
      width: 65%;
    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {  
      width: 58%;
    }
    
    /* Extra extra large devices (extra large desktops, 1400px and up) */
    @media (min-width: 1400px) {  
      width: 46%;
    }
  }

  .error-message {
    color: ${palette.error};
    font-size: 10px; 
    font-weight: 500;
  }

  .label-upload {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: ${palette.primary};
    textDecoration: underline;
  }

  .column-start {
    display:flex;
    flex-direction:column;
    align-items: flex-start;

    p {
      margin-left: 6px;
    }
  }

  .button-x {
    align-center: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 12px !important;
    margin: 0 8px !important;

    img {
      margin-left: 0 !important;
    }

    &.add-scenarie {
      border: 1px solid ${palette.primary};
      background-color: ${palette.primary};

      &:hover {
        border: 1px solid ${palette.primary};
        background-color: ${palette.primary};
      }
    }

    &.close-scenarie {
      border: 1px solid #bebebe !important;
      background-color: ${palette.white};

      &:hover {
        border: 1px solid #bebebe !important;
        background-color: ${palette.white};
      }
    }
  }

  #check.Mui-disabled {
    border: 1px solid ${palette.primary};
    background-color: ${palette.primary};
    opacity: 0.8;
  }

  .wrapper-checkbox {
    margin-top: 20px;
  }

  .base-modal {
    &.no-bg {
      #box-modal-step {
        background: transparent;
      }
    }
    div.MuiBox-root {
      background-color: white;
      border-radius: 20px;

      form {
        margin-top: 0;
      }
    }
  }

  #upload-photo {
    opacity: 0;
    position: absolute;
    z-index: -1;
 }
 
  .center {
    position: relative;
    z-index: 3;
  }

  .base-modal {
    overflow-y: scroll;
  }

  .card {
    background-color: ${palette.white};
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin: 20px 0 10px 0;
    width: 100%;
  }

  .graphic-style {
    height: 350px;
    min-height: 350px;
    max-height: 350px;
    height: 340px;
  }
  
  .half-graphic-style {
    min-height: 540px;
    max-height: 540px;
    height: 540px;
  }

  .map-content {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
  }

  .MuiStep-horizontal {
    .Mui-active {
      color: ${palette.onSecondaryOpacity} !important;
    }
    .Mui-completed {
      color: ${palette.secondaryContainer} !important;
    }
  }

  .wrapper {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .iphone {
    position: relative;
    background: ${palette.iphoneContainer};
    width: 45%;
    height: 0;
    padding-top: 95%;
    margin: 0 auto;
    border-radius: 3em;
  }
  
  .power {
    position: absolute;
    background: ${palette.iphoneContainer};
    width: 13%;
    height: .5%;
    top: -.5%;
    right: 12%;
    border-top-left-radius: .5em;
    border-top-right-radius: .5em;
  }
  
  .lock {
    position: absolute;
    background: ${palette.iphoneContainer};
    width: 1%;
    height: 5%;
    top: 12.5%;
    left: -1%;
    border-top-left-radius: .5em;
    border-bottom-left-radius: .5em;
  }
  
  .volume {
    position: absolute;
    background: ${palette.iphoneContainer};
    width: 1%;
    height: 4%;
    top: 23%;
    left: -1%;
    border-top-left-radius: .5em;
    border-bottom-left-radius: .5em;
  }
  
  .volume.down{ top: 31%; }
  
  .camera {
    position: absolute;
    top: 4%;
    left: 48.5%;
    background: ${palette.iphoneButtonContainer};
    width: 3%;
    height: 0;
    padding-top: 3%;
    border-radius: 50%;
  }
  
  .speaker {
    position: absolute;
    top: 7%;
    left: 42.5%;
    background: ${palette.iphoneButtonContainer};
    width: 15%;
    height: 0;
    padding-top: 3%;
    border-radius: 1em;
  }
  
  .text {
    opacity: 0;
    font-size: 1.25rem;
    text-align: center;
  }
  
  .screen {
    background: ${palette.white};
    width: 90%;
    height: 75%;
    position: absolute;
    top: 12.5%;
    left: 5%;
  }

  .preview-wrapper {
    .bs-borderbox, .bs-borderbox * {
      box-sizing: border-box!important;
    }
  }

  .iframe {
    height: 100%;
    position: absolute;
    transition: all 1s ease;
    width: 100%;

    iframe {
      height: 100%;
    }
  }

  .heading-section {
    color: ${palette.secondaryContainer};
    font-size: ${variants.normal};
    font-weight 500 !important;
    margin-bottom: 10px;
  }

  .loader-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    transition: all 1s ease-in-out;
    
    animation: unhide 5s infinite alternate;

    img {
      height: 160px;
      width: 160px;
    }
  }
  
  .button {
    position: absolute;
    bottom: 2.75%;
    left: 42.5%;
    background: ${palette.iphoneButtonContainer};
    border: 1px solid ${palette.black};
    width: 15%;
    height: 0;
    padding-top: 15%;
    border-radius: 50%;
  }
  
  .square {
    position: absolute;
    top: 26%;
    left: 25%;
    border: 2px solid ${palette.neutral10};
    border-radius: .3em;
    width: 45%;
    height: 45%;
  }
  
  @media screen and (min-width: 28em ) {
    body{ font-size: 8px; }
  }
  
  @media screen and (min-width: 34em ) {
    body{ font-size: 10px; }
  }
  
  @media screen and (min-width: 48em ) {
    body{ font-size: 14px; }
  }

  .card-blue {
    background-color: #E4EBFF;
    padding: 8px 20px;
    margin: 0 10px;
    border: 1px solid #1148F4;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 4px rgba(186, 186, 186, 0.25);
  }

  .info-icon {
    position: relative;
    svg {
      position: absolute;
      left: 4px;
      top: 0;
    }
  }

  pre {
    width: 100%;
    position: relative;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    margin: 24px 0;
    z-index:2;

    button {
      position: absolute;
      right: 6px;
      top: 6px;
      z-index: 4;
      background: none;
      border: none;
      cursor: pointer;
      color: #9c9c9c !important;
      display: flex;
      justify-content: center;

      &:hover, &:hover > svg {
        color: ${palette.primary} !important;
      }
    
      svg {
        height : 20px !important;
        width : 20px !important;
        margin-left: 6px;
        color: #9c9c9c !important;
      }
    }
  }

  .datePicker {
    .MuiTextField-root {
      margin-bottom: 0 !important;
    }
  }

  #lineal {
    text {
      font-size: 14px;
    }
  }

  .download-btn-report {
    background-color: ${palette.primary};
    color: ${palette.white};
    font-weight: 400;
    font-size: ${variants.small};
    border-radius: 8px;
    height: 38px;
    padding: 4px 8px;
    margin-right: 20px;
    cursor: pointer;
    line-height: 120%;
    text-transform: inherit !important;
    box-shadow: none;
    border: none;
    color: ${palette.white};

    &:hover: {
      background-color: ${palette.primary};
      color: ${palette.white};
    }
  }
`;

export default GlobalStyle;
