import { additionalDataBeforeAdd } from "../constant";
/**
 * It should remove a scenarie from list
 * @param idx: Number
 * @param list: Array
 */
export const removeScenarieByIdCreate = (idx, list) => {
  let tempArr = [];
  let currentScenaries = [];
  let load = 0;

  currentScenaries = list.filter((item, index) => index !== idx);

  if (currentScenaries.length === 3) {
    tempArr.push(
      {
        name: currentScenaries[0]?.name,
        start: 300,
        components: currentScenaries[0]?.components,
        content: currentScenaries[0]?.content,
        notifications: currentScenaries[0]?.notifications,
        scenarieId:
          currentScenaries[0]?.scenarieId && currentScenaries[0]?.scenarieId,
        end: currentScenaries[1]?.start - 1
      },
      {
        name: currentScenaries[1]?.name,
        start: currentScenaries[1].start,
        components: currentScenaries[1]?.components,
        content: currentScenaries[1]?.content,
        notifications: currentScenaries[1]?.notifications,
        scenarieId:
          currentScenaries[1]?.scenarieId && currentScenaries[1]?.scenarieId,
        end: currentScenaries[2]?.start - 1
      },
      {
        name: currentScenaries[2]?.name,
        start: currentScenaries[2].start,
        components: currentScenaries[2]?.components,
        content: currentScenaries[2]?.content,
        notifications: currentScenaries[2]?.notifications,
        scenarieId:
          currentScenaries[2]?.scenarieId && currentScenaries[2]?.scenarieId,
        end: 850
      }
    );
    load = 1;
  }

  if (currentScenaries.length === 4) {
    tempArr.push(
      {
        name: currentScenaries[0]?.name,
        start: 300,
        components: currentScenaries[0]?.components,
        content: currentScenaries[0]?.content,
        notifications: currentScenaries[0]?.notifications,
        scenarieId:
          currentScenaries[0]?.scenarieId && currentScenaries[0]?.scenarieId,
        end: currentScenaries[1]?.start - 1
      },
      {
        name: currentScenaries[1]?.name,
        start: currentScenaries[1].start,
        components: currentScenaries[1]?.components,
        content: currentScenaries[1]?.content,
        notifications: currentScenaries[1]?.notifications,
        scenarieId:
          currentScenaries[1]?.scenarieId && currentScenaries[1]?.scenarieId,
        end: currentScenaries[2]?.start - 1
      },
      {
        name: currentScenaries[2]?.name,
        start: currentScenaries[2].start,
        components: currentScenaries[2]?.components,
        content: currentScenaries[2]?.content,
        notifications: currentScenaries[2]?.notifications,
        scenarieId:
          currentScenaries[2]?.scenarieId && currentScenaries[2]?.scenarieId,
        end: currentScenaries[3]?.start - 1
      },
      {
        name: currentScenaries[3]?.name,
        start: currentScenaries[3].start,
        components: currentScenaries[3]?.components,
        content: currentScenaries[3]?.content,
        notifications: currentScenaries[3]?.notifications,
        scenarieId:
          currentScenaries[3]?.scenarieId && currentScenaries[3]?.scenarieId,
        end: 850
      }
    );

    load = 2;
  }

  return { tempArr, load };
};

/**
 * It should remove a scenarie from list
 * @param idx: Number
 * @param list: Array
 */
export const removeScenarieById = (idx, list) => {
  let tempArr = [];
  let currentScenaries = [];
  let load = 0;

  currentScenaries = list.filter((item) => item.scenarieId !== idx);

  if (currentScenaries.length === 3) {
    tempArr.push(
      {
        name: currentScenaries[0]?.name,
        start: 300,
        components: currentScenaries[0]?.components,
        content: currentScenaries[0]?.content,
        notifications: currentScenaries[0]?.notifications,
        scenarieId:
          currentScenaries[0]?.scenarieId && currentScenaries[0]?.scenarieId,
        end: currentScenaries[1]?.start - 1
      },
      {
        name: currentScenaries[1]?.name,
        start: currentScenaries[1].start,
        components: currentScenaries[1]?.components,
        content: currentScenaries[1]?.content,
        notifications: currentScenaries[1]?.notifications,
        scenarieId:
          currentScenaries[1]?.scenarieId && currentScenaries[1]?.scenarieId,
        end: currentScenaries[2]?.start - 1
      },
      {
        name: currentScenaries[2]?.name,
        start: currentScenaries[2].start,
        components: currentScenaries[2]?.components,
        content: currentScenaries[2]?.content,
        notifications: currentScenaries[2]?.notifications,
        scenarieId:
          currentScenaries[2]?.scenarieId && currentScenaries[2]?.scenarieId,
        end: 850
      }
    );
  }

  if (currentScenaries.length === 4) {
    tempArr.push(
      {
        name: currentScenaries[0]?.name,
        start: 300,
        components: currentScenaries[0]?.components,
        content: currentScenaries[0]?.content,
        notifications: currentScenaries[0]?.notifications,
        scenarieId:
          currentScenaries[0]?.scenarieId && currentScenaries[0]?.scenarieId,
        end: currentScenaries[1]?.start - 1
      },
      {
        name: currentScenaries[1]?.name,
        start: currentScenaries[1].start,
        components: currentScenaries[1]?.components,
        content: currentScenaries[1]?.content,
        notifications: currentScenaries[1]?.notifications,
        scenarieId:
          currentScenaries[1]?.scenarieId && currentScenaries[1]?.scenarieId,
        end: currentScenaries[2]?.start - 1
      },
      {
        name: currentScenaries[2]?.name,
        start: currentScenaries[2].start,
        components: currentScenaries[2]?.components,
        content: currentScenaries[2]?.content,
        notifications: currentScenaries[2]?.notifications,
        scenarieId:
          currentScenaries[2]?.scenarieId && currentScenaries[2]?.scenarieId,
        end: currentScenaries[3]?.start - 1
      },
      {
        name: currentScenaries[3]?.name,
        start: currentScenaries[3].start,
        components: currentScenaries[3]?.components,
        content: currentScenaries[3]?.content,
        notifications: currentScenaries[3]?.notifications,
        scenarieId:
          currentScenaries[3]?.scenarieId && currentScenaries[3]?.scenarieId,
        end: 850
      }
    );
  }

  return { tempArr, load };
};

/**
 * It should remove a scenarie from list
 * @param scenarie: Object
 * @param list: Array
 */
export const updateScenarieByIdx = (scenarie, list) => {
  const { idx } = scenarie;
  let tempArr = [];
  let currentScenaries = list;
  const elForm = document.getElementById(`0-${idx}`);
  const elPreview = document.getElementById(`01-${idx}`);

  if (currentScenaries[idx + 1]) {
    if (Number(scenarie?.end) >= currentScenaries[idx + 1].end - 3) {
      return {
        errorMessage:
          "Por favor verifique sus valores, Rango supera el limite del siguiente escenario"
      };
    }
  }

  if (Number(scenarie?.start) > Number(scenarie?.end)) {
    return {
      errorMessage:
        "Por favor verifique sus valores, Rango inicial supera el limite del final"
    };
  }

  if (Number(scenarie?.end) < Number(scenarie?.start)) {
    return {
      errorMessage:
        "Por favor verifique sus valores, Rango final supera el limite del inicial"
    };
  }

  for (let index = 0; index < currentScenaries.length; index++) {
    if (index === idx) {
      tempArr.push({
        name: scenarie?.name,
        start: Number(scenarie?.start),
        end: Number(scenarie?.end)
      });
      continue;
    } else if (index - (idx + 1) === 0) {
      tempArr.push({
        name: currentScenaries[index]?.name,
        start: Number(scenarie?.end) + 1,
        end: currentScenaries[index]?.end
      });
      continue;
    } else if (index === idx && idx === currentScenaries.length - 1) {
      tempArr.push(scenarie);
      continue;
    }

    tempArr.push(currentScenaries[index]);
  }

  elPreview.style.display = "block";
  elForm.style.display = "none";

  return {
    tempArr,
    errorMessage: null
  };
};

/**
 * It should add scenarie from list
 * @param scenarie: Object
 * @param list: Array
 */
export const addNewScenarieList = (scenarie, list) => {
  const { components, content, notifications } = additionalDataBeforeAdd;
  let tempArr = [];
  let currentScenaries = list;

  if (Number(scenarie?.start) < 300) {
    return {
      errorMessage:
        "Por favor verifique sus valores, rango inicial debe ser mayor a 300"
    };
  }

  if (Number(scenarie?.end) > 850) {
    return {
      errorMessage:
        "Por favor verifique sus valores, Rango final supera el limite final 850"
    };
  }

  if (Number(scenarie?.end) < Number(scenarie?.start)) {
    return {
      errorMessage:
        "Por favor verifique sus valores, Rango final es menor el limite del inicial"
    };
  }

  if (Number(scenarie?.end) - Number(scenarie?.start) < 3) {
    return {
      errorMessage: "Por favor verifique sus valores, Rango debe ser mayor a 3"
    };
  }

  const hasSameInitialRange = currentScenaries.find(
    (i) => Number(i?.start) === Number(scenarie?.start)
  );

  if (hasSameInitialRange) {
    return {
      errorMessage:
        "Por favor verifique sus valores, Rango inicial debe ser diferente a los establecidos"
    };
  }

  currentScenaries.push({ ...scenarie, isNew: true });
  currentScenaries.sort((a, b) => Number(a.start) - Number(b.start));

  for (let index = 0; index < currentScenaries.length; index++) {
    tempArr.push({
      scenarieId: currentScenaries[index]?.scenarieId && currentScenaries[index]?.scenarieId,
      name: currentScenaries[index]?.name,
      start: !currentScenaries[index - 1]?.isNew
        ? Number(currentScenaries[index]?.start)
        : Number(currentScenaries[index - 1]?.end) + 1,
      end: !currentScenaries[index]?.isNew
        ? index < currentScenaries.length - 1
          ? Number(currentScenaries[index + 1]?.start) - 1
          : Number(currentScenaries[index]?.end)
        : Number(scenarie?.end),
      components: currentScenaries[index]?.scenarieId
        ? currentScenaries[index]?.components
        : components,
      content: currentScenaries[index]?.scenarieId
        ? currentScenaries[index]?.content
        : content,
      notifications: currentScenaries[index]?.scenarieId
        ? currentScenaries[index]?.notifications
        : notifications
    });
  }

  return {
    tempArr,
    errorMessage: null
  };
};
