import React from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DescriptionIcon from "@mui/icons-material/Description";
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import MainViewStyle from "./customMainView.styled";
import Sidebar from "../sidebar";
import Heading from "../heading";
import SessionIdleTimeout from "../sessionIdleTimeout";

const customMainView = ({ children }) => {
  const routesLinkData = [
    {
      path: "/dashboard",
      exact: true,
      sidebar: "Dashboard",
      heading: "Dashboard",
      description:
        "Entérate de cómo interactuan las personas usuarias con tus páginas de información",
      icon: () => <DashboardIcon />,
      show: true
    },
    {
      path: "/template",
      sidebar: "Tus plantillas",
      heading: "Tus plantillas",
      description:
        "Crea, personaliza y administra tus plantillas para tus clientes",
      icon: () => <DescriptionIcon />,
      show: true
    },
    {
      path: "/template/:templateId",
      sidebar: "Tus plantillas",
      heading: "Tus plantillas",
      exact: true,
      description:
        "Crea, personaliza y administra tus plantillas para tus clientes",
      icon: () => <DescriptionIcon />,
      show: false
    },
    {
      path: "/development",
      sidebar: "Desarrollador",
      heading: "Modo Desarrollador",
      exact: true,
      description: "Conecta con nuestra API",
      icon: () => <DesktopMacIcon />,
      show: true
    }
  ];

  return (
    <>
      <SessionIdleTimeout />
      <MainViewStyle.Content>
        <Sidebar routesLink={routesLinkData} />
        <MainViewStyle.Main id="main-content">
          <Heading routesLink={routesLinkData} />
          {children}
        </MainViewStyle.Main>
      </MainViewStyle.Content>
    </>
  );
};

export default customMainView;
