import React, { useState, useEffect } from "react";
import TemplateStyles from "../../../components/createTemplate/createTemplate.styled";
import EditStyles from "./editTemplate.styled";
import Typography from "../../../components/common/typography";
import Divider from "@mui/material/Divider";
import { palette, variants } from "../../../theme/partials";
import ChevronRight from "../../../static/chevron-right.svg";
import editIcon from "../../../static/edit.svg";
import warning from "../../../static/warning.svg";
import Button from "@mui/material/Button";
import TemplateName from "../../../components/createTemplate/components/TemplateName";
import UploadImageComponent from "../../../components/createTemplate/components/UploadImage";
import CustomColorTemplate from "../../../components/createTemplate/components/ColorPickerTemplate";
import EditIcon from "../../../static/edit.svg";
import ModalComponent from "../../../components/common/modal/modal";
import EditScenariesTemplate from "./components/scenaries";
import { setItemSelectedEditMap } from "../../../helper/templateMappers";
import InputComponents from "../../../components/createTemplate/components/InputComponents";
import EditExpandedTemplate from "../../../components/createTemplate/components/sortExpandedComponents";
import CustomMainView from "../../../components/customMainView";
import AlertComponent from "../../../components/common/alert";
import { useParams } from "react-router-dom";
import {
  setEditDataTemplate,
  setCheckedNotifications,
  setEditTemplatePayload
} from "../../../helper/templateMappers";
import LoaderTemplate from "../../../components/skeleton/loaderTemplate";
import {
  getTemplateById,
  downloadImageByPath,
  updateTemplate,
  removeScenariesById
} from "../../../services/platform.service";
import { useNavigate } from "react-router-dom";
import {NAMES_REGEX, TITLE_REGEX} from "../../../helper/regex";

const EditTemplate = () => {
  const navigate = useNavigate();
  const { templateId } = useParams();
  const [customEdit, setCustomEdit] = useState({
    templateId,
    templateName: "",
    name: "",
    logo: "",
    color: {
      primary: "",
      secondary: ""
    },
    mainComponents: {
      score: "",
      points: "",
      history: "",
      notify: ""
    },
    scenaries: null,
    openComponentsModal: false,
    openScenariesModal: false,
    isLoading: false,
    indexRow: null,
    customComponentEdit: null,
    isPayloadEdit: {
      templateId
    },
    scenariesToRemove: null
  });

  const [showErrorMessage, setShowErrorMessage] = useState(null);

  /**
   * It should to cancel edit template
   * @param null
   */
  const isEditingCanceled = () => {
    redirectToTemplates();
  };

  /**
   * It should redirect to table templates
   * @param null
   */
  const redirectToTemplates = () => {
    navigate("/template");
  };

  /**
   * It should get scenaries edited
   * @param item: Object
   */
  const editComponent = (item) => {
    const itemSelected = setItemSelectedEditMap(
      item,
      customEdit?.mainComponents
    );

    setCustomEdit({
      ...customEdit,
      openComponentsModal: true,
      customComponentEdit: itemSelected
    });
  };

  /**
   * It should close component modal
   * @param null
   */
  const handleComponentsModal = () => {
    setCustomEdit({
      ...customEdit,
      openComponentsModal: false,
      customComponentEdit: null
    });
  };

  /**
   * It should save components modal and save
   * @param null
   */
  const updateDataScenaries = (data) => {
    const transformNotifications = setCheckedNotifications(data);
    const scenarieList = customEdit?.scenaries.map((i) => {
      return data.scenarieId === i.scenarieId
        ? {
            ...data,
            content: data?.content,
            notifications: transformNotifications
          }
        : i;
    });

    setCustomEdit({
      ...customEdit,
      scenaries: scenarieList,
      openComponentsModal: false,
      customComponentEdit: null,
      isPayloadEdit: {
        ...customEdit.isPayloadEdit,
        scenaries: scenarieList
      }
    });
  };

  /**
   * It should close scenarie modal
   * @param null
   */
  const handleScenariesModal = () => {
    setCustomEdit({
      ...customEdit,
      openScenariesModal: false,
      position: null
    });
  };

  /**
   * It should get scenarie selected
   * @param idx: Number
   * @param item: Object
   */
  const isScenarieSelected = (idx, item) => {
    setCustomEdit({
      ...customEdit,
      openScenariesModal: true,
      indexRow: idx,
      itemSelected: item
    });
  };

  /**
   * It should get main components title
   * @param e: Event
   * @param name: String
   */
  const setInputFieldComponents = (e, name) => {
    const value = e.target.value;
    const cleanValue = value.replace(TITLE_REGEX, '')
    setCustomEdit({
      ...customEdit,
      mainComponents: {
        ...customEdit.mainComponents,
        [name]: cleanValue
      },
      isPayloadEdit: {
        ...customEdit.isPayloadEdit,
        mainComponents: {
          ...customEdit.mainComponents,
          [name]: cleanValue
        }
      }
    });
  };

  /**
   * It should change appearance template
   * @param type: String
   * @param color: String
   */
  const onChangeColor = (type, color) => {
    setCustomEdit({
      ...customEdit,
      color: {
        ...customEdit.color,
        [type]: color
      },
      isPayloadEdit: {
        ...customEdit.isPayloadEdit,
        color: {
          ...customEdit.color,
          [type]: color
        }
      }
    });
  };

  /**
   * It should get path image
   * @param data: Object
   */
  const updateImageForTemplateInfo = (data) => {
    setCustomEdit({
      ...customEdit,
      logo: data?.logo,
      isPayloadEdit: {
        ...customEdit.isPayloadEdit,
        logo: data?.logo
      }
    });
  };

  /**
   * It should get template name
   * @param e: Event
   */
  const setNewTemplateName = (e) => {
    const value= e.target.value
    const cleanValue = value.replace(NAMES_REGEX, '')

    setCustomEdit({
      ...customEdit,
      name: cleanValue,
      isPayloadEdit: {
        ...customEdit.isPayloadEdit,
        name: cleanValue
      }
    });
  };

  /**
   * It should set data params
   * @param itemSelected: Object
   */
  const setCustomValues = async (itemSelected) => {
    try {
      const response = await downloadImageByPath(itemSelected?.logo);
      if (response?.data) {
        setCustomEdit({
          ...customEdit,
          isLoading: false,
          templateName: itemSelected?.name,
          name: itemSelected?.name,
          logo: response?.data?.archivo,
          color: {
            primary: itemSelected?.color?.primary,
            secondary: itemSelected?.color?.secondary
          },
          mainComponents: {
            score: itemSelected?.components[0].titulo,
            points: itemSelected?.components[1].titulo,
            history: itemSelected?.components[2].titulo,
            notify: itemSelected?.components[3].titulo
          },
          scenaries: itemSelected?.scenaries.map((it) => {
            return {
              components: it?.componentes.map((ox) => {
                return {
                  type: ox?.tipo,
                  sort: ox?.orden
                };
              }),
              content: it?.contenido,
              end: it?.hasta,
              start: it?.desde,
              name: it?.nombre,
              notifications: it?.notificaciones,
              scenarieId: it?.idEscenario
            };
          })
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * It should remove template by Id
   * @param scenarieId: String
   */
  const removeTemplateByScenarieId = async (id) => {
    try {
      await removeScenariesById(id);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * It should iterate to remove template selected
   * @param list: Array
   */
  const removeAllTemplateSelected = (list) => {
    for (const id of list) {
      removeTemplateByScenarieId(id);
    }
  };

  /**
   * It should get payload before save changes
   * @param null
   */
  const setPayloadTemplateUpdated = async () => {
    const payload = setEditTemplatePayload(customEdit?.isPayloadEdit);
    handleTemplateUpdated(payload);
  };

  /**
   * It should update template changes
   * @param payload: Object
   */
  const handleTemplateUpdated = async (payload) => {
    try {
      if (customEdit?.scenariesToRemove)
        await removeAllTemplateSelected(customEdit?.scenariesToRemove);

      await updateTemplate(payload);
      await redirectToTemplates();
    } catch (error) {
      console.log(error);
      if (error.response.data.detalles.length > 0) {
        setShowErrorMessage(error.response.data.detalles[0]);
      }
    }
  };

  /**
   * It should validate data Async method to render
   * @param data: Object
   */
  const validateData = (data) => {
    if (!data) return false;
    return data;
  };

  /**
   * It should finish scenarie edition
   * @param data: Object
   */
  const onFinishEditScenarie = async (data) => {
    if (data) {
      const rest = await validateData(data);

      setCustomEdit({
        ...customEdit,
        scenaries: [...rest?.scenariesUpdated],
        openScenariesModal: false,
        isPayloadEdit: {
          ...customEdit.isPayloadEdit,
          scenaries: [...rest?.scenariesUpdated]
        },
        scenariesToRemove: [...rest?.scenariesRemoved]
      });
    }
  };

  /**
   * It should get template info by ID
   * @param null
   */
  const getTemplateDataById = async () => {
    try {
      const response = await getTemplateById(templateId);
      if (response?.data) {
        const itemSelected = setEditDataTemplate(response?.data);
        setCustomValues(itemSelected);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (templateId) {
      setCustomEdit({
        ...customEdit,
        isLoading: true
      });
      getTemplateDataById();
    }
  }, []);

  return (
    <CustomMainView>
      <div style={{ width: "100%" }}>
        {customEdit?.isLoading ? (
          <LoaderTemplate />
        ) : (
          <>
            <EditStyles.Header>
              <TemplateStyles.breadcrumb className="breadcrumb">
                <Typography
                  text={"Editar plantilla"}
                  variant={variants.small}
                  color={palette.neutral}
                  asTag={"a"}
                  hasDecoration
                />
                <img src={`${ChevronRight}`} alt={"icon"} loading="lazy" />
                <Typography
                  text={customEdit?.templateName}
                  variant={variants.small}
                  color={palette.primary}
                  asTag={"a"}
                  hasDecoration
                />
              </TemplateStyles.breadcrumb>

              <EditStyles.Name>
                <Typography
                  text={customEdit?.templateName}
                  variant={variants.regular}
                  color={palette.secondary}
                  asTag={"h3"}
                  hasDecoration
                />
              </EditStyles.Name>
            </EditStyles.Header>

            <EditStyles.Wrapper>
              <EditStyles.InputName>
                <Typography
                  text={"Nombre de la plantilla"}
                  variant={variants.small}
                  color={palette.secondary}
                  asTag={"h6"}
                />
                <TemplateStyles.FieldWrapper>
                  <TemplateName
                    inputName={"Nombre de la plantilla"}
                    inputPlaceholder={"Nombre de la plantilla"}
                    onChangeInputName={setNewTemplateName}
                    currentValue={customEdit?.name}
                    errorMessage={""}
                  />
                </TemplateStyles.FieldWrapper>
              </EditStyles.InputName>

              <Divider />

              <EditStyles.Colors>
                <Typography
                  text={"Personalización de Apariencia"}
                  variant={variants.small}
                  color={palette.secondary}
                  asTag={"h6"}
                />

                <EditStyles.RowPerColumns>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h5 className="heading-section">Logo</h5>

                    <TemplateStyles.UploadImage style={{ marginRight: "80px" }}>
                      <UploadImageComponent
                        updateImageForTemplateInfo={updateImageForTemplateInfo}
                        sourceFile={customEdit?.logo}
                      />
                    </TemplateStyles.UploadImage>
                  </div>

                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <h5 className="heading-section">Colores</h5>

                    <div>
                      <CustomColorTemplate
                        colors={customEdit?.color}
                        onChangeColor={onChangeColor}
                      />
                    </div>
                  </div>
                </EditStyles.RowPerColumns>
              </EditStyles.Colors>

              <Divider />

              <EditStyles.wrapperScenaries>
                <EditStyles.RowPerColumns className="full-width">
                  <TemplateStyles.PreviewtWrapper
                    style={{ width: "40%", paddingRight: "30px" }}
                  >
                    <Typography
                      text={"Definición de escenarios"}
                      variant={variants.small}
                      color={palette.secondary}
                      asTag={"h6"}
                    />
                    {customEdit.scenaries &&
                      customEdit.scenaries.map((item, index) => {
                        return (
                          <TemplateStyles.Steps
                            id={index}
                            className="preview-content"
                            key={`${index}-${item.name}`}
                            onMouseOver={() =>
                              setCustomEdit({
                                ...customEdit,
                                indexRow: index
                              })
                            }
                            onMouseOut={() => {
                              setCustomEdit({
                                ...customEdit,
                                indexRow: null
                              });
                            }}
                          >
                            <div id={`01-${index}`}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "flex-start",
                                  alignItems: "center"
                                }}
                              >
                                <Typography
                                  text={item.name}
                                  variant={variants.normal}
                                  color={palette.neutral30}
                                  asTag={"h5"}
                                />
                                {customEdit?.indexRow === index && (
                                  <span
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                      alignItems: "center"
                                    }}
                                  >
                                  </span>
                                )}
                              </div>
                              <Typography
                                text={`Puntaje desde ${item.start} hasta ${item.end}`}
                                variant={variants.normal}
                                color={palette.neutral30}
                                asTag={"p"}
                              />
                            </div>
                          </TemplateStyles.Steps>
                        );
                      })}
                  </TemplateStyles.PreviewtWrapper>

                  <EditStyles.Fields>
                    {customEdit?.mainComponents && (
                      <InputComponents
                        sourceData={customEdit?.mainComponents}
                        setInputFieldComponents={setInputFieldComponents}
                      />
                    )}
                  </EditStyles.Fields>
                </EditStyles.RowPerColumns>
              </EditStyles.wrapperScenaries>

              <Divider />

              <EditStyles.Colors>
                <Typography
                  text={"Customización de escenarios"}
                  variant={variants.small}
                  color={palette.secondary}
                  asTag={"h6"}
                />

                <EditStyles.RowPerColumns>
                  <TemplateStyles.GridRangesEdit className="edit">
                    {customEdit.scenaries &&
                      customEdit.scenaries.map((item, index) => {
                        return (
                          <TemplateStyles.Range
                            className="edit"
                            key={`${index}-${item.name}`}
                          >
                            <div className="head">
                              <Typography
                                text={item.name}
                                variant={variants.normal}
                                color={palette.neutral20}
                                asTag={"h5"}
                              />
                              <img
                                className="options"
                                onClick={() => editComponent(item)}
                                src={`${editIcon}`}
                                alt={"icon"}
                                loading="lazy"
                              />
                            </div>
                            <Typography
                              text={`${item.start} - ${item.end}`}
                              variant={variants.normal}
                              color={palette.neutral30}
                              asTag={"p"}
                            />
                          </TemplateStyles.Range>
                        );
                      })}
                  </TemplateStyles.GridRangesEdit>
                </EditStyles.RowPerColumns>
              </EditStyles.Colors>

              <Divider />

              <TemplateStyles.ButtonWrapper className="row">
                <Button
                  variant={"contained"}
                  disabled={false}
                  type="button"
                  onClick={() => setPayloadTemplateUpdated()}
                >
                  {"Guardar Cambios"}
                </Button>

                <span
                  style={{ marginTop: "0" }}
                  onClick={() => isEditingCanceled()}
                >
                  <Typography
                    text={"Salir"}
                    variant={variants.small}
                    color={palette.neutral20}
                    asTag={"a"}
                    hasDecoration
                  ></Typography>
                </span>
              </TemplateStyles.ButtonWrapper>
            </EditStyles.Wrapper>

            <ModalComponent
              isOpen={customEdit?.openScenariesModal}
              handleCloseModal={handleScenariesModal}
              styleName={{
                padding: "40px 60px",
                margin: "30px 0px",
                overflowY: "scroll",
                height: "90vh"
              }}
            >
              <EditScenariesTemplate
                scenarieList={customEdit?.scenaries}
                scenarieSelected={customEdit?.itemSelected}
                handleScenariesModal={handleScenariesModal}
                onFinishEditScenarie={onFinishEditScenarie}
              />
            </ModalComponent>

            <ModalComponent
              isOpen={customEdit?.openComponentsModal}
              handleCloseModal={handleComponentsModal}
              styleName={{ padding: "40px 60px" }}
            >
              <div style={{ textAlign: "center", marginBottom: "40px" }}>
                <Typography
                  text={`Editar Plantilla`}
                  variant={variants.large}
                  color={palette.secondary}
                  asTag={"h2"}
                />
              </div>

              <EditExpandedTemplate
                templateName={customEdit?.name}
                customEdit={
                  customEdit?.customComponentEdit
                    ? customEdit?.customComponentEdit
                    : customEdit?.scenaries
                }
                updateDataScenaries={updateDataScenaries}
                handleComponentsModal={handleComponentsModal}
              />
            </ModalComponent>
          </>
        )}
        {/* ALLOW SHOW TENPLATE REMOVED AFTER CONFIRMATION - MODAL */}
        <ModalComponent
          isOpen={showErrorMessage}
          handleCloseModal={() => setShowErrorMessage(null)}
        >
          <AlertComponent
            icon={warning}
            title={"Error de edición"}
            description={showErrorMessage}
          />
        </ModalComponent>
        {/* END ALLOW SHOW TENPLATE REMOVED AFTER CONFIRMATION - MODAL */}
      </div>
    </CustomMainView>
  );
};
export default EditTemplate;
