import React, { useState, useEffect } from "react";
import Chart from "react-google-charts";
import { palette, variants } from "../../../theme/partials";
import SelectComponent from "../../../components/common/select";
import Typography from "../../../components/common/typography";
import LoaderMap from "../../skeleton/loaderMap";
import {
  SCORE_RANGES,
  GENERATION_TYPES,
  SCORE_RANGES_MATCH
} from "../../../constant";
import { getGrachicMapData } from "../../../services/dashboard.service";
import { setGraphicMapData } from "../../../helper/dashboardMapper";

const customOptions = {
  displayMode: "regions",
  region: "MX",
  resolution: "provinces",
  colorAxis: {
    colors: ["#90909A", "#DDE1FF", "#94A6FF", "#5A73C4", "#293E80", "#00164D"]
  },
  backgroundColor: "#F6F8FF",
  datalessRegionColor: "#F6F8FF",
  defaultColor: "#f5f5f5",

  maintainAspectRatio: false
};

const scenaries = [
  {
    text: "722 a 850",
    color: palette.secondaryContainer,
    id: 1
  },
  {
    text: "664 a 721",
    color: "#5A73C4",
    id: 2
  },
  {
    text: "590 a 663",
    color: "#94A6FF",
    id: 3
  },
  {
    text: "300 a 589",
    color: "#DDE1FF",
    id: 4
  },
  {
    text: "No hit",
    color: "#90909A",
    id: 5
  }
];

const GraphicGeo = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [countryData, setCountryData] = useState(null);
  const [status, setStatus] = useState(SCORE_RANGES[1]?.value);
  const [genre, setGenre] = useState(GENERATION_TYPES[1]?.value);

  const onSelectedOption = (e, type) => {
    const value = e?.target?.value;

    if (type === "status") {
      const isType = SCORE_RANGES.filter((it) => it.value === value)[0]?.value;
      setStatus(isType);
    }

    if (type === "genre") {
      const isGeneration = GENERATION_TYPES.filter(
        (it) => it.value === value
      )[0]?.value;

      setGenre(isGeneration);
    }
  };

  const getData = async () => {
    setIsLoading(true);
    try {
      const scoreType = SCORE_RANGES.filter((i) => i.value === status);
      const generation = GENERATION_TYPES.filter((i) => i.value === genre);

      const payload = {
        puntaje: scoreType[0]?.type,
        generacion: generation[0]?.type
      };

      const result = await getGrachicMapData(payload);

      if (result?.data) {
        const dataFiltered = setGraphicMapData(result?.data);
        let gmapData = [];

        if (dataFiltered) {
          gmapData.push(["States", "Estado", "Usuarios"]);
          for (const key in dataFiltered) {
            if (dataFiltered[key].isAvailable) {
              gmapData.push([
                dataFiltered[key].id,
                `${dataFiltered[key].entity}: ${dataFiltered[key].percent}%`,
                dataFiltered[key].users
              ]);
            }
          }
        }
        setCountryData([...gmapData]);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, [status, genre]);

  return (
    <div className="">
      <div style={{ padding: "10px" }}>
        <div
          style={{
            margin: "6px 0 16px 0",
            textAlign: "left",
            paddingLeft: " 10px",
          }}
        >
          <Typography
            text={"Heatmap de scores a nivel estatal"}
            variant={variants.small}
            color={palette.secondary}
            asTag={"h4"}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            margin: "10px 0 16px 0"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <span
              style={{ margin: "0 10px", width: "130px", textAlign: "center" }}
            >
              <Typography
                text={"Ver por:"}
                variant={variants.extraSmall}
                color={palette.black}
                asTag={"h6"}
              />
            </span>
            <SelectComponent
              currentValue={status}
              id={"status"}
              label={"Score promedio"}
              source={SCORE_RANGES_MATCH}
              onSelectedOption={onSelectedOption}
              isDisabled={isLoading}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <span
              style={{ margin: "0 10px", width: "130px", textAlign: "center" }}
            >
              <Typography
                text={"Filtrar Por:"}
                variant={variants.extraSmall}
                color={palette.black}
                asTag={"h6"}
              />
            </span>
            <SelectComponent
              currentValue={genre}
              id={"genre"}
              label={"Generación"}
              source={GENERATION_TYPES.map((it) => it.value)}
              onSelectedOption={onSelectedOption}
              isDisabled={isLoading}
            />
          </div>
        </div>

        <>
          {!isLoading ? (
            <div className="map-content">
              <div>
                {countryData && countryData.length > 0 && (
                  <Chart
                    chartType="GeoChart"
                    data={countryData}
                    width="90%"
                    height="270px"
                    options={customOptions}
                  />
                )}
              </div>
              <div style={{ padding: "10px 16px" }}>
                <Typography
                  text={"Score promedio"}
                  variant={variants.small}
                  color={palette.secondary}
                  asTag={"h6"}
                />
                <div
                  style={{
                    marginTop: "14px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    width: "100%"
                  }}
                >
                  {scenaries &&
                    scenaries.map((it) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                          width: "100%",
                          marginBottom: "10px",
                          textAlign: 'left'
                        }}
                        key={it.id}
                      >
                        <div
                          style={{
                            width: "16px",
                            height: "16px",
                            background: it.color,
                            marginRight: "8px"
                          }}
                        ></div>
                        <Typography
                          text={it.text}
                          variant={variants.small}
                          color={palette.secondary}
                          asTag={"p"}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            <LoaderMap show={isLoading} />
          )}
        </>
      </div>
    </div>
  );
};

export default React.memo(GraphicGeo);
