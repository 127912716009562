import React, { useCallback, useRef } from "react";
import ReactToPrint from "react-to-print";
import { palette } from "../../theme/partials";
import { ComponentToPrint } from "./documentToPrint";

const ToPrintPage = () => {
  const componentRef = useRef(null);

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const reactToPrintTrigger = useCallback(() => {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          backgroundColor: palette.white,
          padding: '0 20px',
          marginBottom: '20px'
        }}
      >
        <button className="download-btn-report">Descargar Reporte</button>
      </div>
    );
  }, []);

  return (
    <>
      <ReactToPrint
        content={reactToPrintContent}
        documentTitle="Reporte"
        trigger={reactToPrintTrigger}
      />
      <ComponentToPrint ref={componentRef} />
    </>
  );
};

export default ToPrintPage;
