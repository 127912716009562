import React from "react";
import moment from "moment";
import CheckIcon from "../../../static/check.svg";
import { ReactComponent as CopyIcon } from "../../../static/copy.svg";
import { ReactComponent as PreviewIcon } from "../../../static/preview.svg";
import { ReactComponent as EditIcon } from "../../../static/edit.svg";
import { ReactComponent as DeleteIcon } from "../../../static/delete.svg";

const isCompleteTemplate = (template) => {
  return template.etapa === 'PLANTILLA_COMPLETA';
}

const isPublishedTemplate = (template) => {
  return template.estado === 'PUBLICADA';
}

const TableBody = ({ body, optionSelected, show }) => {

  const getStatus = (status) => {
    if (status === "NO_PUBLICADA") {
      return "No publicada";
    }

    if (status === "BORRADOR") {
      return "Borrador";
    }

    if (status === "PUBLICADA") {
      return "Publicada";
    }

    return status;
  };

  return (
    <tbody>
      {show &&
        body.map((item, idx) => {
          return (
            <tr
              key={`${item.idPlantilla}-${idx}`}
              className="rows"
            >
              <td
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center"
                }}
              >
                {item.nombre}
              </td>
              <td
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center"
                }}
              >
                {moment(item.fechaModificacion).format("DD/MM/YYYY")}
              </td>
              <td
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  textTransform: "capitalize"
                }}
                className="last"
              >
                {getStatus(item.estado)}
                <>
                  {item.estado === "PUBLICADA" && (
                    <img className="icons--check" src={CheckIcon} />
                  )}
                </>
              </td>
              <td style={{ height: "30px" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <p
                    className={`linked ${(isPublishedTemplate(item) || !isCompleteTemplate(item)) ? 'linked--disabled' : ''}`}
                    disabled={isPublishedTemplate(item) || !isCompleteTemplate(item)}
                    onClick={(isPublishedTemplate(item) || !isCompleteTemplate(item))
                      ? undefined
                      : () => optionSelected(item, "publish")
                    }
                  >
                    Publicar
                  </p>
                  <div
                    className={`icons ${isCompleteTemplate(item) ? 'icons--blue' : 'icons--disabled'}`}
                    onClick={isCompleteTemplate(item)
                      ? () => optionSelected(item, "show")
                      : undefined
                    }
                  >
                    <PreviewIcon />
                  </div>
                  <div
                    className="icons icons--darkblue"
                    onClick={() => optionSelected(item, "copy")}
                  >
                    <CopyIcon />
                  </div>
                  <div
                    className="icons icons--darkblue"
                    onClick={() => optionSelected(item, "edit")}
                  >
                    <EditIcon />
                  </div>
                  <div
                    className="icons icons--red"
                    onClick={() => optionSelected(item, "remove")}
                  >
                    <DeleteIcon />
                  </div>
                </div>
              </td>
            </tr>
          );
        })}
    </tbody>
  );
};

export default TableBody;
