import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Typography from "../../common/typography";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";
import ErrorIcon from "@mui/icons-material/Error";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import changePasswordStyles from "./changePassword.styled";
import { palette, variants } from "../../../theme/partials";

const validationSchema = Yup.object({
  password: Yup.string("Ingresa tu contraseña")
    .min(8, "Contraseña debe tener mínimo 8 caracteres")
    .required("Contraseña es requerida"),
  passwordConfirmation: Yup.string()
    .oneOf([""], "Contraseñas no coinciden")
    .required("Confirmar contraseña es requerida"),
});

const ChangePasswordForm = ({ title, description }) => {
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  return (
    <changePasswordStyles.FormContent onSubmit={formik.handleSubmit}>
      <changePasswordStyles.HeadingWrapper>
        <>
          {title && (
            <Typography
              text={title}
              variant={variants.large}
              color={palette.secondary}
              asTag={"h3"}
            />
          )}
        </>
        <>
          {description && (
            <Typography
              text={description}
              variant={variants.normal}
              color={palette.neutral30}
              asTag={"p"}
            />
          )}
        </>
      </changePasswordStyles.HeadingWrapper>

      <changePasswordStyles.FieldWrapper>
        <FormControl
          variant="outlined"
          className={
            formik.touched.password && formik.errors.password && "text-error"
          }
        >
          <InputLabel htmlFor="outlined-adornment-password">
            Contraseña
          </InputLabel>
          <OutlinedInput
            id={"password"}
            name={"password"}
            label={"password"}
            type={"password"}
            endAdornment={
              <>
                {formik.errors.password &&
                  formik.touched.password &&
                  Boolean(formik.errors.password) && (
                    <InputAdornment position="end">
                      <IconButton aria-label="alert" edge="end">
                        <ErrorIcon />
                      </IconButton>
                    </InputAdornment>
                  )}
              </>
            }
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            placeholder={"Ingresa tu nueva contraseña"}
            onBlur={formik.handleBlur}
            required
          />
          {formik.errors.password && (
            <div id="help-error">{formik.errors.password}</div>
          )}
        </FormControl>
      </changePasswordStyles.FieldWrapper>

      <changePasswordStyles.FieldWrapper>
        <FormControl
          variant="outlined"
          className={
            formik.touched.confirmPassword &&
            formik.errors.confirmPassword &&
            "text-error"
          }
        >
          <InputLabel htmlFor="outlined-adornment-password">
            Confirmar contraseña
          </InputLabel>
          <OutlinedInput
            id={"confirmPassword"}
            name={"confirmPassword"}
            label={"confirmPassword"}
            type={"text"}
            endAdornment={
              <>
                {!formik.values.confirmPassword &&
                  formik.touched.confirmPassword &&
                  formik.errors.confirmPassword && (
                    <InputAdornment position="end">
                      <IconButton aria-label="alert" edge="end">
                        <ErrorIcon />
                      </IconButton>
                    </InputAdornment>
                  )}
              </>
            }
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
            placeholder={"Confirma tu contraseña"}
            onBlur={formik.handleBlur}
            required
          />
          {formik.errors.confirmPassword && (
            <div id="help-error">{formik.errors.confirmPassword}</div>
          )}
        </FormControl>
      </changePasswordStyles.FieldWrapper>

      <changePasswordStyles.Rules>
        <Typography
          text={"Tu contraseña debe tener al menos:"}
          variant={variants.small}
          color={palette.neutral30}
          asTag={"p"}
        />

        <changePasswordStyles.Item>
          <ErrorIcon />
          <Typography
            text={"8 caracteres"}
            variant={variants.small}
            color={palette.neutral30}
            asTag={"p"}
          />
        </changePasswordStyles.Item>

        <changePasswordStyles.Item>
          <ErrorIcon />
          <Typography
            text={"1 digito"}
            variant={variants.small}
            color={palette.neutral30}
            asTag={"p"}
          />
        </changePasswordStyles.Item>

        <changePasswordStyles.Item>
          <ErrorIcon className={'completed'} />
          <Typography
            text={"1 carácter especial"}
            variant={variants.small}
            color={palette.neutral30}
            asTag={"p"}
          />
        </changePasswordStyles.Item>

        <changePasswordStyles.Item>
          <ErrorIcon />
          <Typography
            text={"1 mayúscula"}
            variant={variants.small}
            color={palette.neutral30}
            asTag={"p"}
          />
        </changePasswordStyles.Item>
      </changePasswordStyles.Rules>

      <changePasswordStyles.ButtonWrapper>
        <Button variant={"contained"} disabled={!formik.isValid} type="submit">
          Crear contraseña
        </Button>
      </changePasswordStyles.ButtonWrapper>
    </changePasswordStyles.FormContent>
  );
};

export default ChangePasswordForm;
