import { axiosPrivate } from "../services/axiosInstance";
import { environment } from "../constant";

const sectionPrefix = "template";
const mediaPrefix = "file";
const stagePrefix = "stage";

/**
 * It should get templatee
 * @param null
 */
export const getTemplates = () => {
  return axiosPrivate.get(
    `${environment.path_prefix.platform}/${sectionPrefix}`
  );
};

/**
 * It should show a preview template
 * @param null
 */
export const getPreviewTemplate = (templateId, score) => {
  return axiosPrivate.get(
    `${environment.path_prefix.platform}/${sectionPrefix}/preview/${templateId}/${score}`
  );
};

/**
 * It should create template
 * @param payload: Object
 */
export const createNewTemplate = (payload) => {
  return axiosPrivate.post(
    `${environment.path_prefix.platform}/${sectionPrefix}/name`,
    payload
  );
};

/**
 * It should update template image
 * @param payload: Object
 */
export const uploadTemplateImage = (payload) => {
  return axiosPrivate.post(
    `${environment.path_prefix.platform}/${mediaPrefix}/upload`,
    payload
  );
};

/**
 * It should add template appearance
 * @param payload: Object
 */
export const addTemplateAppearance = (payload) => {
  return axiosPrivate.post(
    `${environment.path_prefix.platform}/${sectionPrefix}/appearance`,
    payload
  );
};

/**
 * It should add template stage
 * @param payload: Object
 */
export const addTemplateStage = (payload) => {
  return axiosPrivate.post(
    `${environment.path_prefix.platform}/${sectionPrefix}/${stagePrefix}`,
    payload
  );
};

/**
 * It should update template stage settings
 * @param payload: Object
 */
export const stageSettings = (payload) => {
  return axiosPrivate.patch(
    `${environment.path_prefix.platform}/${sectionPrefix}/${stagePrefix}/setting`,
    payload
  );
};

/**
 * It should remove template by template Id
 * @param templateId: String
 */
export const removeTemplate = (templateId) => {
  return axiosPrivate.delete(
    `${environment.path_prefix.platform}/${sectionPrefix}/${templateId}`
  );
};

/**
 * It should remove template by template Id
 * @param id: String
 */
export const removeScenariesById = (id) => {
  return axiosPrivate.delete(`${environment.path_prefix.platform}/${sectionPrefix}/stage/setting/${id}`
  );
};

/**
 * It should get template by Id
 * @param templateId: String
 */
export const getTemplateById = (templateId) => {
  return axiosPrivate.get(
    `${environment.path_prefix.platform}/${sectionPrefix}/${templateId}`
  );
};

/**
 * It should download image by template id
 * @param templateId: String
 */
export const downloadImageByPath = (templateId) => {
  return axiosPrivate.post(
    `${environment.path_prefix.platform}/${mediaPrefix}/download`,
    {
      idArchivo: `${templateId}`,
    }
  );
};

/**
 * It should update template after edit
 * @param payload: Object
 */
export const updateTemplate = (payload) => {
  return axiosPrivate.put(
    `${environment.path_prefix.platform}/${sectionPrefix}`,
    payload
  );
};

/**
 * I should publish a new template
 * @param {String} templateId 
 */
export const publishTemplate = (templateId) => {
  return axiosPrivate.patch(
    `${environment.path_prefix.platform}/${sectionPrefix}/publish`,
    {
      idPlantilla: templateId
    }
  );
};
