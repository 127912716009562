import { createTheme } from "@mui/material";
import { palette, variants, fontFamily } from "./partials";

export const muiTheme = {
  palette: {
    black: {
      main: palette.black,
    },
    error: {
      main: palette.error,
    },
    errorContainer: {
      main: palette.errorContainer,
    },
    neutral: {
      main: palette.neutral,
    },
    neutral20: {
      main: palette.neutral20,
    },
    neutral30: {
      main: palette.neutral30,
    },
    neutral95: {
      main: palette.neutral95,
    },
    neutral100: {
      main: palette.neutral100,
    },
    onSecondary: {
      main: palette.onSecondary,
    },
    onSecondaryContainer: {
      main: palette.onSecondaryContainer,
    },
    onSecondaryOpacity: {
      main: palette.onSecondaryOpacity,
    },
    secondary: {
      main: palette.secondary,
    },
    secondaryContainer: {
      main: palette.secondaryContainer,
    },
    tertiary: {
      main: palette.tertiary,
    },
    tertiaryContainer: {
      main: palette.tertiaryContainer,
    },
    white: {
      main: palette.white,
    },
  },
  typography: {
    poppins: fontFamily.Poppins,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        ".MuiBox-root": {},
        ".Mui-tab-content": {
          ".MuiBox-root": {
            padding: "12px",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          color: palette.neutral30,
          marginBottom: "30px",
          width: "100%",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: palette.primary,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: palette.neutral30,
          fontWeight: "300"
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: palette.primary,
          borderRadius: "4px",
          "&.Mui-checked": {
            color: palette.primary,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: palette.neutral20,
          fontWeight: "400",
          fontSize: "12px",
          lineHeight: "120%",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "1em",
        },
      },
      variants: [
        {
          props: { variant: "text" },
          style: {
            color: palette.primary,
            fontFamily: fontFamily.poppins,
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: variants.small,
            lineHeight: "120%",
            textTransform: "capitalize !important",
            textDecoration: "underline",
            "&:hover": {
              color: palette.primary,
              textDecoration: "underline",
            },
          },
        },
        {
          props: { variant: "contained" },
          style: {
            backgroundColor: palette.primary,
            color: palette.white,
            fontFamily: fontFamily.poppins,
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: variants.small,
            borderRadius: "8px",
            height: "38px",
            lineHeight: "120%",
            textTransform: "inherit !important",
            "&:hover": {
              backgroundColor: palette.primary,
              color: palette.white,
            },
            "&.Mui-disabled": {
              backgroundColor: palette.primary,
              boxShadow: "none",
              color: palette.white,
              opacity: "0.4",
            },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            color: palette.primary,
            fontFamily: fontFamily.poppins,
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: variants.small,
            lineHeight: "120%",
            textTransform: "capitalize !important",
            "&:hover": {
              color: palette.primary,
            },
          },
        },
      ],
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "32px !important",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginRight: "5px",
          marginLeft: "-2px",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: "100%",

          "button > svg": {
            color: palette.error,
          },

          label: {
            color: `${palette.neutral30}`,
            "&.Mui-focused": {
              color: `${palette.primary}`,
            },
          },

          "div.MuiOutlinedInput-root": {
            "&.Mui-focused": {
              "fieldset": {
                borderColor: `${palette.primary} !important`,
              },
            }
          },
        
          "&.text-error": {           
            label: {
              color: `${palette.error}`,
            },
            input: {
              color: `${palette.error}`,
            },
            "div.MuiOutlinedInput-root": {
              "&.Mui-focused": {
                "fieldset": {
                  borderColor: `${palette.error} !important`,
                },
              }
            },
          },
        },
      },
    },
  },
};

export default createTheme(muiTheme);
