import React from "react";
import { palette } from "../../../theme/partials";

export default function AnswerIcon({
  color = palette.primary,
  width = 20,
  height = 20
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00004 0.333252C3.32004 0.333252 0.333374 3.31992 0.333374 6.99992C0.333374 10.6799 3.32004 13.6666 7.00004 13.6666C10.68 13.6666 13.6667 10.6799 13.6667 6.99992C13.6667 3.31992 10.68 0.333252 7.00004 0.333252ZM6.33337 11.6666V10.3333H7.66671V11.6666H6.33337ZM8.44671 7.11325L9.04671 6.49992C9.72671 5.81992 9.96004 4.65325 9.17337 3.56659C8.57337 2.73325 7.60671 2.20659 6.59337 2.36659C5.56004 2.52659 4.72671 3.27325 4.44004 4.25325C4.33337 4.62659 4.60004 4.99992 4.98671 4.99992H5.18671C5.44671 4.99992 5.65337 4.81325 5.73337 4.56659C5.95337 3.93325 6.64004 3.51325 7.38004 3.71992C7.84671 3.85325 8.22004 4.25992 8.30671 4.73992C8.39337 5.20659 8.24671 5.64659 7.94004 5.93992L7.11337 6.77992C6.83337 7.05992 6.61337 7.40659 6.48004 7.79325C6.38671 8.06659 6.33337 8.35992 6.33337 8.66658V8.99992H7.66671C7.66671 8.69325 7.70004 8.45325 7.75337 8.23992C7.87337 7.75992 8.11337 7.45325 8.44671 7.11325Z"
        fill={color}
      />
    </svg>
  );
}
