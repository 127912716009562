import React from "react";

const Typography = ({
  text,
  variant,
  color,
  asTag,
  handleTagClicked,
  hasDecoration = false,
}) => {
  const Headline = `${asTag}`;
  const hasUnderlineDecoration = hasDecoration ? "underline" : "inherit";
  return (
    <>
      {asTag === "a" ? (
        <Headline
          style={{
            color: color,
            fontSize: variant,
            textDecoration: hasUnderlineDecoration,
          }}
          onClick={handleTagClicked}
        >
          {text}
        </Headline>
      ) : (
        <Headline style={{ color: color, fontSize: variant }}>{text}</Headline>
      )}
    </>
  );
};

export default Typography;
