import styled from "styled-components";
import { palette } from "../../../theme/partials";

const FormContent = styled.form`
  align-items: center;
  background-color: ${palette.white};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 26px 80px;

  h3 {
    margin-bottom: 15px;
  }

  p {
    margin: 12px 0;
    text-align: center;
    width: 370px;
  }
`;

const HeadingWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  p {
    text-align: center;
    width: 370px;
  }
`;

const FieldWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 20px;
  width: 100%;
`;

const NumberValidationWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  width: 100%;

  input {
    background-color: ${palette.white};
    border: 1px solid ${palette.neutral20};
    border-radius: 8px;
    color: ${palette.neutral20};
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    height: 70px;
    text-align: center;
    margin: 0 10px;
    width: 55px;
  }
`;

const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  justify-content: space-between;
  margin: 40px 0 0 0;
  height: 80px;
`;

const LinkWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-content: space-between;
  margin: 20px 0 0 0;
  height: 20px;

  a {
    margin: 0 20px;
  }
`;

const logInStyles = {
  LinkWrapper,
  FormContent,
  FieldWrapper,
  HeadingWrapper,
  ButtonWrapper,
  NumberValidationWrapper
};

export default logInStyles;
