const variants = {
  biggest: "40px",
  extraLarge: "32px",
  large: "24px",
  regular: "18px",
  normal: "16px",
  small: "14px",
  extraSmall: "12px",
  fit: "10px",
};

const fontFamily = {
  Poppins: "Poppins",
};

export { variants, fontFamily };
