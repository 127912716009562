import styled from "styled-components";

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh);
  padding: 0 40px;
`;

const LogoWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 120px;
  width: 100%;
`;

const CopyrightWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 140px;
  text-align: center;

  p {
    &:first-child {
      margin-bottom: 40px;
    }
  }
`;

const logInStyles = {
  Container,
  LogoWrapper,
  CopyrightWrapper
};

export default logInStyles;
