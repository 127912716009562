import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Typography from "../../common/typography";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import ErrorIcon from "@mui/icons-material/Error";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import logInFormStyles from "./logInForm.styled";
import { palette, variants } from "../../../theme/partials";
import ModalComponent from "../../common/modal/modal";
import ForgotPasswordForm from "../forgotPasswordForm";
import ChangePasswordForm from "../changePasswordForm";
import TooltipComponent from "../../common/tooltip";
import success from "../../../static/success.svg";
import AlertComponent from "../../common/alert";
import { useAuth } from "../../../hooks/useAuth";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

const validationSchema = Yup.object({
  username: Yup.string("Ingresa tu usuario")
    .required("Usuario es requerido")
    .min(6, "Usuario debe tener mínimo 6 caracteres"),
  password: Yup.string("Ingresa tu contraseña")
    .min(8, "Contraseña debe tener mínimo 8 caracteres")
    .required("Contraseña es requerida"),
});

const LogInForm = ({ title, description, hasTooltip = false }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const { handleAuthentication } = useAuth();
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async(values) => {
      await onSubmitForm(values);
    }
  });

  /**
   * It should login form submit
   * @param data: Object
   */
  const onSubmitForm = async(data) => {

    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available')
      return
    }

    const gReCaptchaToken = await executeRecaptcha('loginForm')

    handleAuthentication({
      usuario: data?.username,
      contrasenia: data?.password,
      gToken: gReCaptchaToken
    });
  };

  const [open, setOpen] = useState(false);
  const [openSucess, setOpenSuccess] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleOpenSuccess = () => setOpenSuccess(true);
  const handleCloseSuccess = () => setOpenSuccess(false);

  return (
    <>
      <logInFormStyles.FormContent onSubmit={formik.handleSubmit}>
        <logInFormStyles.HeadingWrapper>
          <>
            {title && (
              <Typography
                text={title}
                variant={variants.large}
                color={palette.secondary}
                asTag={"h3"}
              />
            )}
          </>
          <logInFormStyles.DescriptionWrapper>
            {description && (
              <p>
                {description}
                <>
                  {hasTooltip && (
                    <TooltipComponent
                      title={"Recordatorio"}
                      description={
                        "El usuario y contraseña son los que utilizas para realizar consultas en tu portal de Circulo de Créditos, son tus credenciales de afiliación de usuario."
                      }
                      hasIcon
                    />
                  )}
                </>
              </p>
            )}
          </logInFormStyles.DescriptionWrapper>
        </logInFormStyles.HeadingWrapper>

        <logInFormStyles.FieldWrapper>
          <FormControl
            variant="outlined"
            className={
              formik.touched.username && formik.errors.username && "text-error"
            }
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Usuario
            </InputLabel>
            <OutlinedInput
              id={"username"}
              name={"username"}
              label={"Usuario"}
              type={"text"}
              endAdornment={
                <>
                  {formik.errors.username &&
                    formik.touched.username &&
                    Boolean(formik.errors.username) && (
                      <InputAdornment position="end">
                        <IconButton aria-label="alert" edge="end">
                          <ErrorIcon />
                        </IconButton>
                      </InputAdornment>
                    )}
                </>
              }
              value={formik.values.username}
              onChange={formik.handleChange}
              error={formik.touched.username && Boolean(formik.errors.username)}
              placeholder={"Ingresa tu usuario de CDC"}
              onBlur={formik.handleBlur}
              required
            />
            {formik.errors.username && (
              <div id="help-error">{formik.errors.username}</div>
            )}
          </FormControl>
        </logInFormStyles.FieldWrapper>

        <logInFormStyles.FieldWrapper>
          <FormControl
            variant="outlined"
            className={
              formik.touched.password && formik.errors.password && "text-error"
            }
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Contraseña
            </InputLabel>
            <OutlinedInput
              id={"password"}
              name={"password"}
              label={"Contraseña"}
              type={"password"}
              endAdornment={
                <>
                  {formik.touched.password && formik.errors.password && (
                    <InputAdornment position="end">
                      <IconButton aria-label="alert" edge="end">
                        <ErrorIcon />
                      </IconButton>
                    </InputAdornment>
                  )}
                </>
              }
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              placeholder={"Ingresa tu contraseña de CDC"}
              onBlur={formik.handleBlur}
              required
            />
            {formik.errors.password && (
              <div id="help-error">{formik.errors.password}</div>
            )}
          </FormControl>
        </logInFormStyles.FieldWrapper>

        <logInFormStyles.ButtonWrapper>
          <Button
            variant={"contained"}
            disabled={!formik.isValid}
            type="submit"
          >
            Iniciar Sesión
          </Button>
          <Typography
            text={"¿Olvidaste tu contraseña?"}
            variant={variants.small}
            color={palette.primary}
            asTag={"a"}
            hasDecoration
            handleTagClicked={handleOpen}
          />
        </logInFormStyles.ButtonWrapper>

        {/* MODAL */}
        <ModalComponent isOpen={open} handleCloseModal={handleClose}>
          <ForgotPasswordForm
            title={"¿Olvidaste tu contraseña?"}
            description={
              "Ingresa tu correo electrónico asociado a tu cuenta para enviarte un vínculo y reestablecer tu contraseña."
            }
            onCancelHandle={handleClose}
          />
        </ModalComponent>

        <ModalComponent isOpen={false} handleCloseModal={handleCloseSuccess}>
          <ChangePasswordForm
            title={"Crear contraseña"}
            description={"Crea una nueva contraseña para ingresar"}
          />
        </ModalComponent>

        <ModalComponent isOpen={false} handleCloseModal={handleOpenSuccess}>
          <AlertComponent
            icon={success}
            title={"Contraseña creada"}
            description={"Su contraseña fue creada con exito."}
          />
        </ModalComponent>

        <ModalComponent
          isOpen={openSucess}
          handleCloseModal={handleCloseSuccess}
        >
          <AlertComponent
            icon={success}
            title={"Petición enviada"}
            description={"Su petición fue enviada con exito."}
          />
        </ModalComponent>
      </logInFormStyles.FormContent>
    </>
  );
};

export default LogInForm;
