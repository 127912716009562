import styled from "styled-components";
import { palette } from "../../../theme/partials";

const Container = styled.div`
  align-items: center;
  background-color: ${palette.white};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 26px 80px;
  text-align: center;

  img {
    margin: 10px 0 20px 0;
  }
  
  p {
    margin: 20px 0 20px 0;
  }

  button {
    margin: 10px 0 10px 0;
  }
  
  a {
    margin: 20px 0 20px 0;
  }
`;

const AlertStyles = {
  Container,
};

export default AlertStyles;
