import styled from "styled-components";
import { palette } from "../../theme/partials";

const Content = styled.div`
  background-color: ${palette.white};
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100vh;
  width: 100%;
`;

const Main = styled.div`
  height: 100vh;
  transition: all 0.1s ease;
  min-width: 860px;
  overflow-y: scroll;

  @media screen and (min-width: 992px) {
    width: calc(100vw - 255px);
  }
`;

const MainViewStyle = {
  Content,
  Main
};

export default MainViewStyle;
