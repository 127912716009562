import React from "react";
import signUpStyles from "./signup.styled";
import logo from "../../static/logo.svg";
import SignUpForm from "../../components/form/signUpForm";

const SignUpPage = () => {
  return (
    <signUpStyles.Container>
      <signUpStyles.LogoWrapper>
        <img src={`${logo}`} alt={"logo"} loading="lazy" />
      </signUpStyles.LogoWrapper>

      <SignUpForm
        title={"Crea tu cuenta"}
        description={"Ingresa tu correo electrónico, teléfono y RFC"}
      />
    </signUpStyles.Container>
  );
};

export default SignUpPage;
