import React from "react";
import CustomMainView from "../../components/customMainView";
import DevStyles from "./development.styled";
import { MARKDOWN_CONTENT } from "../../constant";
import MarkDownComponent from "../../components/common/markdown";

const DevelopmentComponent = () => {
  return (
    <CustomMainView>
      <DevStyles.MainContent>
        <MarkDownComponent content={MARKDOWN_CONTENT} />
      </DevStyles.MainContent>
    </CustomMainView>
  );
};
export default DevelopmentComponent;
