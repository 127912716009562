import React, { useState } from "react";
import TemplateStyles from "../createTemplate.styled";
import Typography from "../../common/typography";
import { palette, variants } from "../../../theme/partials";
import Button from "@mui/material/Button";
import { translation } from "../../../constant";
import ShowScenariesComponent from "../components/showScenaries";
import UpdateScenarieSelected from "../components/updateScenarie";
import { textToCapitalize } from '../../../utils/sanitize';

const Step3 = ({
  templateInfo,
  onNextStepHandler,
  handleCloseCreateTemplate
}) => {
  const { staticText } = translation;
  const { name, scenaries } = templateInfo;
  const [itemData, setItemData] = useState({
    scenarieId: null,
    itemSelected: null,
    isEditing: false,
  });

  /**
   * It should handle is edit component
   * @param item - Object
   */
  const isEditScenarieSelected = (item) => {
    setItemData({
      ...itemData,
      itemSelected: item,
      isEditing: true,
    });
  };

  /**
   * It should cancel edit component
   * @param item - Object
   */
  const isEditScenarieCanceled = () => {
    setItemData({
      ...itemData,
      itemSelected: null,
      isEditing: false,
    });
  };

  return (
    <>
      <Typography
        text={`${staticText.customizationEditScenaries} ${textToCapitalize(name)}`}
        variant={variants.small}
        color={palette.secondary}
        asTag={"h6"}
      />
      <>
        {!itemData?.isEditing && !itemData?.itemSelected && scenaries && (
          <ShowScenariesComponent
            sourceData={templateInfo?.scenaries}
            scenarieSelected={isEditScenarieSelected}
          />
        )}
      </>

      <>
        {itemData?.isEditing && itemData?.itemSelected && (
          <UpdateScenarieSelected
            templateInfo={templateInfo}
            scenarieSelected={itemData?.itemSelected}
            isEditScenarieCanceled={isEditScenarieCanceled}
          />
        )}
      </>

      <>
        {!itemData?.isEditing && !itemData?.itemSelected && scenaries && (
          <TemplateStyles.ButtonWrapper>
            <Button
              variant={"contained"}
              type="button"
              onClick={onNextStepHandler}
            >
              {staticText.btnSaveAndContinue}
            </Button>

            <span style={{ marginTop: "30px" }} onClick={() => handleCloseCreateTemplate(4)}>
              <Typography
                text={staticText.btnExit}
                variant={variants.small}
                color={palette.neutral20}
                asTag={"a"}
                hasDecoration
              />
            </span>
          </TemplateStyles.ButtonWrapper>
        )}
      </>
    </>
  );
};

export default Step3;
