import React from 'react'
import { Box, Typography } from '@mui/material'
import ScoreStatusComponent from '../ScoreStatus'
import { ResponsiveBar } from '@nivo/bar'
import { linearGradientDef } from '@nivo/core'
import ImageComponent from '../Images/ImageComponent'
import LastProgress from '../../static/icons/lastProgress.svg'
import Schedule from '../../static/icons/schedule.svg'

const FicoScoreComponent = ({ score }) => {
	return (
		<Box component={'div'} className="flex-column bg-white">
			<Box
				component={'div'}
				className="flex-column justify-center padding-content"
			>
				<ScoreStatusComponent
					source={score}
					isFirst
					scoreSize={'extra-large'}
				/>
				<Typography variant="indicatorText">
					<strong>¡Subiste 12 puntos </strong> desde el mes pasado!
				</Typography>
			</Box>
			<br />
			<Box component={'div'} className="wrapper-nivo fico-score">
				<div className="bar-status"></div>
				<ResponsiveBar
					height={34}
					layout="horizontal"
					borderRadius={10}
					data={[{ value: score }]}
					indexBy={(_index) => `${_index.value} `}
					keys={['value']}
					colors={['#cce4f2']}
					colorBy="indexValue"
					groupMode="grouped"
					enableGridX={false}
					enableLabel={false}
					enableGridY={false}
					label={(_label) => _label.data.id}
					padding={0.2}
					labelFormat={(d) => <tspan x={40}>{d}</tspan>}
					axisBottom={null}
					labelTextColor="black"
					motionStiffness={170}
					motionDamping={26}
					defs={[
						linearGradientDef(
							'gradient',
							[
								{ offset: 0, color: '#288B08' },
								{ offset: 25, color: '#A4DE00' },
								{ offset: 50, color: '#FEFE00' },
								{ offset: 75, color: '#FEBF01' },
								{ offset: 100, color: '#FF0101' },
							],
							{
								gradientTransform: 'rotate(90 0.5 0.5)',
							},
						),
					]}
					fill={[{ match: '*', id: 'gradient' }]}
				/>
			</Box>
			<Box
				component={'div'}
				className="flex-row space-between padding-content inicator-chart"
			>
				<Typography variant="barGraphicIndicator">Muy Bajo</Typography>
				<Typography variant="barGraphicIndicator">Excelente</Typography>
			</Box>
			<Box
				component={'div'}
				className="flex-column flex-start padding-content bg-main no-bottom preview"
			>
				<Box
					component={'div'}
					className="flex-row flex-start align-center padding-content indicator-text full-width mt-10 inherit-bg"
				>
					<ImageComponent source={Schedule} alt="schedule" />
					<Typography variant="lastedUpdated">
						Última actualización: 20/Diciembre/2021
					</Typography>
				</Box>
				<Box
					component={'div'}
					className="flex-row flex-start align-center padding-content indicator-text full-width inherit-bg"
				>
					<ImageComponent source={LastProgress} alt="progress" />
					<Typography variant="lastedUpdated">
						Próxima actualización: 20/Enero/2022
					</Typography>
				</Box>
			</Box>
		</Box>
	)
}

export default FicoScoreComponent
