import React from 'react'
import { Box } from '@mui/material'

const ImageComponent = ({ hasBackground = false, source, alt }) => {
	const avatar = () => {
		return (
			<Box component={'div'} className="display-avatar extra-large">
				<img src={`${source}`} alt={alt} loading="lazy" />
			</Box>
		)
	}
	return hasBackground ? (
		avatar()
	) : (
		<img src={`${source}`} alt={alt} loading="lazy" />
	)
}

export default ImageComponent
