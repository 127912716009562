export const isUniClick = process.env.NEXT_PUBLIC_CURRENT_APP === 'UNICLICK'
export const isDevelop = process.env.NEXT_PUBLIC_ENVIROMENT === 'DEVELOP'

const GamaScoreTheme = {
	Colors: {
		primary: '#1148F4',
		primaryLight: '#A28DE2',
		secondary: '#6C8AE8',
		success: '#63F7D3',
		info: '#4BDDE9',
		warning: '#FBDD40',
		error: '#FD6F6F',
		text: '#737373',
		darkText: '#00105B',
		blackText: '#000000',
		lightText: '#ADB6D2',
		indicator: '#ABAAB4',
		white: '#FFF',
		dashboardCardBackground: '#FFF',
		cardHeaderBackground: 'rgba(184, 196, 255, 0.12) !important',
		tabHeaderBackground: 'rgb(242 243 249)!important',
		cardBackground: '#F9F9F9 !important',
		cardHeaderBorder: '#9D9D9D',
		mainBackground: '#FFF',
		uncheckedInput: '#ADB6D2',
		headerBackground: '#FFF',
		activeRouteBg: '#EAEEF9',
		footerBackground: '#DCE2F3',
		movementsTableIcons: '#6C8AE8',
		iconsColorPrimary: '#1F497D',
		yellow: '#FADC51',
		green: '#4FAA31',
		indicatorText:'#45464F',
		scoreListText:'#77767A',
		AuthHeading:'#1B1B1F',
		scoreListMessage:'#737373',
		lineSeparator:'#92A6FF',
		dotColor:'#6C88FF',
		dotColorBg:'#A4A4A4',
		reloadText: '#46464A',
		cardBg: '#FEFBFF',
		greenBar: '#5DBF77'
	},
	Fonts: {
		primary: 'Poppins',
		secondary: 'Roboto, sans-serif',
	},
	BankCardsVariants: [
		'linear-gradient(106.72deg, #6C8AE8 7.19%, #4BDDE9 102.3%)',
		'linear-gradient(106.72deg, #A28DE2 7.19%, #467BD7 102.3%)',
		'linear-gradient(106.72deg, #33C1FF 7.19%, #63F7D3 102.3%)',
		'linear-gradient(106.72deg, #A28DE2 5.99%, #FD6F6F 102.3%)',
		'linear-gradient(106.72deg, #FBDD40 5.99%, #FD6F6F 102.3%)',
	],
	Shadows: {
		headerShadow: '0px 11px 38px -6px #D3DAF166',
		cardShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)'
	},
	GrapichColors: {
		low: 'linear-gradient(to right, #f90000, #fa4000, #fa5e00, #fa7500, #fa8a00)',
	},
}

const ThemeConst = GamaScoreTheme

export const Colors = ThemeConst.Colors
export const Fonts = ThemeConst.Fonts
export const BankCardsVariants = ThemeConst.BankCardsVariants
export const GraphicColors = ThemeConst.GrapichColors
export const Shadows = ThemeConst.Shadows
export const ExpenseCategoryColors = {
	officeTeam: '#63F7D3',
	administrativeExpenses: '#6C8AE8',
	travelExpenses: '#A28DE2',
	repairsAndMaintenance: '#FBDD40',
	bankTransactions: '#D4F600',
	financialServices: '#4BDDE9',
	professionalServices: '#FF9900',
	publicServices: '#4116C4',
	salariesAndStaff: '#E48C9B',
	transportMobility: '#FF51EE',
}

export default ThemeConst
