import React from "react";
import Skeleton from "react-loading-skeleton";
import TemplateStyles from "../createTemplate/createTemplate.styled";
import EditStyles from "../../pages/template/editTemplate/editTemplate.styled";
import Typography from "../common/typography";
import Divider from "@mui/material/Divider";
import { palette, variants } from "../../theme/partials";
import ChevronRight from "../../static/chevron-right.svg";
import { translation } from "../../constant";

const LoaderTemplate = () => {
  const { staticText } = translation;

  return (
    <>
      <EditStyles.Header>
        <TemplateStyles.breadcrumb className="breadcrumb">
          <Typography
            text={"Tus plantillas"}
            variant={variants.small}
            color={palette.neutral}
            asTag={"a"}
            hasDecoration
          />
          <img src={`${ChevronRight}`} alt={"icon"} loading="lazy" />
          <Skeleton height={20} />
        </TemplateStyles.breadcrumb>

        <div style={{ marginLeft: "0px", width: "20%" }}>
          <Skeleton height={32} count={1} />
        </div>
      </EditStyles.Header>

      <EditStyles.Wrapper>
        <EditStyles.InputName>
          <Typography
            text={"Nombre de la tu plantilla"}
            variant={variants.small}
            color={palette.secondary}
            asTag={"h6"}
          />
          <div style={{ marginLeft: "0px", width: "100%" }}>
            <Skeleton height={32} count={1} />
          </div>
        </EditStyles.InputName>

        <br />
        <Divider />

        <EditStyles.Colors>
          <Typography
            text={"Personalización de Apariencia"}
            variant={variants.small}
            color={palette.secondary}
            asTag={"h6"}
          />

          <EditStyles.RowPerColumns>
            <div style={{ marginLeft: "00px", width: "100%" }}>
              <Skeleton height={32} count={2} />
            </div>

            <div style={{ marginLeft: "40px", width: "100%" }}>
              <Skeleton height={32} count={2} />
            </div>
          </EditStyles.RowPerColumns>
        </EditStyles.Colors>

        <Divider />

        <EditStyles.Colors>
          <EditStyles.RowPerColumns className="full-width">
            <TemplateStyles.PreviewtWrapper
              style={{ width: "40%", paddingRight: "30px" }}
            >
              <Typography
                text={"Definición de escenarios"}
                variant={variants.small}
                color={palette.secondary}
                asTag={"h6"}
              />
              <Skeleton height={32} count={5} />
            </TemplateStyles.PreviewtWrapper>

            <EditStyles.Fields>
              <Typography
                text={staticText.components}
                variant={variants.small}
                color={palette.secondary}
                asTag={"h6"}
              />
              <Skeleton height={40} count={5} />
            </EditStyles.Fields>
          </EditStyles.RowPerColumns>
        </EditStyles.Colors>

        <Divider />

        <EditStyles.Colors>
          <Typography
            text={"Customización de escenarios"}
            variant={variants.small}
            color={palette.secondary}
            asTag={"h6"}
          />

          <EditStyles.RowPerColumns>
            <div style={{ marginLeft: "0px", width: "30%" }}>
              <Skeleton height={32} count={1} />
            </div>
            <div style={{ marginLeft: "20px", width: "30%" }}>
              <Skeleton height={32} count={1} />
            </div>
            <div style={{ marginLeft: "20px", width: "30%" }}>
              <Skeleton height={32} count={1} />
            </div>
          </EditStyles.RowPerColumns>
        </EditStyles.Colors>

        <Divider />

        <TemplateStyles.ButtonWrapper className="row">
          <div style={{ marginLeft: "0px", width: "20%" }}>
            <Skeleton height={32} count={1} />
          </div>
          <div style={{ marginLeft: "20px", width: "20%" }}>
            <Skeleton height={32} count={1} />
          </div>
        </TemplateStyles.ButtonWrapper>
      </EditStyles.Wrapper>
    </>
  );
};

export default LoaderTemplate;
