import {
	Box,
	Typography,
	Button,
	TextField,
	InputAdornment,
} from '@mui/material'
import React, { useState } from 'react'
import BasicCardComponent from '../Cards/BasicCard'
import ImageComponent from '../Images/ImageComponent'
import common from '../../common.json'
import CheckIcon from '../../static/icons/check.svg'
import Mail from '@mui/icons-material/MailOutlined'
import Calendar from '@mui/icons-material/CalendarMonthTwoTone'

const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i
const { general, forms, buttons } = common

const IconTextField = ({ iconStart, InputProps, ...props }) => {
	return (
		<TextField
			{...props}
			InputProps={{
				...InputProps,
				startAdornment: iconStart ? (
					<InputAdornment position="start">{iconStart}</InputAdornment>
				) : null,
			}}
		/>
	)
}

const SubscribeFormCompoent = ({ title, subscriptionOptions }) => {
	const [currency, setCurrency] = useState('semanal')
	const [isSubmitted, setSubmitted] = useState(false)
	const [userEmail, setUserEmail] = useState(null)

	const handleChange = (event) => {
		setCurrency(event.target.value)
	}

	return (
		<>
			{isSubmitted ? (
				<BasicCardComponent cardHeading={title} bg={'bg-white'}>
					<Box
						component={'div'}
						className="form padding-content pd-more bg-white"
					>
						<Box
							component={'div'}
							className="flex-column justify-center form padding-content pd-more"
						>
							<ImageComponent
								source={CheckIcon}
								alt="check"
								className="shake"
							/>
							<Typography variant="submittedHeader">
								¡Ya estás suscripto!
							</Typography>
							<Typography variant="submittedInfo">
								Enviaremos actualizaciones sobre tu score cada mes a la casilla
								de correo electrónico: {userEmail}
							</Typography>
						</Box>
					</Box>
					<Box
						component={'div'}
						className="flex-column justify-center padding-content bg-main"
					>
						<Box component={'div'} className="padding-content full-width">
							<Button
								type="button"
								variant="contained"
								onClick={() => setSubmitted(false)}
							>
								Editar subscripción
							</Button>
						</Box>
					</Box>
				</BasicCardComponent>
			) : (
				<BasicCardComponent cardHeading={title} bg={'bg-white'}>
					<Box component={'div'} className="padding-content pd-more bg-white preview">
						<Typography variant="headingIndicator">
							{general.subscribeNotificationMessage}
						</Typography>
					</Box>

					<Box component={'div'} className="form padding-content pd-more bg-white inherit-bg">
						<IconTextField
							error={
								(userEmail && userEmail.length) > 0 &&
								!emailRegex.test(userEmail)
							}
							label={forms.inputEmail}
							color="primary"
							placeholder={forms.placeholderEmail}
							onChange={(e) => setUserEmail(e.target.value)}
							value={userEmail}
							iconStart={<Mail />}
						/>
						{!emailRegex.test(userEmail) &&
							userEmail &&
							userEmail.length > 0 && (
								<Typography variant="errorMessage">
									El correo electrónico ingresado no es válido
								</Typography>
							)}

						<IconTextField
							select
							label={forms.inputPeriodicity}
							value={currency}
							onChange={handleChange}
							SelectProps={{
								native: true,
							}}
							iconStart={<Calendar />}
						>
							{subscriptionOptions.map((option) => (
								<option key={option.value} value={option.value}>
									{option.label}
								</option>
							))}
						</IconTextField>
					</Box>
					<Box
						component={'div'}
						className="flex-column justify-center padding-content no-bottom bg-main preview"
					>
						<Box component={'div'} className="full-width bg-unset subscribe-wrapper">
							<Button
								type="button"
								variant="contained"
								disabled={!emailRegex.test(userEmail)}
								onClick={() => setSubmitted(true)}
							>
								{buttons.subscribe}
							</Button>
						</Box>
					</Box>
				</BasicCardComponent>
			)}
		</>
	)
}

export default SubscribeFormCompoent
