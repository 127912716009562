import styled from "styled-components";
import { palette } from "../../theme/partials";

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 20px;
`;

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 20px 0;
  width: 100%;

  .dropdown {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 6px 12px;
    height: 46px;
    background-color: #f6f8ff;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #f6f8ff;
    transition: all 0.2s ease;

    h4 {
      font-weight: 800;
      margin: 0 16px 0 12px;
    }

    &:hover {
      border: 1px solid #1148F4;
    }
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f6f8ff;
    min-width: 188px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    top: 70px;
  }

  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    color: #45464F
  }

  .dropdown-content a:hover {
    background-color: #E4EBFF;
    color: #1148F4;
  }

  .logout {
    color: #1148F4 !important;
    text-decoration: underline !important;
    text-align: center;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown:hover .dropbtn {
    background-color: #3e8e41;
  }
`;

const Profile = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  height: 46px;
  background-color: #f6f8ff;
  border-radius: 8px;

  h4 {
    font-weight: 800;
    margin: 0 16px 0 12px;
  }
`;

const Avatar = styled.div`
  display: flex;
  background-color: ${palette.primary};
  color: ${palette.white};
  height: 40px;
  width: 40px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

const SubHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0 20px 0;
`;

const HeadingStyle = {
  Heading,
  Avatar,
  Profile,
  Section,
  SubHeading,
};

export default HeadingStyle;
