import React from "react";
import Typography from "../../common/typography";
import { SketchPicker } from "react-color";
import colorPickerImage from "../../../static/picker-color.svg";
import { palette, variants } from "../../../theme/partials";

const ColorPicker = ({ initialColor, handleColorSelected }) => {
  const [displayColorPicker, setDisplayColor] = React.useState(false);
  const styles = {
    color: {
      width: "14px",
      height: "14px",
      borderRadius: "4px",
      background: `${initialColor}`,
      margin: "0 8px"
    },
    swatch: {
      padding: "5px",
      display: "flex",
      cursor: "pointer",
      textTransform: "uppercase",
    },
    popover: {
      position: "absolute",
      zIndex: "2",
    },
    cover: {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    },
  };

  return (
    <div>
      <div
        style={styles.swatch}
        onClick={() => setDisplayColor(!displayColorPicker)}
      >
        <Typography
          text={initialColor}
          variant={variants.extraSmall}
          color={palette.neutral30}
          asTag={"a"}
          hasDecoration
          style={{'marginLeft': '0px !important'}}
        />
        <div style={styles.color} />
        <img src={`${colorPickerImage}`} alt={"color"} loading="lazy" />
      </div>
      {displayColorPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={() => setDisplayColor(false)} />
          <SketchPicker
            color={initialColor}
            onChange={(e) => handleColorSelected(e.hex)}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ColorPicker;
