import React from "react";
import Skeleton from "react-loading-skeleton";

const LoaderTable = ({ items = 5, height = '32px', show = false }) => {

    return (
        <>
            {show && (
                <div style={{ width: '100%', paddingLeft: '1px' }}>
                    <Skeleton height={height} count={items} />
                </div>
            )}
        </>
    );
};

export default LoaderTable;
