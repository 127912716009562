import React from "react";
import TemplateStyles from "../createTemplate.styled";
import Typography from "../../common/typography";
import ColorPicker from "../../common/color-picker";
import { palette, variants } from "../../../theme/partials";
import { translation } from "../../../constant";

const CustomColorTemplate = ({ colors, onChangeColor }) => {
  const { staticText } = translation;

  return (
    <TemplateStyles.HtmlPickerWrapper>
      <TemplateStyles.Picker>
        <Typography
          text={staticText.primaryColor}
          variant={variants.extraSmall}
          color={palette.secondaryContainer}
          asTag={"h6"}
          className="no-margin"
        />
        <ColorPicker
          initialColor={colors?.primary}
          handleColorSelected={(e) => onChangeColor("primary", e)}
        />
      </TemplateStyles.Picker>
      <TemplateStyles.Picker>
        <Typography
          text={staticText.secondaryColor}
          variant={variants.extraSmall}
          color={palette.secondaryContainer}
          asTag={"h6"}
          className="no-margin"
        />
        <ColorPicker
          initialColor={colors?.secondary}
          handleColorSelected={(e) => onChangeColor("secondary", e)}
        />
      </TemplateStyles.Picker>
    </TemplateStyles.HtmlPickerWrapper>
  );
};

export default CustomColorTemplate;
