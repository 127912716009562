import React from 'react'
import { Card, CardHeader, CardContent } from '@mui/material'

const BasicCardComponent = ({ cardHeading = '', children, bg= '' }) => {
	return (
		<Card>
			{cardHeading && <CardHeader title={cardHeading} />}
			<CardContent className={bg}>{children}</CardContent>
		</Card>
	)
}

export default BasicCardComponent
