import React, { useState } from "react";
import TemplateStyles from "../createTemplate.styled";
import Button from "@mui/material/Button";
import Typography from "../../common/typography";
import UploadImageComponent from "../components/UploadImage";
import CustomColorTemplate from "../components/ColorPickerTemplate";
import { palette, variants } from "../../../theme/partials";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { translation, COLORS } from "../../../constant";
import { addTemplateAppearance } from "../../../services/platform.service";
import { setPayloadAppearence } from "../../../helper/templateMappers";

const Step1 = ({
  templateInfo,
  onNextStepHandler,
  handleCloseCreateTemplate,
  refreshListOfTemplates
}) => {
  const { staticText } = translation;
  const { name, templateId } = templateInfo;
  const [appeareance, setAppearance] = useState({
    logo: null,
    color: {
      primary: COLORS.primary,
      secondary: COLORS.secondary,
    },
  });
  const [expanded, setExpanded] = useState(false);

  /**
   * It should save color appearence on templateInfo State
   * @param type: string,
   * @param color: string,
   */
  const onChangeColor = (type, color) => {
    setAppearance({
      ...appeareance,
      color: {
        ...appeareance?.color,
        [type]: color,
      },
    });
  };

  /**
   * It should save logo on templateInfo State
   * @param data: string,
   */
  const updateImageForTemplateInfo = (data) => {
    setAppearance({
      ...appeareance,
      logo: data?.logo,
    });
  };

  /**
   * It should expand and collapse panels
   * @param panel: Event
   */
  const onCollapsablePanelHandler = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  /**
   * It should save appearance for step 2 and go to next step
   * @param payload: Object - Colores and Logo
   */
  const saveAppearanceTemplate = async () => {
    try {
      const payload = setPayloadAppearence({
        logo: appeareance?.logo,
        color: appeareance?.color,
        templateId,
      });
      const { data } = await addTemplateAppearance(payload);

      if (data?.idPlantilla) {
        onNextStepHandler({
          nextStep: 3,
          logo: appeareance?.logo,
          color: appeareance?.color,
        });
      }
      refreshListOfTemplates();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Typography
        text={`${staticText.customAppearance} "${name}"`}
        variant={variants.small}
        color={palette.secondary}
        asTag={"h6"}
      />

      <TemplateStyles.AccordionPanel>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={onCollapsablePanelHandler("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography
              text={staticText.logoCompany}
              variant={variants.normal}
              color={palette.secondaryContainer}
              asTag={"h5"}
            />
          </AccordionSummary>
          <AccordionDetails>
            <TemplateStyles.UploadImage>
              <UploadImageComponent
                updateImageForTemplateInfo={updateImageForTemplateInfo}
              />
            </TemplateStyles.UploadImage>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={onCollapsablePanelHandler("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography
              text={staticText.colors}
              variant={variants.normal}
              color={palette.secondaryContainer}
              asTag={"h5"}
            />
          </AccordionSummary>
          <AccordionDetails>
            <CustomColorTemplate
              colors={appeareance?.color}
              onChangeColor={onChangeColor}
            />
          </AccordionDetails>
        </Accordion>
      </TemplateStyles.AccordionPanel>

      <TemplateStyles.ButtonWrapper>
        <Button
          variant={"contained"}
          disabled={!appeareance?.logo}
          type="button"
          onClick={saveAppearanceTemplate}
        >
          {staticText.btnSaveAndContinue}
        </Button>

        <span
          style={{ marginTop: "30px" }}
          onClick={() => handleCloseCreateTemplate(2)}
        >
          <Typography
            text={staticText.btnExit}
            variant={variants.small}
            color={palette.neutral20}
            asTag={"a"}
            hasDecoration
          />
        </span>
      </TemplateStyles.ButtonWrapper>
    </div>
  );
};

export default Step1;
