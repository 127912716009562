import styled from "styled-components";
import { palette } from "../../theme/partials";

const Content = styled.div`
  background-color: ${palette.white};
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100vh;
  width: 100%;
`;

const Main = styled.div`
  height: 100vh;
  width: calc(100vw - 255px);
  overflow-y: scroll;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${palette.primaryContainer};
  justify-content: center;
  padding: 20px;
`;

const Indicators = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  width: 100%;
`;

const Indicator = styled.div`
  background-color: ${palette.white};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px;
`;

const IndicatorLink = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 16px;

  h4 {
    font-weight: 500 !important;
    margin-left: 8px;
  }

  button {
    height: 24px !important;
    width: 24px !important;
  }

  svg {
    color: ${palette.primary} !important;
  }
`;

const WrapperGraphics = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 40px;
  width: 100%;

  & .grid-item {
    background-color: ${palette.white};
    text-align: center;
    padding: 10px;
    font-size: 30px;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(186, 186, 186, 0.25);
  }

  & .item1 {
    width: 98%;
  }

  @media print {
    & .item1 {
      width: 98.6%;
    }
  }
  
  @media only screen and (min-width: 1400px) {
    & .item1 {
      width: 100%;
    } 
  }
`;

const WrapperGraphicsMaps = styled.div`
  display: flex;
  margin-top: 40px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;

  & .grid-item {
    background-color: ${palette.white};
    text-align: center;
    padding: 10px;
    font-size: 30px;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(186, 186, 186, 0.25);
  }

  & .item3 {
    width: 98%;
  }

  & .item4 {
    margin-top: 12px;
    width: 98%;
  }

  @media print {
    display: flex;
    gap: 20px;
    width: 100%;
    flex-direction: row;

    & .item3 {
      width: 100%;
    }

    & .item4 {
      margin-top: 0;
      width: 100%;
    }
  }

  @media only screen and (min-width: 1400px) {
    flex-direction: row;
    gap: 20px;

    & .item3 {
      width: 100%;
    }

    & .item4 {
      margin-top: 0;
      width: 100%;
    }
  }
`;

const Grapchic = styled.div`
  min-width: 100%;
  width: 100%;
`;

const Filters = styled.div`
  display: flex;
  width: 100%;
`;

const Grapchics = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 0;

  @media only screen and (min-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
  }
`;

const Column = styled.div`
  width: 50%;
`;

const Download = styled.div`
  width: 100%;
  padding-top: 8px;
  padding-left: 14px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
`;

const DashboardStyle = {
  Content,
  Main,
  Download,
  MainContent,
  Indicators,
  Indicator,
  IndicatorLink,
  Grapchic,
  Grapchics,
  Column,
  Filters,
  WrapperGraphics,
  WrapperGraphicsMaps
};

export default DashboardStyle;
