import React, { useState, useEffect } from "react";
import StepperComponent from "../stepper";
import TemplateStyles from "./createTemplate.styled";
import Typography from "../common/typography";
import { palette, variants } from "../../theme/partials";
import CreateTemplateName from "./steps/stepInitial";
import CreateTemplateAppearance from "./steps/step1";
import CreateScenariesAndComponents from "./steps/step2";
import CreateNotificationsAndSort from "./steps/step3";
import { DATA_STUB, INITIAL_DATA_TEMPLATE } from "../../constant";

const CreateTemplateForAdmin = ({
  handleCloseCreateTemplate,
  handleShowTemplateCreationSuccess,
  isTemplateSelected,
  refreshListOfTemplates,
}) => {
  const [templateInfo, setTemplateInfo] = useState(INITIAL_DATA_TEMPLATE);

  /**
   * It should keep control for next step
   * @param data: Object
   */
  const onNextStepHandler = (data) => {
    const {
      nextStep,
      name,
      templateId,
      color,
      logo,
      customScenaries,
      fieldsComponent
    } = data;

    if (templateInfo?.currentStep === 1) {
      setTemplateInfo({
        ...templateInfo,
        currentStep: nextStep,
        name,
        templateId
      });
    }

    if (templateInfo?.currentStep === 2) {
      setTemplateInfo({
        ...templateInfo,
        currentStep: nextStep,
        logo,
        color
      });
    }

    if (templateInfo?.currentStep === 3) {
      setTemplateInfo({
        ...templateInfo,
        currentStep: nextStep,
        scenaries: customScenaries,
        components: fieldsComponent
      });
    }

    if (templateInfo?.currentStep === 4) {
      handleShowTemplateCreationSuccess();
    }
  };

  /**
   * It should update from steps
   * @param null
   */
  const shouldUpdateTemplate = () => {
    if (
      isTemplateSelected?.etapa === "NOMBRE_CREADO" ||
      isTemplateSelected?.etapa === "APARIENCIA_CREADA"
    ) {
      setTemplateInfo({
        ...templateInfo,
        templateId: isTemplateSelected?.idPlantilla,
        name: isTemplateSelected?.nombre,
        currentStep: isTemplateSelected?.etapa === "NOMBRE_CREADO" ? 2 : 3
      });
    }
  };

  useEffect(() => {
    if (templateInfo?.currentStep === 3) {
      const el = document.getElementById("box-modal-step");
      el.style.height = "calc(100vh - 70px)";
    } else {
      const el = document.getElementById("box-modal-step");
      el.style.height = "auto";
    }
  }, [templateInfo?.currentStep]);

  useEffect(() => {
    if (isTemplateSelected) {
      shouldUpdateTemplate();
    }

    return () => {
      setTemplateInfo(INITIAL_DATA_TEMPLATE);
    };
  }, [isTemplateSelected]);

  return (
    <TemplateStyles.Main>
      <TemplateStyles.Heading>
        <Typography
          text={"Crear Plantilla"}
          variant={variants.large}
          color={palette.secondary}
          asTag={"h3"}
        />
        <Typography
          text={"Crea tus plantillas completando los siguientes pasos:"}
          variant={variants.normal}
          color={palette.neutral20}
          asTag={"p"}
        />
      </TemplateStyles.Heading>

      <StepperComponent
        sourceSteps={DATA_STUB.templateSteps}
        activeStep={templateInfo?.currentStep}
      />

      <TemplateStyles.Content>
        <TemplateStyles.ContentWrapper>
          {templateInfo?.currentStep === 1 && (
            <CreateTemplateName
              currentTemplate={templateInfo}
              onNextStepHandler={onNextStepHandler}
              refreshListOfTemplates={refreshListOfTemplates}
            />
          )}
        </TemplateStyles.ContentWrapper>
        <>
          {templateInfo?.currentStep === 2 && (
            <CreateTemplateAppearance
              templateInfo={templateInfo}
              onNextStepHandler={onNextStepHandler}
              handleCloseCreateTemplate={handleCloseCreateTemplate}
              refreshListOfTemplates={refreshListOfTemplates}
            />
          )}
        </>
        <>
          {templateInfo?.currentStep === 3 && (
            <CreateScenariesAndComponents
              templateInfo={templateInfo}
              handleCloseCreateTemplate={handleCloseCreateTemplate}
              onNextStepHandler={onNextStepHandler}
              refreshListOfTemplates={refreshListOfTemplates}
            />
          )}
        </>
        <>
          {templateInfo?.currentStep === 4 && (
            <CreateNotificationsAndSort
              templateInfo={templateInfo}
              handleCloseCreateTemplate={handleCloseCreateTemplate}
              onNextStepHandler={onNextStepHandler}
              refreshListOfTemplates={refreshListOfTemplates}
            />
          )}
        </>
      </TemplateStyles.Content>
    </TemplateStyles.Main>
  );
};

export default CreateTemplateForAdmin;
