import React, { useState, useRef, useEffect } from "react";
import Typography from "../common/typography";
import { palette, variants } from "../../theme/partials";
import Indicator from "../../static/indicator.svg";
import DragStyles from "./dragAndDrop.styled";
import { translation } from "../../constant";
import { nameComponentByType } from '../../helper/templateMappers';

const DragAndDrop = ({ source, updateComponentList }) => {
  const { staticText } = translation;
  const dragItem = useRef();
  const dragOverItem = useRef();
  const [list, setList] = useState([]);
 
  /**
   * It should get start position
   * @param e - Event
   * @param position - Number
   */
  const dragStart = (e, position) => {
    dragItem.current = position;
  };

  /**
   * It should put item in position
   * @param e - Event
   * @param position - Number
   */
  const dragEnter = (e, position) => {
    if (position === 0) {
      dragOverItem.current = 1;
      return;
    }

    dragOverItem.current = position;
  };

  /**
   * It should put item
   * @param e - Event
   */
  const drop = (e) => {
    const copyListItems = [...list];
    const dragItemContent = copyListItems[dragItem.current];

    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);

    dragItem.current = null;
    dragOverItem.current = null;

    setList(copyListItems);
    updateComponentList(copyListItems);
  };

  useEffect(() => {
    setList(source);
  }, [source]);

  return (
    <>
      <Typography
        text={staticText.orderComponents}
        variant={variants.small}
        color={palette.neutral20}
        asTag={"p"}
      />
      {list &&
        list.map((item, index) => (
          <DragStyles.ItemDraggable
            onDragStart={(e) => dragStart(e, index)}
            onDragEnter={(e) => dragEnter(e, index)}
            onDragEnd={drop}
            key={index}
            draggable={true && index !== 0}
          >
            <DragStyles.Content>
              {index !== 0 && (
                <DragStyles.Icon>
                  <img src={`${Indicator}`} alt={"color"} loading="lazy" />
                </DragStyles.Icon>
              )}
              <DragStyles.Info>
                <Typography
                  text={nameComponentByType(item.type)}
                  variant={variants.small}
                  color={palette.neutral30}
                  asTag={"p"}
                />

                <Typography
                  text={item.title}
                  variant={variants.normal}
                  color={palette.neutral20}
                  asTag={"h4"}
                />
              </DragStyles.Info>
            </DragStyles.Content>
          </DragStyles.ItemDraggable>
        ))}
    </>
  );
};

export default DragAndDrop;
