import React, { useState, useEffect } from "react";
import Typography from "../../common/typography";
import { palette, variants } from "../../../theme/partials";
import Button from "@mui/material/Button";
import TemplateStyles from "../createTemplate.styled";
import TemplateName from "../components/TemplateName";
import { translation } from "../../../constant";
import { createNewTemplate } from "../../../services/platform.service";
import { setPayloadInitialStep } from "../../../helper/templateMappers";
import {NAMES_REGEX} from "../../../helper/regex";

const StepInitial = ({ currentTemplate, onNextStepHandler, refreshListOfTemplates }) => {
  const { staticText } = translation;
  const [inputData, setInputData] = useState({
    name: '',
    errorMessage: null,
  });

  /**
   * It should save template name on templateInfo State
   * @param e:Event
   */
  const onChangeInputName = (e) => {
    const value= e.target.value
    const cleanValue = value.replace(NAMES_REGEX, '')

    setInputData({
      name: cleanValue,
      errorMessage: null,
    });
  };

  const onFocusInputName = () => {
    setInputData({
      ...inputData,
      errorMessage: null,
    })
  }

  /**
   * It should save templateName
   * @param null
   */
  const createNewTemplateByName = async () => {
    try {
      const payload = setPayloadInitialStep(inputData);
      const { data } = await createNewTemplate(payload);

      if (data && data?.idPlantilla) {
        onNextStepHandler({ nextStep: 2, templateId: data?.idPlantilla, name: inputData?.name });
      }
      refreshListOfTemplates();
    } catch (error) {
      const { detalles } = error?.response?.data;
      setInputData({
        ...inputData,
        errorMessage: detalles[0],
      });
    }
  };

  /**
   * It should get templateName to edit
   * @param null
   */
  useEffect(() => {
    if (currentTemplate) setInputData(currentTemplate?.name);
  }, []);

  return (
    <>
      <Typography
        text={staticText.templateName}
        variant={variants.small}
        color={palette.secondary}
        asTag={"h6"}
      />
      <TemplateStyles.FieldWrapper>
        <TemplateName
          onChangeInputName={onChangeInputName}
          onFocusInputName={onFocusInputName}
          currentValue={inputData?.name}
          errorMessage={inputData?.errorMessage}
          inputName={staticText.templateName}
          inputPlaceholder={staticText.inputPlaceholderName}
        />
      </TemplateStyles.FieldWrapper>

      <TemplateStyles.ButtonWrapper>
        <Button
          variant={"contained"}
          disabled={!inputData?.name || inputData?.name.length <= 3}
          type="button"
          onClick={createNewTemplateByName}
        >
          {staticText.btnSaveAndContinue}
        </Button>
      </TemplateStyles.ButtonWrapper>
    </>
  );
};

export default StepInitial;
