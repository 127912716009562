export const truncateText = (text, start, end) => {
	return text.substring(start, end)
}

export const getColorBar = (value) => {
	if (value < 300)
		return 'linear-gradient(0deg, rgba(242,0,0,1) 76%, rgba(242,0,0,1) 100%)'

	if (value > 299 && value < 450)
		return 'linear-gradient(0deg, rgba(242,0,0,1) 0%, rgba(255,154,0,1) 100%)'

	if (value > 449 && value < 550)
		return 'linear-gradient(0deg, rgba(242,0,0,1) 0%, rgba(255,154,0,1) 55%, rgba(255,239,0,1) 100%)'

	if (value > 549 && value < 650)
		return 'linear-gradient(0deg, rgba(242,0,0,1) 0%, rgba(255,154,0,1) 39%, rgba(255,239,0,1) 72%, rgba(104,255,0,1) 100%)'

	if (value > 649 && value < 730)
		return 'linear-gradient(0deg, rgba(242,0,0,1) 0%, rgba(255,154,0,1) 33%, rgba(255,239,0,1) 60%, rgba(104,255,0,1) 85%, rgba(1,214,65,1) 100%)'

	if (value > 729)
		return 'linear-gradient(0deg, rgba(242,0,0,1) 0%, rgba(255,154,0,1) 32%, rgba(255,239,0,1) 50%, rgba(104,255,0,1) 69%, rgba(1,214,65,1) 85%, rgba(0,219,66,1) 100%)'
}

export const getColorText = (value) => {
	if (value < 250) return '#F2741D'
	if (value > 249 && value < 430)
		return '#E9D033'

	if (value > 429 && value < 580)
		return '#DFE739'

	if (value > 579 && value < 700)
		return '#B3E05B'

	if (value > 699)
		return '#8BCD48'
}

export const getMaskNumber = (phoneNumber) => {
	const start = phoneNumber.slice(0,2);
	const end = phoneNumber.slice(8,phoneNumber.length+1);

	return `${start}******${end}`;
}