import React, { useState, useEffect } from "react";
import AppleIcon from "../../../static/loader.gif";
import { PreviewFrontApp } from '../../preview';

const MobilePreview = ({ score, apiResponse }) => {
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 400);
  }, []);

  return (
    <div className="iphone">
      <div className="power"></div>
      <div className="lock"></div>
      <div className="volume up"></div>
      <div className="volume down"></div>
      <div className="camera"></div>
      <div className="speaker"></div>
      <div className="screen">
        {loader ? (
          <div className="loader-wrapper">
            <img src={AppleIcon} alt="mobile" />
          </div>
        ) : (
            <div className="preview-wrapper" style={{ height: '100%', overflow: 'auto' }}>
              <PreviewFrontApp score={score} apiResponse={apiResponse} />
            </div>
          )
        }
      </div>
      <div className="button">
        <div className="square"></div>
      </div>
    </div>
  );
};

export default MobilePreview;
