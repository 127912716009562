import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FieldTextLimit from "../../../helper/field";
import ErrorIcon from "@mui/icons-material/Error";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

const TemplateName = ({
  onChangeInputName,
  onFocusInputName,
  currentValue,
  inputName,
  inputPlaceholder,
  errorMessage,
}) => {
  return (
    <FormControl variant="outlined" className={errorMessage && "text-error"}>
      {inputName && (
        <InputLabel htmlFor="outlined-adornment-name">{inputName}</InputLabel>
      )}
      <OutlinedInput
        id={"name"}
        name={"name"}
        label={inputName}
        type={"text"}
        placeholder={inputPlaceholder}
        required
        error={errorMessage && Boolean(errorMessage)}
        inputProps={{ maxLength: 30 }}
        onChange={(e) => onChangeInputName(e)}
        onFocus={onFocusInputName}
        value={currentValue}
        endAdornment={
          <>
            {errorMessage && Boolean(errorMessage) && (
              <InputAdornment position="end">
                <IconButton aria-label="alert" edge="end">
                  <ErrorIcon />
                </IconButton>
              </InputAdornment>
            )}
          </>
        }
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: errorMessage ? "space-between" : "flex-end",
          alignItems: "center",
          width: "100%",
        }}
      >
        {errorMessage && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
            id="help-error"
          >
            {errorMessage}
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {currentValue && <FieldTextLimit
            currentValue={currentValue}
            style={{ width: "10%" }}
            limit={30}
          />}
        </div>
      </div>
    </FormControl>
  );
};

export default TemplateName;
