import React, { useState, useEffect } from "react";
import Typography from "../../../../components/common/typography";
import { palette, variants } from "../../../../theme/partials";
import UpdateScenariesComponent from "./updateScenaries";

const EditScenariesTemplate = ({
  scenarieList,
  scenarieSelected,
  handleScenariesModal,
  onFinishEditScenarie,
}) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    setData(scenarieList);
  }, [scenarieList]);

  return (
    <>
      <div style={{ textAlign: "center", marginBottom: "40px" }}>
        <Typography
          text={`Editar Plantilla`}
          variant={variants.large}
          color={palette.secondary}
          asTag={"h2"}
        />
      </div>

      <Typography
        text={`Definición de escenarios: ${scenarieSelected?.name}`}
        variant={variants.small}
        color={palette.secondary}
        asTag={"h6"}
      />

      <p className="desc-tag">
        Define los escenarios que verán tus clientes y configura los puntajes
        entre ellos. Debes configurar un <strong>mínimo 3</strong> y un{" "}
        <strong>máximo 5</strong> escenarios.
      </p>

      <UpdateScenariesComponent
        sourceData={data}
        updateScenarieList={onFinishEditScenarie}
        handleScenariesModal={handleScenariesModal}
        withButton
      />
    </>
  );
};
export default EditScenariesTemplate;
