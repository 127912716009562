import styled from "styled-components";
import { palette, variants } from "../../../theme/partials";

const LoaderContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  background-color: ${palette.white};
  height: 100vh;
  justify-content: center;
  width: 100%;
`;

const LoaderStyles = {
  LoaderContent,
};

export default LoaderStyles;
