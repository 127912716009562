import React from 'react'
import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import SeparatorComponent from '../Separator'
import ImageComponent from '../Images/ImageComponent'
import ScoreStatusComponent from '../ScoreStatus'
import PersonIcon from '../../static/icons/person.svg'
import PeopleIcon from '../../static/icons/people.svg'
import UpIcon from '../../static/icons/up.svg'
import DownIcon from '../../static/icons/down.svg'

const ComparateScoreComponent = ({ source }) => {
	const { heading, score, payload } = source

	return (
		<Box components={'div'} className="padding-content">
			<Box components={'div'} className="padding-content">
				<Typography variant="headingIndicator">{heading}</Typography>
			</Box>
			<Box
				components={'div'}
				className="flex-row space-between padding-content"
			>
				<Box components={'div'} className={'flex-row flex-start item-padding'}>
					<ImageComponent source={PersonIcon} alt="person" />
					<Typography variant="scoreListHead">{score.text}</Typography>
				</Box>
				<ScoreStatusComponent isFirst source={payload.valorScore} />
			</Box>
			<SeparatorComponent />
			<Box
				components={'div'}
				className="flex-row space-between padding-content"
			>
				<Box components={'div'} className={'flex-row flex-start item-padding'}>
					<ImageComponent source={PeopleIcon} alt="people" />
					<Typography variant="scoreList">Usuarios en México</Typography>
				</Box>
				<ScoreStatusComponent source={730} />
			</Box>
			<SeparatorComponent />

			<Box
				components={'div'}
				className="flex-row space-between padding-content"
			>
				<Box components={'div'} className={'flex-row flex-start item-padding'}>
					<ImageComponent source={PeopleIcon} alt="people" />
					<Typography variant="scoreList">Usuarios en Guadalajara</Typography>
				</Box>
				<ScoreStatusComponent source={650} />
			</Box>
			<SeparatorComponent />

			<Box
				components={'div'}
				className="flex-row space-between padding-content"
			>
				<Box components={'div'} className={'flex-row flex-start item-padding'}>
					<ImageComponent source={DownIcon} alt="down" />
					<Typography variant="scoreMesage">
						Te encuentras <strong>{730 - payload.valorScore}</strong> puntos
						debajo del promedio nacional.
					</Typography>
				</Box>
			</Box>

			<Box
				components={'div'}
				className="flex-row space-between padding-content"
			>
				<Box components={'div'} className={'flex-row flex-start item-padding'}>
					<ImageComponent source={UpIcon} alt="up" />
					<Typography variant="scoreMesage">
						Te encuentras <strong>{payload.valorScore - 650}</strong> puntos
						arriba del promedio estatal.{' '}
					</Typography>
				</Box>
			</Box>
		</Box>
	)
}

export default ComparateScoreComponent
