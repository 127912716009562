import React, { useEffect, useState, useMemo } from "react";
import Pagination from "react-bootstrap/Pagination";

const PaginationComponent = ({
    showPagination = false,
    total = 0,
    itemsPerPage = 10,
    currentPage = 1,
    onPageChange
}) => {
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        if (total > 0 && itemsPerPage > 0)
            setTotalPages(Math.ceil(total / itemsPerPage));
    }, [total, itemsPerPage]);

    const paginationItems = useMemo(() => {
        const pages = [];

        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <Pagination.Item
                    key={i}
                    active={i === currentPage}
                    onClick={() => onPageChange(i)}
                >
                    {i}
                </Pagination.Item>
            );
        }

        return pages;
    }, [totalPages, currentPage]);

    if (totalPages === 0) return null;

    return (
        <>
            {
                showPagination && (
                    <>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                color: "#45464F",
                                fontSize: "12px",
                                marginTop: "6px"
                            }}
                        >
                            <p>
                                {currentPage === 1 ? 1 : currentPage * 10 - 9 } - {Math.min(currentPage * itemsPerPage, total)} de {total} plantillas
                            </p>
                            <Pagination>
                                <Pagination.Prev
                                    onClick={() => onPageChange(currentPage - 1)}
                                    disabled={currentPage === 1}
                                />
                                {paginationItems}
                                <Pagination.Next
                                    onClick={() => onPageChange(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                />
                            </Pagination>
                        </div>

                    </>
                )
            }
        </>
    );
};

export default PaginationComponent;