import React, { useState } from 'react'
import { CssBaseline, createTheme } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { generateCustomMuiTheme } from './theme/muiTheme'
import { renderPreviewComponents } from './listOfComponents'
import SkeletonLoaderComponent from './components/Skeleton'
import MainLayout from './templates/layouts/MainLayout'
import './index.css'
import { useEffect } from 'react'

export const PreviewFrontApp = ({ score, apiResponse }) => {
	const [muiTheme, setMuiTheme] = useState({})

	useEffect(() => {
		const { color } = apiResponse
		const { primario: primaryColor, secundario: secondaryColor } = color
		const customMuiTheme = generateCustomMuiTheme({ primaryColor, secondaryColor })
		const createdMuiTheme = createTheme(customMuiTheme)

		setMuiTheme(createdMuiTheme)
	}, [apiResponse])

	if (!Object.entries(muiTheme).length) return null

	return (
		<ThemeProvider theme={muiTheme}>
			<CssBaseline />
			<MainLayout>
				{!apiResponse && <SkeletonLoaderComponent />}
				{apiResponse && (
					<>
						{renderPreviewComponents({ score, apiResponse })}
					</>
				)}
			</MainLayout>
		</ThemeProvider>
	)
}

export default PreviewFrontApp
