import React from "react";
import Stepper from "@mui/material/Stepper";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const StepperComponent = ({ activeStep, sourceSteps }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep - 1} alternativeLabel>
        {sourceSteps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}

export default StepperComponent;