import axios from "axios";
import { memoizedRefreshToken } from "./refreshToken";
import { isCookiesExist } from "../utils/context";
import { getTokenValues } from "../helper/authMapper";

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

axios.interceptors.request.use(
  async (config) => {
    if (isCookiesExist) {
      const ctx = getTokenValues();
      config.headers = {
        ...config.headers,
        token: `${ctx?.token}`,
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;

    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true;

      const result = await memoizedRefreshToken();

      if (result?.token) {
        config.headers = {
          ...config.headers,
          token: `${result?.token}`,
        };
      }

      return axios(config);
    }
    return Promise.reject(error);
  }
);

export const axiosPrivate = axios;
