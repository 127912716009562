import React, { useState, useEffect } from "react";
import { palette, variants } from "../../../theme/partials";
import Typography from "../../common/typography";
import SelectComponent from "../../common/select";
import TextField from "@mui/material/TextField";
import Chart from "react-google-charts";
import LoaderGraphicBar from "../../skeleton/loaderGraphicBar";
import { GENERATION_TYPES, INPUT_PROVIDENCE } from "../../../constant";
import { getGrachicLinealData } from "../../../services/dashboard.service";
import {
  setGraphicLinealData,
  MONTHS,
  getScoreMonthly
} from "../../../helper/dashboardMapper";

export const options = {
  colors: ["#1F3063", "#5B73C4"],
  axes: {
    x: {
      0: { side: "bottom", label: "Evolución de distribución de score" }
    }
  },
  bars: "vertical",
};

const formatMonths = (month) => {
  return month >= 1 && month < 10 ? `0${month}` : month;
};

var currentDateStart = new Date();
var currentDateEnd = new Date();

currentDateStart.setMonth(currentDateStart.getMonth() - 5);
currentDateEnd.setMonth(currentDateEnd.getMonth() + 1);

const currentDateStartYear = currentDateStart.getFullYear();
const currentDateEndYear = currentDateEnd.getFullYear();

const currentDateStartDay = currentDateStart.getDate();
const currentDateEndDay = currentDateEnd.getDate();

const dateStartString =
  currentDateStartYear + "-" + ("0" + (currentDateStart.getMonth() + 1)).slice(-2) + "-" + currentDateStartDay;
const dateEndString =
  currentDateEndYear + "-" + ("0" + (currentDateEnd.getMonth() + 1)).slice(-2) + "-" + currentDateEndDay;

const GraphicLineal = () => {
  const providenceList = INPUT_PROVIDENCE.map((it) => it.providence);
  const genreList = GENERATION_TYPES.map((it) => it.value);

  const [isLoading, setIsLoading] = useState(true);
  const [dataList, setDataList] = useState(null);
  const [dataService, setDataService] = useState(null);
  const [status, setStatus] = useState(INPUT_PROVIDENCE[16]?.providence);
  const [genre, setGenre] = useState(GENERATION_TYPES[1]?.value);
  const [startDate, setStartDate] = useState(dateStartString);
  const [endDate, setEndDate] = useState(dateEndString);


  const onSelectedOption = (e, type) => {
    const value = e?.target?.value;

    if (type === "status") {
      const providence = INPUT_PROVIDENCE.filter(
        (it) => it.providence === value
      )[0]?.providence;

      setStatus(providence);
    }

    if (type === "genre") {
      const isGeneration = GENERATION_TYPES.filter(
        (it) => it.value === value
      )[0]?.value;

      setGenre(isGeneration);
    }

    if (type === "startDate") {
      setStartDate(value);
    }

    if (type === "endDate") {
      setEndDate(value);
    }
  };

  const getScoreSelected = () => {
    const utcStartSplitted = startDate.split("-").map((it) => parseInt(it));
    const utcEndSplitted = endDate.split("-").map((it) => parseInt(it));
    const dataExtracted = Object.entries(dataList).map((list) => list[1]);
    const scores = getScoreMonthly(dataService);

    return (
      <>
        {dataExtracted.length === 1 ? (
          <>
            <div className="card-blue">
              <Typography
                text={scores?.start}
                variant={variants.secondary}
                color={palette.neutral30}
                asTag={"h4"}
              />
              <Typography
                text={`Promedio - ${MONTHS[utcEndSplitted[1]]}, 20`}
                variant={variants.secondary}
                color={palette.neutral30}
                asTag={"p"}
              />
            </div>
          </>
        ) : (
          <>
            <div className="card-blue">
              <Typography
                text={scores?.start}
                variant={variants.small}
                color={palette.neutral30}
                asTag={"h4"}
              />
              <Typography
                text={`Promedio - ${MONTHS[utcStartSplitted[1]]}, 20`}
                variant={variants.small}
                color={palette.neutral30}
                asTag={"p"}
              />
            </div>

            <div className="card-blue">
              <Typography
                text={scores?.end}
                variant={variants.small}
                color={palette.neutral30}
                asTag={"h4"}
              />
              <Typography
                text={`Promedio - ${MONTHS[utcEndSplitted[1]]}, 20`}
                variant={variants.small}
                color={palette.neutral30}
                asTag={"p"}
              />
            </div>
          </>
        )}
      </>
    );
  };

  const getData = async () => {
    setIsLoading(true);
    try {
      const utcStartSplitted = startDate.split("-").map((it) => parseInt(it));
      const utcEndSplitted = endDate.split("-").map((it) => parseInt(it));

      let trasnformStartMonth = formatMonths(utcStartSplitted[1]);
      let trasnformEndMonth = formatMonths(utcEndSplitted[1]);

      const from = `${utcStartSplitted[0]}${trasnformStartMonth}`;
      const to = `${utcEndSplitted[0]}${trasnformEndMonth}`;

      const payload = {
        estado: INPUT_PROVIDENCE.filter((it) => it.providence === status)[0]
          ?.id,
        generacion: GENERATION_TYPES.filter((it) => it.value === genre)[0]
          ?.type,
        periodo: {
          desde: from,
          hasta: to
        }
      };

      const result = await getGrachicLinealData(payload);

      if (result?.data) {
        setDataService(result?.data);
        const dataFiltered = setGraphicLinealData(result?.data, {
          start: utcStartSplitted,
          end: utcEndSplitted
        });

        setDataList(dataFiltered);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, [status, genre, startDate, endDate]);

  return (
    <div className="">
      <div xstyle={{ padding: "20px" }}>
        <div
          style={{
            margin: "6px 0 0 0",
            textAlign: "left",
            paddingLeft: " 10px",
            paddingTop: "10px"
          }}
        >
          <Typography
            text={"Gráfica de comparación por filtros"}
            variant={variants.small}
            color={palette.secondary}
            asTag={"h4"}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            marginBottom: '40px',
            marginTop: '16px'
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "10%"
            }}
          >
            <Typography
              text={"Filtros"}
              variant={variants.extraSmall}
              color={palette.black}
              asTag={"h6"}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "90%"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-betwwen",
                alignItems: "center"
              }}
            >
              <SelectComponent
                currentValue={status}
                id={"status"}
                label={"Estado"}
                source={providenceList}
                formStyle={{ margin: "0 10px" }}
                onSelectedOption={onSelectedOption}
                isDisabled={isLoading}
              />

              <SelectComponent
                currentValue={genre}
                id={"genre"}
                label={"Generación"}
                source={genreList}
                formStyle={{ margin: " 0 10px" }}
                onSelectedOption={onSelectedOption}
                isDisabled={isLoading}
              />

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  alignItems: "center",
                  margin: " 0 10px"
                }}
                className="datePicker"
              >
                <div>
                  <TextField
                    id="date"
                    label="Fecha inicial"
                    type="date"
                    defaultValue={startDate}
                    sx={{ width: "100%" }}
                    disabled={isLoading}
                    placeholder="Selecciona fecha"
                    onChange={(e) => onSelectedOption(e, "startDate")}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </div>

                <div style={{ margin: "0 10px" }}>
                  <Typography
                    text={"Hasta"}
                    variant={variants.extraSmall}
                    color={palette.neutral20}
                    asTag={"p"}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    margin: " 0 10px"
                  }}
                  className="datePicker"
                >
                  <TextField
                    id="date"
                    label="Fecha final"
                    type="date"
                    defaultValue={endDate}
                    sx={{ width: "100%" }}
                    disabled={isLoading}
                    onChange={(e) => onSelectedOption(e, "endDate")}
                    placeholder="Selecciona fecha"
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <>
          {!isLoading ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "-20px 10px 10px 10px"
                }}
              >
                {getScoreSelected()}
              </div>

              <div
                id="lineal"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  margin: "30px 0 10px 0",
                  padding: '20px'
                }}
              >
                <Chart
                  chartType="Bar"
                  width="100%"
                  height="200px"
                  data={dataList}
                  options={options}
                />
              </div>
            </>
          ) : (
            <LoaderGraphicBar show={isLoading} />
          )}
        </>
      </div>
    </div>
  );
};

export default React.memo(GraphicLineal);
