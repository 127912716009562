import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { translation } from "../../../constant";

const Search = ({ onSearch }) => {
    const { staticText } = translation;
    const [search, setSearch] = useState("");

    /**
    * It should search by query on data table
    * @param value: Event
    */
    const onInputChange = value => {
        setSearch(value);
        onSearch(value);
    };
    return (
        <FormControl className="search-control" variant="outlined">
            <OutlinedInput
                id={"search"}
                name={"search"}
                type={"text"}
                value={search}
                startAdornment={
                    <>
                        <InputAdornment position="start">
                            <IconButton edge="start">
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    </>
                }
                onChange={e => onInputChange(e.target.value)}
                placeholder={staticText.findTemplate}
                required
            />
        </FormControl>
    );
};

export default Search;