import React from "react";
import TemplateStyle from "./template.styled";
import CustomMainView from "../../components/customMainView";
import TemplateTableComponent from "../../components/templateTable";

const TemplatComponent = () => {
  return (
    <CustomMainView>
      <TemplateStyle.MainContent>
        <TemplateTableComponent />
      </TemplateStyle.MainContent>
    </CustomMainView>
  );
};
export default TemplatComponent;
