import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Loader from "../components/common/loader";
import { ProtectedLayout } from "./outlet/protectedLayout";
import { HomeLayout } from "./outlet/publicLayout";
import LogInPage from "../pages/logIn";
import SignUpPage from "../pages/signup";
import DashboardPage from "../pages/dashboard";
import TemplateContent from "../pages/template";
import DevelopmentPage from "../pages/development";
import EditContent from "../pages/template/editTemplate";
import { useAuth } from "../hooks/useAuth";

const App = () => {
  const { isPending } = useAuth();
  const [showLoader, setShowLoader] = useState(isPending);

  useEffect(() => {
    setShowLoader(isPending);
  }, [isPending])
  
  return (
    <>
      {showLoader && <Loader />}
      <Routes>
        <Route element={<HomeLayout />}>
          <Route path="/" element={<LogInPage />} />
          <Route path="/register" element={<SignUpPage />} />
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/template" element={<TemplateContent />} />
          <Route path="/template/:templateId" element={<EditContent />} />
          <Route path="/development" element={<DevelopmentPage />} />
        </Route>
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
};

export default App;
