import React from "react";
import DashboardStyle from "./dashboard.styled";
import CustomMainView from "../../components/customMainView";
import ToPrintPage from "../../components/toPrint";

const DashboardPage = () => {
  return (
    <CustomMainView>
      <ToPrintPage />
    </CustomMainView>
  );
};

export default DashboardPage;
