import React, { useState, useEffect } from "react";
import upload from "../../../static/upload.svg";
import download from "../../../static/download.svg";
import { setImageValuesByType } from "../../../helper/templateMappers";
import { uploadTemplateImage } from "../../../services/platform.service";
import{ sanitizeFileName } from "../../../utils/sanitize";

const UploadImageComponent = ({
  updateImageForTemplateInfo,
  sourceFile = null,
}) => {
  const [currentFile, setCurrentFile] = useState({
    logo: null,
    hasBase64: false,
    errorMessage: null,
  });

  /**
   * It should upload image
   * @param e: Event
   */
  const fileFormatHandler = async (e) => {
    try {
      e.preventDefault();
      const file = e.target.files[0];
      const sanitiziedFile = new File([file], sanitizeFileName(file.name), { type: file.type });
      const fileInfo = await setImageValuesByType(sanitiziedFile);
      const { data } = await uploadTemplateImage(fileInfo);
      if (data?.idArchivo) {
        setCurrentFile({
          ...currentFile,
          logo: sanitiziedFile,
          hasBase64: false,
          errorMessage: null,
        });
        updateImageForTemplateInfo({ logo: data?.idArchivo });
      }
    } catch (error) {
      const data = error?.response?.data;
      const message = data?.detalles;
      setCurrentFile({
        ...currentFile,
        logo: null,
        hasBase64: false,
        errorMessage: message[0],
      });
      updateImageForTemplateInfo({ logo: null });
    }
  };

  /**
   * It should validate format image
   * @param null
   */
  const setImagePath = () => {
    if (currentFile?.logo && currentFile?.hasBase64) {
      return `data:image/png;base64,${currentFile?.logo}`;
    }

    if (currentFile?.logo && !currentFile?.hasBase64) {
      return URL.createObjectURL(currentFile?.logo);
    }

    return upload;
  };

  useEffect(() => {
    if (sourceFile) {
      setCurrentFile({
        ...currentFile,
        logo: sourceFile,
        hasBase64: true,
        errorMessage: null,
      });
    }
  }, []);

  return (
    <div className={"column-start"}>
      <div>
        <input
          filename={currentFile}
          onChange={(e) => fileFormatHandler(e)}
          type="file"
          accept="image/*"
          id="upload-photo"
        />
        <label htmlFor="upload-photo" className={"label-upload"}>
          <img
            src={setImagePath()}
            alt={"icon"}
            loading="lazy"
            style={{ marginRight: "10px" }}
            className={sourceFile || currentFile ? "cover-photo" : ""}
          />
          Cargar imágen
          <img
            src={`${download}`}
            alt={"icon"}
            loading="lazy"
            style={{ marginLeft: "10px" }}
          />
        </label>
      </div>
      <div>
        {currentFile?.errorMessage && (
          <div style={{ textAlign: "left !important", width: "100%" }}>
            <p className={"error-message"} style={{ margin: "12px 0 0 0" }}>
              {currentFile?.errorMessage}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadImageComponent;
