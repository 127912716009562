import React from "react";
import { Box, Typography, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Slider from "react-slick";
import { palette } from "../../../../theme/partials";

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  swipe: true,
  arrows: false,
  adaptiveHeight: true
};

const CircularProgressWithLabel = (props) => {
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        background: "none !important"
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
          position: "relative",
          left: 0
        }}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        sx={{
          top: 12,
          color: props?.mainColor,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "none !important"
        }}
      >
        <h5
          variant="caption"
          component="div"
          style={{ color: props?.mainColor }}
        >
          {`${Math.round(props.value)}%`}
        </h5>
      </Box>
    </Box>
  );
};

const HowItsWorkComponent = ({ secondaryColor }) => {
  return (
    <Box
      component={"div"}
      className="flex-column justify-center bg-main preview"
    >
      <Box
        component={"div"}
        className="padding-content full-width inherit-bg"
      >
        <Button type="button" variant="contained">
          Ver preguntas frecuentes
        </Button>
      </Box>
    </Box>
  );
};

export default HowItsWorkComponent;
