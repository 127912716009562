import React from "react";
import SidebarStyle from "./sidebar.styled";
import Logo from "../common/logo";
import { palette, variants } from "../../theme/partials";
import Typography from "../common/typography";
import IconButton from "@mui/material/IconButton";
import { NavLink } from "react-router-dom";

const SidebarComponent = ({ routesLink }) => {
  return (
    <SidebarStyle.Sidebar id="sidebar-content">
      <Logo />
      <SidebarStyle.Menu>
        {routesLink.map((item, index) => {
          return (
            item.show && (
              <NavLink
                key={index}
                to={item.path}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <IconButton aria-label={item.sidebar}>
                  <item.icon />
                </IconButton>
                <Typography
                  text={item.sidebar}
                  variant={variants.normal}
                  color={palette.neutral}
                  asTag={"p"}
                />
              </NavLink>
            )
          );
        })}
      </SidebarStyle.Menu>
    </SidebarStyle.Sidebar>
  );
};

export default SidebarComponent;
