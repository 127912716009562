import React from "react";
import HeadingStyle from "./heading.styled";
import { palette, variants } from "../../theme/partials";
import Typography from "../common/typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

const HeadingComponent = ({ routesLink }) => {
  const { handleLogout, user } = useAuth();
  const navigate = useLocation();
  const currentState = routesLink.filter(
    (route) => route.path === navigate.pathname
  )[0];

  return (
    <HeadingStyle.Section>
      <HeadingStyle.Heading>
        <Typography
          text={currentState ? currentState?.heading : "Tus plantillas"}
          variant={variants.large}
          color={palette.secondaryContainer}
          asTag={"h2"}
        />
        <div className="dropdown">
          <HeadingStyle.Avatar>
            {user?.displayName.charAt(0)}
          </HeadingStyle.Avatar>
          <Typography
            text={user?.displayName}
            variant={variants.normal}
            color={palette.secondaryContainer}
            asTag={"h4"}
          />
          <ExpandMoreIcon />
          <div className="dropdown-content">
            <a>Perfil</a>
            <a>Administrar usuarios</a>
            <a className="logout" onClick={() => handleLogout()}>
              Cerrar sesión
            </a>
          </div>
        </div>
      </HeadingStyle.Heading>
      <HeadingStyle.SubHeading>
        <Typography
          text={
            currentState
              ? currentState?.description
              : "Crea, personaliza y administra tus plantillas para tus clientes"
          }
          variant={variants.normal}
          color={palette.neutral20}
          asTag={"p"}
        />
      </HeadingStyle.SubHeading>
    </HeadingStyle.Section>
  );
};

export default HeadingComponent;
