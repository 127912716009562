import React, { useState, useEffect } from "react";
import { palette, variants } from "../../../theme/partials";
import Typography from "../../common/typography";
import { ResponsiveBar } from "@nivo/bar";
import LoaderGraphicBar from "../../skeleton/loaderGraphicBar";
import { getGrachicBarData } from "../../../services/dashboard.service";
import { setGraphicBarData } from "../../../helper/dashboardMapper";
import styled from "styled-components";

const Grapchic = styled.div`
  height: 350px;
  min-height: 350px;
  max-height: 350px;
  height: 340px;
`;

const GraphicBar = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataList, setDataList] = useState(null);

  const getData = async () => {
    setIsLoading(true);
    try {
      const result = await getGrachicBarData();
      if (result?.data) {
        const dataFiltered = setGraphicBarData(result?.data);
        setDataList(dataFiltered);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Grapchic>
      <div
        style={{ textAlign: "left", paddingLeft: " 10px", paddingTop: "10px" }}
      >
        <Typography
          text={"Gráfica de rango de score consultados por mes"}
          variant={variants.small}
          color={palette.secondary}
          asTag={"h4"}
        />
      </div>
      <>
        {!isLoading ? (
          <ResponsiveBar
            data={dataList}
            keys={[
              "No hit",
              "300 a 589",
              "590 a 663",
              "664 a 721",
              "722 a 850"
            ]}
            indexBy="date"
            margin={{ top: 40, right: 130, bottom: 70, left: 60 }}
            padding={0.32}
            colors={[
              "#90909A",
              "#B9C3FF",
              "#8FA8FD",
              "#5F72C4",
              "#293E80"
            ]}
            labelTextColor="#ffffff"
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 130,
                translateY: 0,
                itemsSpacing: 6,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemTextColor: palette.secondary,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1
                    }
                  }
                ]
              }
            ]}
            barAriaLabel={function (e) {
              return (
                e.id + ": " + e.formattedValue + " in country: " + e.indexValue
              );
            }}
          />
        ) : (
          <LoaderGraphicBar show={isLoading} />
        )}
      </>
    </Grapchic>
  );
};

export default React.memo(GraphicBar);
